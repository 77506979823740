import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, ControlLabel, FormControl, FormGroup, Glyphicon, PageHeader } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useDebounce, useSessionStorage } from 'react-use';
import { Form } from 'react-bootstrap/lib/Navbar';

import { t } from '../../locale';
import { deleteTableGql, getTablesGql } from './gql';
import ConfirmModal from '../../components/ConfirmModal';
import toastHelper from '../../lib/toastHelper';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../components/AdvancedBootstapTable';

function TablesPage() {
  const [page, setPage] = useSessionStorage(`tablePage_${window.eventId}`, 1);
  const [sizePerPage, setSizePerPage] = useSessionStorage(`tableSizePerPage_${window.eventId}`, 25);
  const [confirmData, setConfirmData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`tableSearch_${window.eventId}`, '');

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const filterToSend = useMemo(() => {
    const filterToSend = { $and: [] };
    if (debouncedSearchTerm) filterToSend.$and.push({ name: { $ct: debouncedSearchTerm } });
    if (!filterToSend.$and.length) return '';
    return JSON.stringify(filterToSend);
  }, [debouncedSearchTerm]);

  const { data: { getTables: { tables, meta } = { tables: [], meta: {} } } = {}, refetch } = useQuery(getTablesGql, {
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      filter: filterToSend,
    },
  });

  const [deleteTable, { loading: deleteTableLoading }] = useMutation(deleteTableGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 400) return toastHelper.error(t('tables.errors.deleteTableError400'));
      if (err.networkError.statusCode === 404) return toastHelper.error(t('tables.errors.deleteTableError404'));
      return toastHelper.error(t('tables.errors.deleteTableError'));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('tables.deleteTable.success')); },
  });

  const handleDelete = (table) => {
    setConfirmData({
      invite: table,
      show: true,
      title: t('tables.deleteTable.title'),
      text: t('tables.deleteTable.text', { table }),
      loading: deleteTableLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('tables.deleteTable.delete'),
      onConfirm: async () => {
        await deleteTable({ variables: { eventId: window.eventId, tableId: table.id } });
        setConfirmData({});
      },
      onClose: () => { setConfirmData({}); },
    });
  };

  const columns = [
    { text: t('tables.name'), dataField: 'name'/* , filter: textFilter({ placeholder: t('tables.table.filterPlaceholder.name') }) */ },
    {
      text: t('tables.tableNumber'),
      dataField: 'tableNumber',
      // filter: numberFilter({ placeholder: t('tables.table.filterPlaceholder.tableNumber') }),
    },
    { text: t('tables.seatCount'), dataField: 'seatCount' },
  ];

  if (window.eventRole !== 'viewer') {
    columns.push(
      {
        text: 'Aktion',
        dataField: 'id',
        isDummyField: true,
        formatter: (_, row) => (
          <ButtonGroup>
            <Button
              bsStyle="primary"
              title={t('general.edit')}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = `/events/tableEdit/${window.eventId}/${row.id}`;
              }}
            >
              <Glyphicon glyph="pencil" />
            </Button>
            <Button
              bsStyle="danger"
              title={t('general.delete')}
              onClick={() => handleDelete(row)}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </ButtonGroup>
        ),
      },
    );
  }

  const attendeesColumn = [
    { text: t('customers.table.forename'), dataField: 'forename' },
    { text: t('customers.table.name'), dataField: 'name' },
    { text: t('customers.table.email'), dataField: 'email' },
    { text: t('customers.table.seat'), dataField: 'seat' },
    { text: t('customers.table.ticketCategory'), dataField: 'ticketCategory.name' },
  ];

  const expandRow = {
    renderer: (row) => (
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <BootstrapTable
          keyField="id"
          data={row.attendees}
          columns={attendeesColumn}
          striped
          bordered={false}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => (
      <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={expanded ? faCaretDown : faCaretRight} />
    ),
    expandHeaderColumnRenderer: () => null,
  };

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
    }
  };

  return (
    <>
      <PageHeader>{t('tables.headline')}</PageHeader>
      {(window.eventRole !== 'viewer') && (
        <Button
          bsStyle="primary"
          href={`/events/tableEdit/${window.eventId}`}
        >
          {t('tables.editPage.title')}
        </Button>
      )}
      <StyledContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form inline>
            <FormGroup controlId="formInlineName">
              <ControlLabel>Suche</ControlLabel>{' '}
              <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
            </FormGroup>
          </Form>
        </div>
        <AdvancedBootstapTable
          remote
          keyField="id"
          columns={columns}
          data={tables}
          striped
          bordered={false}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize: meta.total || 0,
            sizePerPageList: [1, 10, 25, 50, 100],
            showTotal: true,
            paginationTotalRenderer,
            sizePerPageRenderer,
          })}
          onTableChange={(type, result) => handleTableChange(type, result)}
          /* filter={filterFactory()} */
          expandRow={expandRow}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
      </StyledContainer>
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}

const StyledContainer = styled.div`
  .reset-expansion-style { background-color: #f9f9f9; }
`;

export default TablesPage;
