import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import featureList from '../featureList.json';
import FormField from '../../../components/FormField';

function FeatureDialog({ show, onHide, onSubmit }) {
  const { t } = useTranslation('settings');
  const { t: tg } = useTranslation('general');
  const data = {

  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {t('features.dialog.title')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormField
                name="feature"
                type="select"
                title={t('features.feature')}
                labelSize={2}
                inputSize={10}
                options={Object.entries(featureList).map(([key, val]) => ({ value: key, label: val.name }))}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {tg('save')}
              </Button>
              <Button onClick={onHide}>{tg('close')}</Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
}

export default FeatureDialog;
