import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import moment from 'moment';
import { getAttributesGql, getCustomerAudit, getTicketCategoriesGql } from '../gql';
import AdvancedBootstapTable from '../../../components/AdvancedBootstapTable';
import { t } from '../../../locale';

export default function CustomerAuditPage() {
  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data } = useQuery(getCustomerAudit, {
    variables: {
      eventId: window.eventId,
      id: window.barcode,
    },
  });

  const attributeByCode = useMemo(() => {
    if (!attributeResult?.getAttributes?.attributes) return {};
    return attributeResult?.getAttributes?.attributes.reduce((acc, attr) => {
      acc[attr.code] = attr;
      return acc;
    }, {});
  }, [attributeResult?.getAttributes?.attributes]);

  const categoryById = useMemo(() => {
    if (!categoriesResult?.getTicketCategories?.ticketCategories) return {};
    return categoriesResult?.getTicketCategories?.ticketCategories.reduce((acc, cat) => {
      acc[cat.id] = cat;
      return acc;
    }, {});
  }, [categoriesResult?.getTicketCategories?.ticketCategories]);

  const customAttrFormatter = (attr, value) => {
    if (attr?.type === 'date' && value) return moment(value).format('L');
    if (attr?.type === 'boolean') return value ? 'Ja' : 'Nein';
    return value;
  };

  const fieldsToSkip = ['barcode', 'invite', 'inviteId', 'linkedCustomerId', 'ticketCategoryName'];
  const formatChanges = (changes, type) => {
    if (type === 'emailSent') {
      if (!changes.subject) return null;
      return (
        <>
          <p>
            <span style={{ fontWeight: 'bold' }}>Betreff</span>: {changes.subject}
          </p>
          <code style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
            {changes.textMessage}
          </code>
        </>
      );
    }
    return (
      <ul>
        {Object.entries(changes).reduce((acc, [key, value]) => {
          if (!type.startsWith('device') && fieldsToSkip.includes(key)) return acc;

          if (key === 'customAttributes' && value) {
            for (const [key, attrValue] of Object.entries(value)) {
              if (attributeByCode[key]?.type === 'attendee') continue;
              acc.push(<li key={key}>{attributeByCode[key]?.name || key}: {customAttrFormatter(attributeByCode[key], attrValue)}</li>);
            }
            return acc;
          }

          let valueToShow = value || '<Nicht gesetzt>';
          if (key === 'title') valueToShow = t(`general.titles.${value}`);
          if (key === 'ticketCategoryId') valueToShow = categoryById[value]?.name || '';

          if (typeof value === 'object') {
            return acc;
          }

          if (type.startsWith('device')) {
            acc.push(<li key={key}>{t(`customers.devices.${key}`)}: {valueToShow}</li>);
          } else {
            acc.push(<li key={key}>{t(`customers.table.${key}`)}: {valueToShow}</li>);
          }
          return acc;
        }, [])}
      </ul>
    );
  };

  const columns = [
    {
      text: 'Datum',
      dataField: 'created',
      formatter: (cellContent) => moment(cellContent).format('L LT'),
      headerStyle: () => ({ width: '150px' }),
    },
    { text: 'Type', dataField: 'type', formatter: (cellContent) => t(`audit.type.${cellContent}`) },
    {
      text: 'Vor Änderung',
      dataField: 'old',
      formatter: (cellContent, row) => formatChanges(cellContent || {}, row.type),
    },
    {
      text: 'Nach Änderung',
      dataField: 'new',
      formatter: (cellContent, row) => formatChanges(cellContent || {}, row.type),
    },
    { text: 'Geändert von', dataField: 'changedBy' },
  ];

  return (
    <AdvancedBootstapTable
      remote
      keyField="id"
      columns={columns}
      data={data?.getCustomerAudit?.auditLogs || []}
    />
  );
}
