/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getRoomsGql = gql`
  query getRooms($eventId: ID!){
    getRooms(eventId: $eventId)
      @rest(type: "[room]", path: "events/{args.eventId}/rooms") {
        rooms {
          id
          name
          currentPresenceCount
          maxAttendees
          status
        }
    }
  }
`;

export const getRoomGql = gql`
  query getRoom($eventId: ID!, $roomId: Int!){
    getRoom(eventId: $eventId, roomId: $roomId)
      @rest(type: "[room]", path: "events/{args.eventId}/rooms/{args.roomId}") {
        room {
          id
          name
          maxAttendees
          status
        }
    }
  }
`;

export const getRoomParticipantsGql = gql`
  query getRoomParticipants($eventId: ID!, $roomId: Int!){
    getRoomParticipants(eventId: $eventId, roomId: $roomId)
      @rest(type: "[room]", path: "events/{args.eventId}/rooms/{args.roomId}/participants") {
        participants {
          id
          forename
          name
          email
          enter
          enterHost
          left
          leftHost
        }
    }
  }
`;

export const addRoomGql = gql`
  mutation addRoom($eventId: ID!, $input: PublishablePostInput!) {
    addProduct (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/rooms",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateRoomGql = gql`
  mutation updateRoom($eventId: ID!, $roomId: Int!, $input: PublishablePostInput!) {
    updateProduct (eventId: $eventId, roomId: $roomId, input: $input) @rest(
      path: "events/{args.eventId}/rooms/{args.roomId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteRoomGql = gql`
  mutation updateRoom($eventId: ID!, $roomId: Int!, $input: PublishablePostInput!) {
    updateProduct (eventId: $eventId, roomId: $roomId, input: $input) @rest(
      path: "events/{args.eventId}/rooms/{args.roomId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
