import React, { useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/de';
import { useQuery } from '@apollo/client';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { useLocalStorage } from 'react-use';
import { getSubeventsGql, getHostsStatus } from './gql';

function LocalEventSettings() {
  const [localPrinter, setLocalPrinter] = useLocalStorage(`${window.eventId}_localPrinter`);
  const [localPrinterSubevent, setLocalPrinterSubevent] = useLocalStorage(`${window.eventId}_localPrinterSubevent`);
  const { data } = useQuery(getHostsStatus, {
    variables: {
      eventId: window.eventId,
    },
  });
  const { data: subeventsData } = useQuery(getSubeventsGql, {
    variables: {
      eventId: window.eventId,
    },
  });

  const onlineHosts = useMemo(() => {
    if (!data) return [];
    return [
      { name: 'Kein Drucker', value: '' },
      ...data.hostStatus.hostStatus.map((entry) => ({
        name: entry.host,
        value: entry.host,
      })),
    ];
  }, [data]);

  const subevents = useMemo(() => {
    if (!subeventsData) return [];
    return [
      { name: 'Kein Teilevent', value: '' },
      ...subeventsData.getSubevents.subevents.map((entry) => ({
        name: entry.name,
        value: entry.id,
      })),
    ];
  }, [subeventsData]);

  return (
    <Row style={{ marginTop: 40 }}>
      <Col md={12} lg={6}>
        <h3>Lokale Einstellungen</h3>
        <Form horizontal>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2}>
              Lokaler Drucker
            </Col>
            <Col sm={10}>
              <FormControl
                componentClass="select"
                value={localPrinter}
                onChange={(val) => setLocalPrinter(val.target.value)}
              >
                {onlineHosts.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
              </FormControl>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2}>
              Teilevent zum Einchecken
            </Col>
            <Col sm={10}>
              <FormControl
                componentClass="select"
                value={localPrinterSubevent?.id}
                onChange={(val) => {
                  const subevent = subeventsData.getSubevents.subevents.find(({ id }) => id === parseInt(val.target.value));
                  setLocalPrinterSubevent(subevent || null);
                }}
              >
                {subevents.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
              </FormControl>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
}

export default LocalEventSettings;
