/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const getSettingsAndTicketCategoriesGql = gql`
  query getSettingsAndTicketCategories($eventId: ID!, $eventOrganizerId: ID!, $settingKeys: String!) {
    getSettings (eventId: $eventId, settingKeys: $settingKeys) @rest(type: "[Settings]", path: "events/{args.eventId}/settings?keys={args.settingKeys}") {
      deviceOutputedTemplateByTicketCategory
      deviceReminderTemplateByTicketCategory
      deviceReturnedTemplateByTicketCategory
    }
    getTicketCategories (eventId: $eventId)
      @rest(type: "[TicketCategory]", path: "events/{args.eventId}/ticketCategories") {
      ticketCategories
    }
    getEmailConfig (eventOrganizerId: $eventOrganizerId)
      @rest(type: "EmailConfig", path: "organizers/{args.eventOrganizerId}/emailConfig") {
        emailConfig
    }
  }
`;

export const setSettingsGql = gql`
  mutation setSettings($eventId: ID!, $input: PublishablePostInput!) {
    setSettings (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/settings",
      method: "POST"
    ) { NoResponse }
  }
`;

export const setEmailConfigGql = gql`
  mutation setEmailConfig($eventOrganizerId: ID!) {
    setEmailConfig (eventOrganizerId: $eventOrganizerId, input: $input) @rest(
      path: "organizers/{args.eventOrganizerId}/emailConfig",
      method: "POST"
    ) { NoResponse }
  }
`;

export const getAttributesGql = gql`
  query attributes($eventId: ID!) {
    getAttributes (eventId: $eventId)
      @rest(type: "[Attribute]", path: "events/{args.eventId}/attributes") {
        attributes {
          id
          code
          name
          type
          section
          isVisibleInTable
          sequenceId
          options
          visibility
          settings
        }
    }
  }
`;

export const createAttributeGql = gql`
  mutation createAttribute($eventId: ID!, $input: PublishablePostInput!) {
    createAttribute (eventId: $eventId,  input: $input) @rest(
      path: "events/{args.eventId}/attributes",
      method: "POST"
    ) { id }
  }
`;

export const updateAttributeGql = gql`
  mutation updateAttribute($eventId: ID!, $attributeId: ID!  $input: PublishablePostInput!) {
    updateAttribute (eventId: $eventId, attributeId: $attributeId, input: $input) @rest(
      path: "events/{args.eventId}/attributes/{args.attributeId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteAttributeGql = gql`
  mutation deleteAttribute($eventId: ID!, $attributeId: ID!  $input: PublishablePostInput!) {
    deleteAttribute (eventId: $eventId, attributeId: $attributeId, input: $input) @rest(
      path: "events/{args.eventId}/attributes/{args.attributeId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;

export const getAvailableLabelsGql = gql`
  query availableLabels {
    getAvailableLabels
      @rest(type: "[Attribute]", path: "availableLabels") {
        availableLabels
    }
  }
`;

export const getEventLabelGql = gql`
  query availableLabels($eventId: ID!) {
    getAvailableLabels (eventId: $eventId)
    @rest(type: "label", path: "events/{args.eventId}/label") {
      labelId
      numCopies
      printAfterCreation
    }
  }
`;

export const updateEventLabelGql = gql`
  mutation updateAttribute($eventId: ID!, $input: PublishablePostInput!) {
    updateAttribute (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/label",
      method: "POST"
    ) { NoResponse }
  }
`;
