import { gql } from '@apollo/client';

export const getTicketCategoriesGql = gql`
  query ticketCategories($eventId: ID!) {
    getTicketCategories (eventId: $eventId)
      @rest(type: "[TicketCategory]", path: "events/{args.eventId}/ticketCategories") {
      ticketCategories
    }
  }
`;

export const createTicketCategoryGql = gql`
  mutation createTicketCategory($eventId: ID!, $input: PublishablePostInput!) {
    createTicketCategory (eventId: $eventId,  input: $input) @rest(
      path: "events/{args.eventId}/ticketCategories",
      method: "POST"
    ) { id }
  }
`;

export const updateTicketCategoryGql = gql`
  mutation updateTicketCategory($eventId: ID!, $ticketCategoryId: ID!  $input: PublishablePostInput!) {
    updateTicketCategory (eventId: $eventId, ticketCategoryId: $ticketCategoryId, input: $input) @rest(
      path: "events/{args.eventId}/ticketCategories/{args.ticketCategoryId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteTicketCategoryGql = gql`
  mutation deleteTicketCategory($eventId: ID!, $ticketCategoryId: ID!  $input: PublishablePostInput!) {
    deleteTicketCategory (eventId: $eventId, ticketCategoryId: $ticketCategoryId, input: $input) @rest(
      path: "events/{args.eventId}/ticketCategories/{args.ticketCategoryId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
