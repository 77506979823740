import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { Col, ControlLabel, FormGroup, Panel, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Field, useForm, useFormState } from 'react-final-form';

import { t } from '../../../locale';
import { getTablesGql } from '../gql';
import { getSingleTableGql } from '../../tables/gql';
import FormField from '../../../components/FormField';

export default forwardRef(({ disabled }, ref) => {
  const [getTables] = useLazyQuery(getTablesGql);
  const { values } = useFormState();
  const form = useForm();

  const { data, refetch } = useQuery(getSingleTableGql, {
    skip: !values.eventTable?.id,
    variables: {
      eventId: window.eventId,
      tableId: values.eventTable?.id,
    },
  });

  useImperativeHandle(ref, () => ({
    refetch() {
      if (values.eventTable?.id) refetch();
    },
  }));

  const promiseOptions = async (inputValue) => {
    const result = await getTables({
      variables: {
        eventId: window.eventId,
        filter: inputValue ? JSON.stringify({ name: { $ct: inputValue } }) : '',
      },
    });
    return result.data.getTables.tables.map((table) => ({
      value: table.id,
      label: table.name,
    }));
  };

  const seatOptions = useMemo(() => {
    const seatOptions = [];
    if (data?.getTable) {
      const selectedSeat = (values.eventTable?.seat) ? parseInt(values.eventTable?.seat) : undefined;
      for (let i = 1; i <= data.getTable.seatCount; i++) {
        const attendee = data?.getTable.attendees.find(({ seat }) => seat === i);

        let labelPostfix = 'Frei';
        if (attendee && attendee.id !== values.id) labelPostfix = `${attendee.forename} ${attendee.name}`;
        if (!attendee && selectedSeat === i) labelPostfix = 'Neuer Platz';
        if (attendee && attendee.id === values.id) labelPostfix = 'Aktuell zugewiesener Platz';

        seatOptions.push({
          value: i,
          label: `Sitzplatz ${i} - ${labelPostfix}`,
          disabled: !!attendee && attendee.id !== values.id,
        });
      }
    }
    return seatOptions;
  }, [data?.getTable, values.eventTable?.id, values.eventTable?.seat]);

  useEffect(() => {
    if (values.eventTable?.seat) return;
    const freeSeat = seatOptions.find((seat) => !seat.disabled);
    form.change('eventTable.seat', freeSeat?.value);
  }, [seatOptions]);

  return (
    <Row>
      <Col xs={12}>
        <Panel>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{t('tables.editPage.seatAssignment')}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              <Col xs={6} style={{ marginBottom: 30 }}>
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={2}>Tisch</Col>
                  <Col sm={10}>
                    <Field
                      name="eventTable"
                    >
                      {({ input }) => (
                        <AsyncSelect
                          {...input}
                          value={input.value ? { label: input.value.name, value: input.value.id } : null}
                          onChange={(newValue) => input.onChange(newValue ? { id: newValue.value, name: newValue.label } : null)}
                          defaultOptions
                          isClearable
                          loadOptions={promiseOptions}
                          placeholder="Bitte ein Tisch auswählen"
                          noOptionsMessage={() => 'Kein Tisch gefunden'}
                          isDisabled={disabled}
                        />
                      )}
                    </Field>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={6} xs={12}>
                {values.eventTable && (
                  <FormField
                    name="eventTable.seat"
                    type="select"
                    options={seatOptions}
                    required
                    title="Sitzplatz"
                    labelSize={2}
                    inputSize={10}
                    disabled={disabled}
                  />
                )}
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </Col>
    </Row>
  );
});
