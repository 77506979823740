import React, { useEffect } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap';
import { Field, useForm, useFormState } from 'react-final-form';
import AsyncSelect from 'react-select/async';
import { useLazyQuery, useQuery } from '@apollo/client';
import { get } from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import de from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker.css';

import { isRequired, isValidEmail } from '../lib/validationUtil';
import { t } from '../locale';
import { getCustomersGql, getTitles } from '../screens/customers/gql';

registerLocale('de', de);

export const titleOptions = [
  { label: t('general.titles.mr'), value: 'mr' },
  { label: t('general.titles.mrs'), value: 'mrs' },
  { label: t('general.titles.mrDr'), value: 'mrDr' },
  { label: t('general.titles.mrsDr'), value: 'mrsDr' },
  { label: t('general.titles.mrProf'), value: 'mrProf' },
  { label: t('general.titles.mrsProf'), value: 'mrsProf' },
];

const identity = (value) => (value);

export default function FormField({
  name, type = 'text', title, required = false,
  labelSize = 4, inputSize = 8, options = [],
  noDefaultOption = false, disabled = false,
}) {
  const form = useForm();
  const { values: formValues } = useFormState();
  const [getAttendees, { loading: attendeesLoading }] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'network-only',
  });

  const { data: dynamicTitles } = useQuery(getTitles, {
    skip: !window.eventFeatures.includes('dynamicTitles') || type !== 'titleSelect',
    variables: { eventId: window.eventId },
  });

  useEffect(() => {
    if (type !== 'attendee' || !get(formValues, name) || typeof get(formValues, name) === 'object') return;
    const exec = async () => {
      const { data } = await getAttendees({
        variables: {
          eventId: window.eventId,
          filter: JSON.stringify({ internalId: get(formValues, name) }),
        },
      });

      if (data.getCustomers.participants.length) {
        form.change(name, {
          id: data.getCustomers.participants[0].internalId,
          // eslint-disable-next-line max-len
          name: `${data.getCustomers.participants[0].forename} ${data.getCustomers.participants[0].name}`,
        });
      }
    };
    exec();
  }, [get(formValues, name)]);

  const promiseAttendeeOptions = async (inputValue) => {
    const result = await getAttendees({
      variables: {
        eventId: window.eventId,
        searchTerm: inputValue,
        limit: 10,
      },
    });
    return result.data.getCustomers.participants.map((attendee) => ({
      value: attendee.internalId,
      label: `${attendee.forename} ${attendee.name} (${attendee.ticketCategory.name})`,
    }));
  };

  const renderField = (type, input) => {
    if (type === 'select') {
      return (
        <FormControl componentClass="select" {...input} disabled={disabled}>
          {!required && !noDefaultOption && <option value="">Nicht ausgewählt</option>}
          {options.map((option) => <option value={option.value} disabled={option.disabled}>{option.label}</option>)}
        </FormControl>
      );
    }
    if (type === 'titleSelect') {
      return (
        <FormControl componentClass="select" {...input} disabled={disabled}>
          {dynamicTitles?.getTitles?.length
            ? dynamicTitles?.getTitles.map((title) => <option value={title.code}>{title.name}</option>)
            : titleOptions.map((option) => <option value={option.value} disabled={option.disabled}>{option.label}</option>)}

        </FormControl>
      );
    }
    if (type === 'boolean') {
      return (
        <FormControl componentClass="select" {...input} disabled={disabled}>
          <option value="true">{t('general.form.boolean.true')}</option>
          <option value="false">{t('general.form.boolean.false')}</option>
        </FormControl>
      );
    }

    if (type === 'textarea') return (<FormControl rows="6" componentClass="textarea" {...input} disabled={disabled} />);
    if (type === 'date') {
      const value = input.value ? input.value.split('T')[0] : undefined;
      return (<FormControl {...input} value={value} disabled={disabled} />);
    }
    if (type === 'dateTime') {
      // const value = input.value ? input.value.split('T')[0] : undefined;
      return (
        <DatePickerWrapper>
          <DatePicker
            locale="de"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            className="form-control"
          />
        </DatePickerWrapper>
      );
    }

    if (type === 'attendee') {
      return (
        <AsyncSelect
          {...input}
          value={input.value ? { label: input.value.name, value: input.value.id } : null}
          onChange={(newValue) => input.onChange(newValue ? { id: newValue.value, name: newValue.label } : null)}
          defaultOptions
          isClearable
          isLoading={attendeesLoading}
          loadOptions={promiseAttendeeOptions}
          placeholder={t('customers.attendeeSelector.placeholder')}
          noOptionsMessage={() => t('customers.attendeeSelector.noAttendee')}
          isDisabled={disabled}
        />
      );
    }

    if (type === 'money') {
      return (
        <InputGroup>
          <FormControl {...input} type="number" disabled={disabled} />
          <InputGroup.Addon>€</InputGroup.Addon>
        </InputGroup>
      );
    }

    return (<FormControl {...input} disabled={disabled} />);
  };

  const validators = [];
  if (required) validators.push(isRequired);
  if (type === 'email') validators.push(isValidEmail());

  return (
    <Field
      name={name}
      type={type}
      validate={(value) => {
        for (const validatorFunc of validators) {
          const result = validatorFunc(value);
          if (result) return result;
        }
        return undefined;
      }}
      parse={identity}
    >
      {({ meta, input }) => (
        <FormGroup validationState={meta.touched && meta.error ? 'error' : null}>
          <Col componentClass={ControlLabel} sm={labelSize}>
            {title}
            {required ? '*' : ''}
          </Col>
          <Col sm={inputSize}>
            {renderField(type, input)}
            {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
          </Col>
        </FormGroup>
      )}
    </Field>
  );
}

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
