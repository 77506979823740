import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useMutation } from '@apollo/client';
import UploadModal from '../../../components/UploadModal';
import { t } from '../../../locale';
import { uploadDigistoreCsvMutation, uploadElopageCsvMutation, uploadXlsxMutation } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import ProgressUploadModal from '../../../components/ProgressUploadModal';

function ImportModals({ onImportDone }, ref) {
  const [showXlsxUploadModal, setShowXlsxUploadModal] = useState(false);
  const [showDigitstoreUploadModal, setShowDigitstoreUploadModal] = useState(false);
  const [showElopageUploadModal, setShowElopageUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [lineErrors, setLineErrors] = useState([]);
  useImperativeHandle(ref, () => ({
    openExcelImport: () => setShowXlsxUploadModal(true),
    openDigitStoreImport: () => setShowDigitstoreUploadModal(true),
    openElopageImport: () => setShowElopageUploadModal(true),
  }));

  const mutationParams = {
    onCompleted: ({ uploadXlsx }) => {
      setSelectedFile(null);
      toastHelper.success(t('customers.import.success', { ...uploadXlsx }));
      if (uploadXlsx.errors && uploadXlsx.errors.length) {
        setLineErrors(uploadXlsx.errors);
        return;
      }
      setShowXlsxUploadModal(false);
      setShowDigitstoreUploadModal(false);
      setShowElopageUploadModal(false);
      setLineErrors([]);
      setSelectedFile(null);
    },
    onError: (err) => {
      setSelectedFile(null);
      if (err.networkError.statusCode === 400 && err.networkError.result.missingFields.length) {
        const missingFields = err.networkError.result.missingFields.map((field) => `"${field}"`).join(', ');
        return toastHelper.error(t('imports.errors.importXlsxError400', { missingFields }));
      }
      return toastHelper.error(t('import.error.importXlsxError'));
    },
  };

  const [uploadXlsx, { loading: loadingXlsx }] = useMutation(uploadXlsxMutation, mutationParams);
  const [uploadElopageCsv] = useMutation(uploadElopageCsvMutation, mutationParams);
  const [uploadDigitstoreCsv] = useMutation(uploadDigistoreCsvMutation, mutationParams);

  const onUploadConfirm = async (type) => {
    setLineErrors([]);
    let func = uploadXlsx;
    if (type === 'elopage') func = uploadElopageCsv;
    if (type === 'digistore') func = uploadDigitstoreCsv;
    await func({ variables: { eventId: window.eventId, input: selectedFile } });
    if (onImportDone) onImportDone();
  };

  return (
    <>
      <UploadModal
        show={showXlsxUploadModal}
        title={t('customers.import.title', { event: window.currentEvent })}
        text={t('customers.import.text')}
        loading={loadingXlsx}
        confirmMessage={t('general.import')}
        onConfirm={() => onUploadConfirm('xlsx')}
        onClose={() => { setShowXlsxUploadModal(false); setLineErrors([]); setSelectedFile(null); }}
        setFile={setSelectedFile}
        file={selectedFile}
        lineErrors={lineErrors}
      />
      <UploadModal
        show={showDigitstoreUploadModal}
        title={t('customers.importDigistore.title', { event: window.currentEvent })}
        text={t('customers.importDigistore.text')}
        loading={null}
        confirmMessage={t('general.import')}
        onConfirm={() => onUploadConfirm('digistore')}
        onClose={() => { setShowDigitstoreUploadModal(false); setLineErrors([]); setSelectedFile(null); }}
        setFile={setSelectedFile}
        file={selectedFile}
        lineErrors={lineErrors}
        accept="text/csv"
      />
      <ProgressUploadModal
        show={showElopageUploadModal}
        title={t('customers.importElopage.title', { event: window.currentEvent })}
        text={t('customers.importElopage.text')}
        confirmMessage={t('general.import')}
        onClose={() => { setShowElopageUploadModal(false); setLineErrors([]); setSelectedFile(null); }}
        setFile={setSelectedFile}
        file={selectedFile}
        lineErrors={lineErrors}
        onImportDone={onImportDone}
        accept="text/csv"
      />
    </>
  );
}

export default forwardRef(ImportModals);
