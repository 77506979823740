import React, { useEffect, useState } from 'react';
import { Modal, Button, ListGroup, ProgressBar, ListGroupItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function ReminderEmailModal(props) {
  const { t: tg } = useTranslation('general');
  const {
    show,
    onClose,
    closeMessage = tg('close'),
    confirmMessage = tg('send'),
    confirmBsStyle = 'primary',
  } = props;
  const [importStatus, setImportStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fortschritt berechnen
  const getProgress = (importStatus) => (importStatus.processed / importStatus.totalDevices) * 100;

  useEffect(() => {
    if (!show) setImportStatus(null);
  }, [show]);

  const onSubmit = async () => {
    setImportStatus({
      totalDevices: 0,
      processed: 0,
      skipped: 0,
    });
    setLoading(true);
    const decoder = new TextDecoder();

    const response = await fetch(`/api/v1/events/${window.eventId}/sendMails/deviceReminder`, {
      method: 'POST',
    });
    for await (const chunk of response.body) {
      for (const chunkChunk of decoder.decode(chunk).split('\n').filter((v) => v)) {
        const result = JSON.parse(chunkChunk);
        switch (result.event) {
          case 'sendProgress':
            setImportStatus((prevState) => {
              const state = { ...prevState };
              state.totalDevices = result.totalDevices;
              state.processed = result.processed;
              state.skipped = result.skipped;
              return state;
            });
            break;
          case 'end':
            setImportStatus((prevState) => {
              const state = { ...prevState, end: true };
              return state;
            });
            setLoading(false);
            break;
          default:
            break;
        }
      }
    }
  };

  const getStatusIcon = (status) => {
    if (status.end) return <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />;
    return <FontAwesomeIcon icon={faSpinner} spin style={{ color: 'blue' }} />;
  };

  const renderContent = () => {
    if (importStatus) {
      return (
        <>
          {importStatus?.end ? (<p>Der E-Mail-Versand wurde beendet.</p>) : (<p>Der E-Mail-Versand läuft. Bitte warten...</p>)}
          <ProgressBar now={getProgress(importStatus)} animated />

          <ListGroup variant="flush" className="mt-3">
            <ListGroupItem>
              {getStatusIcon(importStatus)} Erinnerungsmails werden versendet{' '}
              {importStatus.totalDevices ? (`(${importStatus.processed} / ${importStatus.totalDevices})`) : null}
            </ListGroupItem>
          </ListGroup>

          {importStatus.end && (
            <div className="mt-4 p-3 border rounded bg-light">
              <p>
                <strong>E-Mail-Versand abgeschlossen:</strong> <br />
                {importStatus.totalDevices} Teilnehmer mit Geräten wurden gefunden,<br />
                davon wurden <strong>{importStatus.processed - importStatus.skipped}</strong> Teilnehmern eine E-Mail versendet<br />
                und <strong>{importStatus.skipped}</strong> Teilnehmer wurden übersprungen.
              </p>
            </div>
          )}
        </>
      );
    }
    return (
      <span>
        Möchten Sie die Erinnerungsmail wirklich an alle Geräte senden, die noch nicht zurückgegeben wurden?
      </span>
    );
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Erinnerungsmails versenden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderContent()}
      </Modal.Body>
      <Modal.Footer>
        {!importStatus?.end && (
          <Button bsStyle={confirmBsStyle} onClick={onSubmit} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : confirmMessage}
          </Button>
        )}
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          {closeMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
