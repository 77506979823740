import React from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Modal } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import { t } from '../../../locale';

function UserEditDialog({ show, onHide, modalData, onSubmit }) {
  const isNew = !modalData;
  const data = {
    ...modalData,
  };

  const required = (value) => (value ? undefined : t('general.errors.required'));

  const validEmail = () => (value) =>
    (!value || value.toLowerCase()
      // eslint-disable-next-line max-len
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ? undefined
      : t('users.errors.emailError'));

  const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {modalData ? t('users.editUserDialog.title.create') : t('users.editUserDialog.title.edit')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="subheadline">{t('users.editUserDialog.general')}</div>

              <Field
                name="email"
                type="email"
                validate={composeValidators(validEmail(), isNew ? required : () => undefined)}
              >
                {({ meta, input }) => (
                  <FormGroup controlId="formEmail" validationState={meta.touched && meta.error ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('users.email')}*
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} readOnly={!!modalData} />
                      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field
                name="forename"
                type="text"
                validate={isNew ? required : undefined}
              >
                {({ meta, input }) => (
                  <FormGroup controlId="formForename" validationState={meta.touched && meta.error ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('users.forename')}*
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} />
                      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field
                name="name"
                type="text"
                validate={isNew ? required : undefined}
              >
                {({ meta, input }) => (
                  <FormGroup controlId="formName" validationState={meta.touched && meta.error ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('users.name')}*
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} />
                      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field
                name="company"
                type="text"
              >
                {({ meta, input }) => (
                  <FormGroup controlId="formCompany" validationState={meta.touched && meta.error ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('users.company')}
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} />
                      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="role">
                {({ meta, input }) => (
                  <FormGroup controlId="formRole" validationState={meta.touched && meta.error ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('users.role')}
                    </Col>
                    <Col sm={10}>
                      <FormControl componentClass="select" {...input}>
                        <option key="user" value="user">{t('users.user')}</option>
                        <option key="admin" value="admin">{t('users.admin')}</option>
                        <option key="viewer" value="viewer">{t('users.viewer')}</option>
                      </FormControl>
                    </Col>
                  </FormGroup>
                )}
              </Field>

            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {(modalData) ? t('general.save') : t('general.create')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default UserEditDialog;
