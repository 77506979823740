import React, { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/de';
import { useMutation, useQuery } from '@apollo/client';
import { Button, ButtonToolbar, Col, ControlLabel, Form, FormControl, FormGroup, Row, Table } from 'react-bootstrap';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { getSubeventsGql, getHostsStatus, getEventFeaturesGql, addEventFeatureGql, deleteEventFeatureGql } from './gql';
import featureList from './featureList.json';
import FeatureDialog from './components/FeatureDialog';
import toastHelper from '../../lib/toastHelper';
import ConfirmModal from '../../components/ConfirmModal';

function LocalEventSettings() {
  const { t: tg } = useTranslation('general');
  const { t } = useTranslation('settings');
  const [localPrinter, setLocalPrinter] = useLocalStorage(`${window.eventId}_localPrinter`);
  const [localPrinterSubevent, setLocalPrinterSubevent] = useLocalStorage(`${window.eventId}_localPrinterSubevent`);
  const [showModal, setShowModal] = useState(false);
  const [confirmData, setConfirmData] = useState(false);
  const { data } = useQuery(getHostsStatus, {
    variables: {
      eventId: window.eventId,
    },
  });
  const { data: subeventsData } = useQuery(getSubeventsGql, {
    variables: {
      eventId: window.eventId,
    },
  });
  const { data: featuresData, refetch } = useQuery(getEventFeaturesGql, {
    skip: window.systemRole !== 'admin',
    variables: {
      eventId: window.eventId,
    },
  });

  const [addEventFeature] = useMutation(addEventFeatureGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(tg('successMessages.saved')); },
  });

  const [deleteEventFeature, { loading: loadingDelete }] = useMutation(deleteEventFeatureGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(tg('successMessages.deleted')); },
  });

  const onlineHosts = useMemo(() => {
    if (!data) return [];
    return [
      { name: 'Kein Drucker', value: '' },
      ...data.hostStatus.hostStatus.map((entry) => ({
        name: entry.host,
        value: entry.host,
      })),
    ];
  }, [data]);

  const subevents = useMemo(() => {
    if (!subeventsData) return [];
    return [
      { name: 'Kein Teilevent', value: '' },
      ...subeventsData.getSubevents.subevents.map((entry) => ({
        name: entry.name,
        value: entry.id,
      })),
    ];
  }, [subeventsData]);

  const onSubmit = async (values) => {
    await addEventFeature({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });
    setShowModal(false);
  };

  const onDelete = async (feature) => {
    setConfirmData({
      show: true,
      title: t('features.deleteDialog.title'),
      text: t('features.deleteDialog.text', { feature }),
      loading: loadingDelete,
      confirmBsStyle: 'danger',
      confirmMessage: t('features.deleteDialog.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteEventFeature({ variables: { eventId: window.eventId, feature, input: {} } });
        setConfirmData({});
      },
    });
  };

  return (
    <Row style={{ marginTop: 40 }}>
      <Col md={12} lg={6}>
        <h3>Lokale Einstellungen</h3>
        <Form horizontal>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2}>
              Lokaler Drucker
            </Col>
            <Col sm={10}>
              <FormControl
                componentClass="select"
                value={localPrinter}
                onChange={(val) => setLocalPrinter(val.target.value)}
              >
                {onlineHosts.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
              </FormControl>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2}>
              Teilevent zum Einchecken
            </Col>
            <Col sm={10}>
              <FormControl
                componentClass="select"
                value={localPrinterSubevent?.id}
                onChange={(val) => {
                  const subevent = subeventsData.getSubevents.subevents.find(({ id }) => id === parseInt(val.target.value));
                  setLocalPrinterSubevent(subevent || null);
                }}
              >
                {subevents.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
              </FormControl>
            </Col>
          </FormGroup>
        </Form>
      </Col>
      {window.systemRole === 'admin' && (
        <Col md={6} lg={6}>
          <h3>{t('features.headline')}</h3>
          <Button
            bsStyle="primary"
            onClick={() => {
              setShowModal(true);
            }}
          >
            {t('features.enableFeature')}
          </Button>
          <Table striped hover>
            <thead>
              <tr>
                <th>{t('features.feature')}</th>
                <th>{t('features.description')}</th>
              </tr>
            </thead>
            <tbody>
              {(featuresData?.getEventFeatures?.features || []).map((feature) => (
                <tr key={feature}>
                  <td>{featureList[feature]?.name}</td>
                  <td>{featureList[feature]?.description}</td>
                  <td>
                    <ButtonToolbar style={{ float: 'right' }}>
                      <Button
                        bsStyle="danger"
                        onClick={() => onDelete(feature)}
                      >
                        {tg('delete')}
                      </Button>
                    </ButtonToolbar>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
      <FeatureDialog show={showModal} onHide={() => setShowModal(false)} onSubmit={onSubmit} />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
        hideConfirmButton={confirmData.hideConfirmButton}
      />
    </Row>
  );
}

export default LocalEventSettings;
