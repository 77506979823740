import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import FormField from '../../../components/FormField';

function TicketCategoryEditDialog({ show, onHide, modalData, onSubmit, loading, ticketCategories }) {
  const { t } = useTranslation('orders');
  const { t: tg } = useTranslation('general');
  const isNew = !modalData;

  const fields = [
    { name: 'name', title: t('ticketCategories.table.name'), type: 'text', required: true },
    {
      name: 'status',
      title: t('ticketCategories.table.status'),
      type: 'select',
      required: true,
      options: [
        { label: t('ticketCategories.status.active'), value: 'active' },
        { label: t('ticketCategories.status.inactive'), value: 'inactive' },
      ],
    },
    { name: 'sequenceId', title: t('ticketCategories.sequenceId'), type: 'number', required: true },
    { title: t('ticketCategories.price'), type: 'subheadline' },
    { name: 'price', title: t('ticketCategories.price'), type: 'money', required: true },
    { name: 'salesPrice', title: t('ticketCategories.strikePrice'), type: 'money' },
    { title: t('ticketCategories.stockSubHeadline'), type: 'subheadline' },
    { name: 'inventory.stock', title: t('ticketCategories.table.stock'), type: 'number', required: true },
    { name: 'inventory.safetyStock', title: t('ticketCategories.table.safetyStock'), type: 'number' },
  ];

  const initialValues = useMemo(() => {
    if (modalData) return modalData;
    const maxSequenceId = ticketCategories.reduce((acc, cat) => Math.max(acc, cat.sequenceId), 0);
    return {
      status: 'active',
      sequenceId: maxSequenceId + 1,
    };
  }, [modalData, ticketCategories]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {isNew ? t('ticketCategories.modal.addTitle') : t('ticketCategories.modal.editTitle')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {fields
                .map((field) => (
                  field.type !== 'subheadline'
                    ? (
                      <FormField
                        name={field.name}
                        title={field.title}
                        labelSize={2}
                        inputSize={10}
                        type={field.type}
                        options={field.options}
                        required={field.required}
                      />
                    )
                    : <div className="subheadline">{field.title}</div>
                ))}
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {loading
                  ? <FontAwesomeIcon icon={faCircleNotch} spin />
                  : tg('save')}
              </Button>
              <Button onClick={onHide}>{tg('close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default TicketCategoryEditDialog;
