import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Glyphicon, PageHeader,
} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { useDebounce, useLocalStorage, useSessionStorage } from 'react-use';
import styled from 'styled-components';
import Switch from 'react-bootstrap-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { t } from '../../../locale';
import { deleteCustomerGql, getAttributesGql, getCustomersGql, getTicketCategoriesGql, updateCustomerGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import QueryBuilder from '../components/QueryBuilder';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../../components/AdvancedBootstapTable';
import ConfirmModal from '../../../components/ConfirmModal';
import GuestTable from '../components/GuestTable';

function WaitingListPage() {
  const [showOnlyHosts, setShowOnlyHosts] = useLocalStorage(`waitingListShowOnlyHosts_${window.eventId}`, true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmData, setConfirmData] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`waitingListCustomerPaymentSearch_${window.eventId}`, '');
  const [filter, setFilter] = useState({});
  const [tableFilter, setTableFilter] = useState([]);

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const [updateCustomer] = useMutation(updateCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  const waitingListCategories = useMemo(() => (
    categoriesResult?.getTicketCategories?.ticketCategories
      .filter(({ settings }) => settings?.waitingList) || []
  ), [categoriesResult?.getTicketCategories?.ticketCategories]);

  const hostCategories = useMemo(() => (
    categoriesResult?.getTicketCategories?.ticketCategories
      .filter(({ settings }) => settings?.isHost) || []
  ), [categoriesResult?.getTicketCategories?.ticketCategories]);

  const filterToSend = useMemo(() => {
    const waitingListCategoriesIds = waitingListCategories.map(({ id }) => id);
    let filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);
    if (waitingListCategoriesIds && waitingListCategoriesIds.length) filterToSend.$and.push({ ticketCategoryId: { $in: waitingListCategoriesIds } });

    if (showOnlyHosts && hostCategories.length) {
      filterToSend.$and.push({ ticketCategoryId: { $in: hostCategories.map(({ id }) => id) } });
    }

    if (tableFilter && tableFilter.length) {
      filterToSend = { $and: [...tableFilter, ...filterToSend.$and] };
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [filter, tableFilter, waitingListCategories, hostCategories, showOnlyHosts]);

  const { data: { getCustomers: { participants, meta } = { participants: [], meta: {} } } = {}, refetch } = useQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: encodeURIComponent(debouncedSearchTerm?.trim()),
      filter: filterToSend,
      sort: 'registerDate',
    },
    skip: !waitingListCategories,
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const [getCustomers] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const participantByInternalId = useMemo(() => (
    participants.reduce((acc, row) => {
      acc[row.internalId] = row;
      return acc;
    }, {})
  ), [participants]);

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(deleteCustomerGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const handleMoveUp = (customer) => {
    if (!customer.ticketCategory.settings?.destinationCategoryId) return;
    setConfirmData({
      show: true,
      title: t('customersWaitingList.moveFromWaitingListModal.title'),
      text: t('customersWaitingList.moveFromWaitingListModal.text', { customer }),
      loading: deleteCustomerLoading,
      confirmBsStyle: 'primary',
      confirmMessage: t('customersWaitingList.moveFromWaitingListModal.move'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await updateCustomer({
          variables: {
            eventId: window.eventId,
            customerId: customer.id,
            input: { ticketCategory: { id: customer.ticketCategory.settings?.destinationCategoryId } },
          },
        });
        refetch();
        setConfirmData({});
      },
    });
  };

  const handleDelete = (customer, cb) => {
    setConfirmData({
      show: true,
      title: t('customers.deleteCustomer.title'),
      text: t('customers.deleteCustomer.text', { customer }),
      loading: deleteCustomerLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('invites.deleteInvite.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomer({ variables: { eventId: window.eventId, customerId: customer.id } });
        refetch();
        setConfirmData({});
        if (cb) cb();
      },
    });
  };

  const categoryFilterOptions = useMemo(() => {
    if (!waitingListCategories) return [];
    return waitingListCategories.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [waitingListCategories]);

  const columns = [
    {
      text: t('customers.table.forename'),
      dataField: 'forename',
      filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.forename') }),
    },
    { text: t('customers.table.name'), dataField: 'name', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.name') }) },
    /* {
      text: t('customers.table.company'),
      dataField: 'company',
      filter: textFilter({ placeholder: t('customersPayment.table.filterPlaceholder.company') }),
    }, */
    { text: t('customers.table.email'), dataField: 'email', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.email') }) },
    {
      text: t('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      filter: selectFilter({
        options: [{ value: '', label: t('customers.table.filterPlaceholder.allCategories') }, ...categoryFilterOptions],
        placeholder: t('customers.table.filterPlaceholder.ticketCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    {
      text: t('customers.table.linkedCustomer'),
      dataField: 'linkedCustomer',
      formatter: (cellContent, row) => (
        (row.linkedCustomer)
          ? (
            <a href={`/customers/edit/${window.eventId}/${row.linkedCustomer.id}`}>
              {`${row.linkedCustomer?.forename} ${row.linkedCustomer?.name}`}
            </a>
          ) : ''
      ),
    },
    {
      text: t('customers.table.registerDate'),
      dataField: 'registerDate',
      formatter: (cellContent) => cellContent && moment(cellContent).format('lll'),
    },
    {
      dataField: 'id',
      text: 'Status',
      isDummyField: true,
      headerStyle: () => ({ width: '70px' }),
      formatter: (cellContent, row) => {
        const isPayer = row.ticketCategory?.settings?.needsPayment || false;
        return (
          <>
            <StatusIcon glyph="envelope" className={row?.generalInfo?.emailSent ? 'text-success' : ''} />
            {isPayer ? (
              <StatusIcon
                glyph="euro"
                className={
                  row.customAttributes?.donation || row.customAttributes?.donationOper || row.customAttributes?.beitrag
                    ? 'text-success'
                    : 'text-danger'
                }
              />
            ) : null}
          </>
        );
      },
    },
  ];

  if (window.eventRole !== 'viewer') {
    columns.push(
      {
        text: 'Aktion',
        isDummyField: true,
        headerStyle: () => ({ width: '70px' }),
        formatter: (cellContent, row) => (
          <ButtonGroup>
            <Button
              bsStyle="primary"
              title={t('customersWaitingList.moveFromWaitingList')}
              onClick={() => handleMoveUp(row)}
            >
              <Glyphicon glyph="arrow-right" />
            </Button>
            <Button
              bsStyle="danger"
              title={t('general.delete')}
              onClick={() => handleDelete(row)}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </ButtonGroup>
        ),
      },
    );
  }

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        if (name === 'paid') {
          newFilter.push({
            $or: [
              { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
              { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
            ],
          });
          continue;
        }
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  const expandRow = {
    renderer: (customer) => <GuestTable {...customer} getCustomers={getCustomers} onDelete={handleDelete} hideInviteFormButton />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (!hostCategories?.map(({ name }) => name).includes(participantByInternalId[rowKey]?.ticketCategory?.name)) return null;
      return (
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={expanded ? faCaretDown : faCaretRight} />
      );
    },
    expandHeaderColumnRenderer: () => null,
  };

  return (
    <>
      <PageHeader>{t('customersWaitingList.headline')}</PageHeader>

      <QueryBuilder
        attributes={attributeResult?.getAttributes?.attributes}
        ticketCategories={categoriesResult?.getTicketCategories?.ticketCategories}
        onFilterApply={(newFilter) => { setFilter(newFilter); if (JSON.stringify(newFilter) === JSON.stringify(filter)) { refetch(); } }}
        filterPersistKey={`customerPaymentFilter_${window.eventId}`}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup style={{ marginRight: 30 }}>
            <ControlLabel style={{ marginRight: 10 }}>Nur Gastgeber anzeigen</ControlLabel>
            <Switch
              onText={t('general.form.boolean.true')}
              offText={t('general.form.boolean.false')}
              bsSize="small"
              onChange={(el, state) => setShowOnlyHosts(state)}
              value={showOnlyHosts}
            />
          </FormGroup>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <AdvancedBootstapTable
        remote
        keyField="internalId"
        columns={columns}
        data={participants}
        striped
        bordered={false}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta.total || 0,
          sizePerPageList: [10, 25, 50, 100],
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageRenderer,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        expandRow={expandRow}
        filterPersistKey={`waitingListTableFilter_${window.eventId}`}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}

const StatusIcon = styled(Glyphicon)`
    font-size: 20px;
    margin-right: 5px;
`;

export default WaitingListPage;
