import React, { useMemo, useState } from 'react';
import { PageHeader, Button, Table, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { t } from '../../locale';
import { getEvents } from './gql';
import EventEditDialog from './components/EventEditDialog';

export default function EventmanagementPage() {
  const { data } = useQuery(getEvents);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const events = useMemo(() => {
    if (!data?.getEvents?.events?.length) return [];
    return data?.getEvents?.events.filter(({ eventOrganizer }) => eventOrganizer.id === window.eventOrganizerId)
  }, [data?.getEvents?.events]);

  return (
    <>
      <PageHeader>{t('eventMgmt.title')}</PageHeader>
      <Button
        bsStyle="primary"
        /* onClick={() => {
          setModalData(null);
          setShowModal(true);
        }} */
      >
        {t('eventMgmt.addEvents')}
      </Button>
      <Table striped hover>
        <thead>
          <tr>
            <th>{t('eventMgmt.eventName')}</th>
            <th>{t('eventMgmt.eventStart')}</th>
            <th>{t('eventMgmt.eventEnd')}</th>
            <th>{t('eventMgmt.eventAddress')}</th>
            <th>{t('eventMgmt.eventCity')}</th>
            <th>{t('general.action')}</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr key={event.id}>
              <td>
                <a href={`/events/dashboard/${event.id}`}>
                  {event.name}
                </a>
              </td>
              <td>{moment(event.start).format('lll')}</td>
              <td>{moment(event.end).format('lll')}</td>
              <td>{event.street}</td>
              <td>{event.city}</td>
              <td>
                <ButtonToolbar>
                  <Button
                    onClick={() => {
                      setModalData(event);
                      setShowModal(true);
                    }}
                    bsStyle="primary"
                    title={t('general.edit')}
                  >
                    <Glyphicon glyph="pencil" />
                  </Button>
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <EventEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}
