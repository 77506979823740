import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, ControlLabel, FormGroup, Glyphicon, Label, PageHeader, Panel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { t as tg } from '../../../locale';
import { deleteCustomerGql, getCustomersGql, sendTicketGql } from '../../customers/gql';
import toastHelper from '../../../lib/toastHelper';
import AdvancedBootstapTable from '../../../components/AdvancedBootstapTable';
import PageElement from '../../customers/PageElement';
import ConfirmModal from '../../../components/ConfirmModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getOrderGql } from '../gql';

const numberFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

function OrderDetailsPage() {
  const { t } = useTranslation('orders');
  const [confirmData, setConfirmData] = useState({});
  const [sendTicketCustomerId, setSendTicketCustomerId] = useState(null);
  const [showSendTicketModal, setShowSendTicketModal] = useState(null);
  const customerEditModalRef = useRef();
  const { data, loading } = useQuery(getOrderGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      orderId: window.orderId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const { data: customerData, refetch } = useQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: 1000,
      offset: 0,
      searchTerm: '',
      filter: JSON.stringify({ 'customAttributes.orderId': window.orderId }),
      sort: 'internalId:ASC',
    },
  });

  const [sendTicket, { loading: sendTicketLoading }] = useMutation(sendTicketGql);
  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(deleteCustomerGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const order = useMemo(() => data?.getOrder?.order, [data?.getOrder?.order]);

  const fields = [
    { field: 'orderNumber', name: 'Bestellnummer' },
    { field: 'submitDate', name: 'Bestelldatum', formatData: (val) => moment(val).format('lll') },
    { field: 'sourceIp', name: 'IP Adresse' },
    { field: 'paymentString', name: 'Zahlungsmethode' },
    {
      field: 'status',
      name: 'Status',
      formatData: (status) => {
        if (status === 'open') return (<Label bsStyle="danger">{t('status.open')}</Label>);
        if (status === 'complete') return (<Label bsStyle="success">{t('status.complete')}</Label>);
        return (<Label bsStyle="default">Unbekannt</Label>);
      },
    },
    {
      field: 'paymentStatus',
      name: 'Zahlungsstatus',
      formatData: (status) => {
        if (status === 'open') return (<Label bsStyle="danger">{t('paymentStatus.open')}</Label>);
        if (status === 'processing') return (<Label bsStyle="warning">{t('paymentStatus.processing')}</Label>);
        if (status === 'succeeded') return (<Label bsStyle="success">{t('paymentStatus.succeeded')}</Label>);
        return (<Label bsStyle="default">Unbekannt</Label>);
      },
    },
  ];

  const orderSumFields = [
    { field: 'subtotal', name: 'Zwischensumme', formatData: (val) => numberFormatter.format(val) },
    { field: 'taxAmount', name: 'inkl. MwSt (19%)', formatData: (val) => numberFormatter.format(val) },
    { field: 'total', name: 'Gesamtsummer', formatData: (val) => numberFormatter.format(val) },
  ];

  const columns = [
    {
      text: 'Menge',
      dataField: 'quantity',
    },
    {
      text: 'Ticket',
      dataField: 'product.name',
    },
    {
      text: 'Einzelpreis',
      dataField: 'product.name',
      formatter: (cellContent, row) => numberFormatter.format(row.unitSalePrice || row.unitPrice),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
    },
    {
      text: 'Preis',
      dataField: 'extendedPrice',
      formatter: (cellContent) => numberFormatter.format(cellContent),
      headerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
    },
  ];

  const handleDelete = (customer, cb) => {
    setConfirmData({
      show: true,
      title: tg('customers.deleteCustomer.title'),
      text: tg('customers.deleteCustomer.text', { customer }),
      loading: deleteCustomerLoading,
      confirmBsStyle: 'danger',
      confirmMessage: tg('invites.deleteInvite.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomer({ variables: { eventId: window.eventId, customerId: customer.id } });
        setConfirmData({});
        if (cb) cb();
      },
    });
  };

  const onSendTicketConfirm = async () => {
    await sendTicket({
      variables: { eventId: window.eventId, customerId: sendTicketCustomerId },
      onError: () => toastHelper.error(tg('customers.errors.sendTicketGeneralError')),
      onCompleted: () => toastHelper.success(tg('customers.sendTicketDialog.success')),
    });

    setSendTicketCustomerId(null);
    setShowSendTicketModal(null);
  };

  const attendeesColumn = [
    { text: tg('customers.table.forename'), dataField: 'forename' },
    { text: tg('customers.table.name'), dataField: 'name' },
    { text: tg('customers.table.email'), dataField: 'email' },
    {
      text: tg('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      style: { width: '180px' },
      headerStyle: { width: '180px' },
    },
    {
      text: 'Aktion',
      isDummyField: true,
      style: { width: '180px' },
      headerStyle: { width: '180px' },
      formatter: (cellContent, row) => (
        <ButtonGroup>
          {window.eventFeatures.includes('attendeeMgmtV2') ? (
            <Button
              bsStyle="primary"
              title={tg('general.edit')}
              href={`/customers/edit/${window.eventId}/${row.id}`}
            >
              <Glyphicon glyph="pencil" />
            </Button>
          ) : (
            <Button
              bsStyle="primary"
              title={tg('general.edit')}
              onClick={() => customerEditModalRef.current.openEditDialog(row)}
            >
              <Glyphicon glyph="pencil" />
            </Button>
          )}
          <Button
            bsStyle="info"
            title={tg('customers.showTicket')}
            href={`/customers/outputTicket/${window.eventId}/${row.internalId}`}
            target="_blank"
          >
            <Glyphicon glyph="barcode" />
          </Button>
          {(window.eventRole !== 'viewer') && (
            <>
              <Button
                bsStyle="info"
                title={tg('customers.sendEmail')}
                onClick={() => {
                  setShowSendTicketModal(tg('customers.sendTicketDialog.text', { customer: row }));
                  setSendTicketCustomerId(row.id);
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
              <Button
                bsStyle="danger"
                title={tg('general.delete')}
                onClick={() => handleDelete(row)}
              >
                <Glyphicon glyph="remove" />
              </Button>
            </>
          )}
        </ButtonGroup>
      ),
    },
  ];

  if (loading) return (<LoadingSpinner />);

  return (
    <>
      <PageHeader>Bestellung #{order.orderNumber}</PageHeader>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <Button bsStyle="primary" href={order.invoicePdf} target="_blank">
          {t('downloadInvoice')}
        </Button>
      </div>
      <Row>
        <Col xs={12} md={6}>
          <Panel>
            <Panel.Heading>
              <Panel.Title componentClass="h3">Details</Panel.Title>
            </Panel.Heading>
            <Panel.Body style={{ height: 220 }}>
              {fields.map((field) => (
                <Col xs={12} style={{ marginBottom: 8 }}>
                  <FormGroup key={field.field}>
                    <Col componentClass={ControlLabel} sm={2} style={{ paddingLeft: 0 }}>
                      {field.name}
                    </Col>
                    <Col sm={10}>
                      {field.formatData ? field.formatData(order?.[field.field]) : order?.[field.field]}
                    </Col>
                  </FormGroup>
                </Col>
              ))}
            </Panel.Body>
          </Panel>
        </Col>
        <Col xs={12} md={6}>
          <Panel>
            <Panel.Heading>
              <Panel.Title componentClass="h3">Rechnungsaddresse</Panel.Title>
            </Panel.Heading>
            <Panel.Body style={{ height: 220 }}>
              {order?.invoiceAddress?.forename} {order?.invoiceAddress?.name}<br />
              {order?.invoiceAddress?.company}<br />
              {order?.invoiceAddress?.address1}<br />
              {order?.invoiceAddress?.postalCode} {order?.invoiceAddress?.city}<br />
              {order?.invoiceAddress?.country}<br />
              <br />
              {order?.invoiceAddress?.emailAddress}<br />
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Panel>
            <Panel.Heading>
              <Panel.Title componentClass="h3">Bestellte Tickets</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <Row>
                <Col xs={12}>
                  <AdvancedBootstapTable
                    keyField="id"
                    columns={columns}
                    data={order?.lineItems || []}
                    striped
                    bordered={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={{ float: 'right', marginTop: 40, width: 300, paddingRight: 0 }}>
                  {orderSumFields.map((field) => (
                    <Col xs={12} style={{ marginBottom: 4, paddingRight: 0 }}>
                      <FormGroup key={field.field}>
                        <Col componentClass={ControlLabel} sm={4} style={{ paddingLeft: 0, width: 140 }}>
                          {field.name}
                        </Col>
                        <Col sm={6} style={{ width: 100, textAlign: 'right', float: 'right', marginRight: 6 }}>
                          {field.formatData ? field.formatData(order?.[field.field]) : order?.[field.field]}
                        </Col>
                      </FormGroup>
                    </Col>
                  ))}
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>

      {/* Atteendees */}
      <Row>
        <Col xs={12}>
          <Panel>
            <Panel.Heading>
              <Panel.Title componentClass="h3">Teilnehmer</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <Row>
                <Col xs={12}>
                  <BootstrapTable
                    keyField="id"
                    remote
                    data={customerData?.getCustomers?.participants || []}
                    columns={attendeesColumn}
                    striped
                    bordered={false}
                    noDataIndication={() => <p style={{ textAlign: 'center' }}>{tg('general.noData')}</p>}
                  />
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
        hideConfirmButton={confirmData.hideConfirmButton}
      />
      <ConfirmModal
        show={!!showSendTicketModal}
        title={tg('customers.sendTicketDialog.title')}
        text={showSendTicketModal}
        loading={sendTicketLoading}
        confirmMessage={tg('customers.sendTicketDialog.send')}
        onConfirm={onSendTicketConfirm}
        onClose={() => { setShowSendTicketModal(null); setSendTicketCustomerId(null); }}
      />
      <PageElement
        ref={customerEditModalRef}
        onUpdateDone={refetch}
      />
    </>
  );
}

export default OrderDetailsPage;
