import React from 'react';
import { ButtonGroup, Col, Panel, Row, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { t } from '../../../locale';

export default function ParticipantsCrossEvent({ customer }) {
  const columns = [
    { text: t('customers.edit.crossEvent.eventName'), dataField: 'event.name' },
    { text: t('customers.table.forename'), dataField: 'forename' },
    { text: t('customers.table.name'), dataField: 'name' },
    { text: t('customers.table.email'), dataField: 'email' },
    { text: t('customers.table.ticketCategory'), dataField: 'ticketCategoryName' },
    {
      text: t('customers.edit.crossEvent.checkin'),
      dataField: 'presence',
      formatter: (cellContent) => (cellContent ? moment(cellContent).format('L LT') : ''),
    },
    {
      text: 'Aktion',
      isDummyField: true,
      style: { width: '50px' },
      formatter: (cellContent, row) => (
        <ButtonGroup>
          <Button
            bsStyle="primary"
            title={t('customers.edit.crossEvent.viewInEvent')}
            href={`/customers/edit/${row.event.id}/${row.barcode}`}
          >
            <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faLink} />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  if (!customer.participantsCrossEvent?.length) return null;

  return (
    <Row>
      <Col xs={12}>
        <Panel>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{t('customers.edit.crossEvent.title')}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              <Col xs={12}>
                <BootstrapTable
                  keyField="id"
                  data={customer?.participantsCrossEvent || []}
                  columns={columns}
                  striped
                  bordered={false}
                  noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
                />
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </Col>
    </Row>
  );
}
