import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import { getInviteAttributesGql, getInviteCategoriesGql } from '../gql';
import InviteEditAttendees from './InviteEditAttendees';
import FormField from '../../../components/FormField';
import { getSingleCustomer } from '../../customers/gql';

function InviteEditDialog({ show, onHide, modalData, onSubmit }) {
  const isNew = !modalData;

  const { data: inviteAttributeResult } = useQuery(getInviteAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: categoriesResult } = useQuery(getInviteCategoriesGql, {
    variables: { eventId: window.eventId },
  });

  const { data: customerResult } = useQuery(getSingleCustomer, {
    fetchPolicy: 'no-cache',
    skip: isNew,
    variables: {
      eventId: window.eventId,
      id: modalData?.id,
    },
  });

  const invite = useMemo(() => {
    const defaultAttributes = (inviteAttributeResult?.getInviteAttributes?.inviteAttributes || [])
      .reduce((acc, attr) => {
        if (attr.defaultValue === null) return acc;
        acc[attr.code] = attr.defaultValue;
        return acc;
      }, {});
    if (!customerResult?.getCustomer) {
      return {
        customAttributes: defaultAttributes,
        invite: { inviteCategory: { id: categoriesResult?.getInviteCategories?.inviteCategories[0]?.id } },
        title: 'mr',
      };
    }
    const customer = customerResult.getCustomer;
    customer.invite = customerResult.getCustomer.invites.find(({ type }) => type === 'main');
    customer.customAttributes = { ...defaultAttributes, ...customer.customAttributes };
    return customer;
  }, [customerResult?.getCustomer, categoriesResult?.getInviteCategories, inviteAttributeResult?.getInviteAttributes?.inviteAttributes]);

  const handleSubmit = (data) => {
    if (!data?.invite?.inviteCategory?.id) return onSubmit(data);

    if (!customerResult?.getCustomer) {
      // eslint-disable-next-line no-param-reassign
      data.invites = [];
      data.invites.push({ inviteCategory: { id: data?.invite?.inviteCategory.id } });
      return onSubmit(data);
    }

    // eslint-disable-next-line no-param-reassign
    data.invites = customerResult.getCustomer.invites.map((invite) => {
      if (invite.type !== 'main') return { id: invite.id };
      return { id: invite.id, inviteCategory: { id: data?.invite?.inviteCategory.id } };
    });
    return onSubmit(data);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={invite}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {modalData ? t('invites.editInviteDialog.title.create') : t('invites.editInviteDialog.title.edit')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="subheadline">{t('invites.editInviteDialog.general')}</div>

              <FormField name="title" title={t('invites.title')} required labelSize={2} inputSize={10} type="titleSelect" />
              <FormField name="forename" title={t('invites.forename')} required labelSize={2} inputSize={10} />
              <FormField name="name" title={t('invites.name')} required labelSize={2} inputSize={10} />
              <FormField name="company" title={t('invites.company')} labelSize={2} inputSize={10} />
              <FormField name="email" type="email" title={t('invites.email')} required labelSize={2} inputSize={10} />
              <FormField
                name="invite.inviteCategory.id"
                type="select"
                title={t('invites.inviteCategory')}
                required
                labelSize={2}
                inputSize={10}
                options={categoriesResult?.getInviteCategories?.inviteCategories.map((cat) => ({ value: cat.id, label: cat.name })) || []}
              />

              <div className="subheadline">{t('invites.editInviteDialog.additionalFields')}</div>
              {inviteAttributeResult?.getInviteAttributes?.inviteAttributes.map((attribute) => (
                <FormField name={`customAttributes.${attribute.code}`} type={attribute.type} title={attribute.name} labelSize={2} inputSize={10} />
              ))}

              {invite?.status === 'accepted' && <InviteEditAttendees invite={invite} />}

            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {(modalData) ? t('general.save') : t('general.create')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default InviteEditDialog;
