import React, { useMemo, useState } from 'react';
import { PageHeader, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../locale';
import { getHostsGql, updateHostGql } from './gql';
import ConfirmModal from '../../components/ConfirmModal';
import LinkModal from './components/LinkModal';
import { getSubeventsGql } from '../subevents/gql';

export default function PrinterHostsPage() {
  const { data, refetch } = useQuery(getHostsGql, {
    pollInterval: 10_000,
  });

  const { data: subeventsData, loading: loadingSubevents } = useQuery(getSubeventsGql, {
    variables: { eventId: window.eventId },
  });

  const subeventsOptions = useMemo(
    () => subeventsData?.getSubevents.subevents.map((subevent) => ({ label: subevent.name, value: subevent.id })) || [],
    [subeventsData?.getSubevents.subevents],
  );

  const [updateHost, { loading: updateLoading }] = useMutation(updateHostGql);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [linkModalData, setLinkModalData] = useState(null);
  const [unlinkModalData, setUnlinkModalData] = useState(null);

  const onUnlink = async () => {
    await updateHost({
      variables: {
        hostId: unlinkModalData.id,
        input: {
          currentEventId: null,
          currentSubeventId: null,
        },
      },
    });
    refetch();
    setShowUnlinkModal(false);
    setUnlinkModalData(null);
  };

  const onLink = async (values) => {
    await updateHost({
      variables: {
        hostId: linkModalData.id,
        input: {
          currentEventId: window.eventId,
          currentSubeventId: parseInt(values.subeventId),
        },
      },
    });
    refetch();
    setShowLinkModal(false);
    setLinkModalData(null);
  };

  return (
    <>
      <PageHeader>{t('printerHosts.title')}</PageHeader>

      <Table striped hover>
        <thead>
          <tr>
            <th>{t('printerHosts.name')}</th>
            <th>{t('printerHosts.currentEvent')}</th>
            <th>{t('printerHosts.currentSubevent')}</th>
            <th>{t('printerHosts.lastContact')}</th>
            <th>{t('general.action')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.getHosts?.hosts || []).map((host) => {
            let color = 'grey';
            if (host.events.id === window.eventId) {
              color = 'red';
              if (!moment(host.lastPingDate).isBefore(moment().subtract(120, 'second'))) {
                color = 'green';
              }
            }
            return (
              <tr key={host.id}>
                <td style={{ color }}>{host.name}</td>
                <td style={{ color }}>
                  {host.events.name} {host.events.id === window.eventId ? '(Dieses Event)' : ''}
                </td>
                <td style={{ color }}>{host.subevents.name}</td>
                <td style={{ color }}>{host.lastPingDate ? moment(host.lastPingDate).format('lll') : ''}</td>
                <td>
                  <ButtonToolbar>
                    <Button
                      onClick={() => {
                        setLinkModalData(host);
                        setShowLinkModal(true);
                      }}
                      bsStyle="primary"
                      title={t('general.edit')}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </Button>
                    {host.events.id === window.eventId && (
                      <Button
                        onClick={() => {
                          setUnlinkModalData(host);
                          setShowUnlinkModal(true);
                        }}
                        bsStyle="danger"
                        title={t('general.edit')}
                      >
                        <FontAwesomeIcon icon={faLinkSlash} />
                      </Button>
                    )}
                  </ButtonToolbar>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <ConfirmModal
        show={!!showUnlinkModal}
        title={t('printerHosts.deleteModal.title')}
        text={t('printerHosts.deleteModal.text')}
        loading={updateLoading}
        confirmMessage={t('printerHosts.deleteModal.disconnect')}
        onConfirm={onUnlink}
        onClose={() => { setShowUnlinkModal(false); setUnlinkModalData(null); }}
      />
      {!loadingSubevents && (
        <LinkModal
          show={showLinkModal}
          onHide={() => setShowLinkModal(false)}
          onSubmit={onLink}
          subeventsOptions={subeventsOptions}
          selectedSubeventId={linkModalData?.subevents?.id}
        />
      )}
    </>
  );
}
