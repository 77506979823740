/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { Button, Col, Form, PageHeader, Panel, Row } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';
import { getSettingsAndTicketCategoriesGql, setSettingsGql } from '../gql';
import FormField from '../../../components/FormField';
import LoadingSpinner from '../../../components/LoadingSpinner';
import toastHelper from '../../../lib/toastHelper';

function SettingsTemplatePage() {
  const { t } = useTranslation('settings');
  const { t: tg } = useTranslation('general');

  const { data, loading, refetch } = useQuery(getSettingsAndTicketCategoriesGql, {
    variables: {
      eventId: window.eventId,
      settingKeys: [
        'deviceOutputedTemplateByTicketCategory',
        'deviceReminderTemplateByTicketCategory',
        'deviceReturnedTemplateByTicketCategory',
      ].join(','),
    },
  });

  const [setSettings] = useMutation(setSettingsGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const deviceOutputFields = useMemo(
    () => data?.getTicketCategories?.ticketCategories
      .filter((cat) => cat.settings?.type === 'device')
      .map((cat) => ({ name: `deviceOutputedTemplateByTicketCategory.id${cat.id}`, title: cat.name, type: 'string' })) || [],
    [data?.getTicketCategories?.ticketCategories],
  );

  const deviceReminderFields = useMemo(
    () => data?.getTicketCategories?.ticketCategories
      .filter((cat) => cat.settings?.type === 'device')
      .map((cat) => ({ name: `deviceReminderTemplateByTicketCategory.id${cat.id}`, title: cat.name, type: 'string' })) || [],
    [data?.getTicketCategories?.ticketCategories],
  );

  const deviceReturnFields = useMemo(
    () => data?.getTicketCategories?.ticketCategories
      .filter((cat) => cat.settings?.type === 'device')
      .map((cat) => ({ name: `deviceReturnedTemplateByTicketCategory.id${cat.id}`, title: cat.name, type: 'string' })) || [],
    [data?.getTicketCategories?.ticketCategories],
  );

  const onSubmit = async (orgValues) => {
    const values = {};
    if (orgValues.deviceOutputedTemplateByTicketCategory) {
      values.deviceOutputedTemplateByTicketCategory = Object.entries(orgValues.deviceOutputedTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[key.replace('id', '')] = value;
          return acc;
        }, {});
    }
    if (orgValues.deviceReminderTemplateByTicketCategory) {
      values.deviceReminderTemplateByTicketCategory = Object.entries(orgValues.deviceReminderTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[key.replace('id', '')] = value;
          return acc;
        }, {});
    }
    if (orgValues.deviceReturnedTemplateByTicketCategory) {
      values.deviceReturnedTemplateByTicketCategory = Object.entries(orgValues.deviceReturnedTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[key.replace('id', '')] = value;
          return acc;
        }, {});
    }

    await setSettings({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });
    refetch();
  };

  const initialValues = useMemo(() => {
    const settings = { ...data?.getSettings };
    if (settings.deviceOutputedTemplateByTicketCategory) {
      settings.deviceOutputedTemplateByTicketCategory = Object.entries(settings.deviceOutputedTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[`id${key}`] = value;
          return acc;
        }, {});
    }
    if (settings.deviceReminderTemplateByTicketCategory) {
      settings.deviceReminderTemplateByTicketCategory = Object.entries(settings.deviceReminderTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[`id${key}`] = value;
          return acc;
        }, {});
    }
    if (settings.deviceReturnedTemplateByTicketCategory) {
      settings.deviceReturnedTemplateByTicketCategory = Object.entries(settings.deviceReturnedTemplateByTicketCategory)
        .reduce((acc, [key, value]) => {
          acc[`id${key}`] = value;
          return acc;
        }, {});
    }
    return settings;
  }, [data?.getSettings]);

  if (loading) return (<LoadingSpinner />);

  return (
    <PageWrapper>
      <PageHeader>{t('template.emailTemplates')}</PageHeader>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton bsStyle="primary" type="submit">
                {tg('save')}
              </ActionButton>
            </div>
            {(window.eventFeatures && window.eventFeatures.includes('devices')) && (
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title componentClass="h3">{t('template.deviceOutput')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      {deviceOutputFields
                        .map((field) => (
                          field.type !== 'subheadline'
                            ? (
                              <FormField
                                name={field.name}
                                title={field.title}
                                labelSize={3}
                                inputSize={9}
                                type={field.type}
                                options={field.options}
                                required={field.required}
                              />
                            )
                            : <div className="subheadline">{field.title}</div>
                        ))}
                    </Panel.Body>
                  </Panel>
                </Col>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title componentClass="h3">{t('template.deviceReminder')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      {deviceReminderFields
                        .map((field) => (
                          field.type !== 'subheadline'
                            ? (
                              <FormField
                                name={field.name}
                                title={field.title}
                                labelSize={3}
                                inputSize={9}
                                type={field.type}
                                options={field.options}
                                required={field.required}
                              />
                            )
                            : <div className="subheadline">{field.title}</div>
                        ))}
                    </Panel.Body>
                  </Panel>
                </Col>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title componentClass="h3">{t('template.deviceReturn')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      {deviceReturnFields
                        .map((field) => (
                          field.type !== 'subheadline'
                            ? (
                              <FormField
                                name={field.name}
                                title={field.title}
                                labelSize={3}
                                inputSize={9}
                                type={field.type}
                                options={field.options}
                                required={field.required}
                              />
                            )
                            : <div className="subheadline">{field.title}</div>
                        ))}
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            )}
          </Form>
        )}
      />
    </PageWrapper>
  );
}

export default SettingsTemplatePage;

const ActionButton = styled(Button)`
  margin-left: 5px;
`;

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
