import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import FormField from '../../../components/FormField';

function RoomEditDialog({ show, onHide, modalData, onSubmit, loading }) {
  const { t } = useTranslation('rooms');
  const { t: tg } = useTranslation('general');
  const isNew = !modalData;

  const fields = [
    { name: 'name', title: t('name'), type: 'text', required: true },
    { name: 'maxAttendees', title: t('maxAttendees'), type: 'text', required: true },
  ];

  const initialValues = useMemo(() => {
    if (modalData) return modalData;
    return {};
  }, [modalData]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {isNew ? t('modal.addTitle') : t('modal.editTitle')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {fields
                .map((field) => (
                  field.type !== 'subheadline'
                    ? (
                      <FormField
                        name={field.name}
                        title={field.title}
                        labelSize={2}
                        inputSize={10}
                        type={field.type}
                        options={field.options}
                        required={field.required}
                      />
                    )
                    : <div className="subheadline">{field.title}</div>
                ))}
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {loading
                  ? <FontAwesomeIcon icon={faCircleNotch} spin />
                  : tg('save')}
              </Button>
              <Button onClick={onHide}>{tg('close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default RoomEditDialog;
