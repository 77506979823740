import React, { useState } from 'react';
import { Button, ButtonGroup, Glyphicon, PageHeader } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { t } from '../../locale';
import AdvancedBootstapTable from '../../components/AdvancedBootstapTable';
import { getQuotas, updateQuotaGql } from './gql';
import QuotaEditDialog from './components/QuotaDialog';
import toastHelper from '../../lib/toastHelper';

function QuotasPage() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedQuota, setSelectedQuota] = useState(null);
  const { data, refetch } = useQuery(getQuotas, {
    variables: { eventId: window.eventId },
  });

  const [updateQuota] = useMutation(updateQuotaGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  const columns = [
    { text: t('quotas.table.name'), dataField: 'name' },
    { text: t('quotas.table.currentCustomerCount'), dataField: 'currentCustomerCount' },
    { text: t('quotas.table.maxCustomerCount'), dataField: 'maxCustomerCount' },
    {
      text: t('quotas.table.isLocked'),
      dataField: 'isLocked',
      formatter: (cell, row) => {
        const isLocked = row.isLocked || row.currentCustomerCount >= row.maxCustomerCount;
        return t(`general.form.boolean.${isLocked}`);
      },
    },
  ];

  const handleEditQuota = (quota = null) => {
    setSelectedQuota(quota);
    setShowEditModal(true);
  };

  if (window.eventRole !== 'viewer') {
    columns.push(
      {
        text: 'Aktion',
        dataField: 'id',
        isDummyField: true,
        formatter: (_, row) => (
          <ButtonGroup>
            <Button
              bsStyle="primary"
              title={t('general.edit')}
              onClick={() => handleEditQuota(row)}
            >
              <Glyphicon glyph="pencil" />
            </Button>
          </ButtonGroup>
        ),
      },
    );
  }

  const onEditSubmit = async (data) => {
    const { name, maxCustomerCount } = data;
    await updateQuota({
      variables: {
        eventId: window.eventId,
        quotaId: selectedQuota.id,
        input: { name, maxCustomerCount },
      },
    });

    setSelectedQuota(null);
    setShowEditModal(false);
    return refetch();
  };

  return (
    <>
      <PageHeader>{t('quotas.headline')}</PageHeader>
      <StyledContainer>
        <AdvancedBootstapTable
          remote
          keyField="id"
          columns={columns}
          data={data?.getQuotas?.quotas || []}
          striped
          bordered={false}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
      </StyledContainer>
      <QuotaEditDialog
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        modalData={selectedQuota}
        onSubmit={onEditSubmit}
      />
    </>
  );
}

const StyledContainer = styled.div`
  .reset-expansion-style { background-color: #f9f9f9; }
`;

export default QuotasPage;
