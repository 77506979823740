/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getNotReturnedDevicesGql = gql`
  query getNotReturnedDevices($eventId: ID!) {
    getNotReturnedDevices (eventId: $eventId)
      @rest(type: "[Table]", path: "events/{args.eventId}/devices/notReturned") {
        devices
    }
  }
`;
