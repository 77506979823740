import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from '../../../locale';

class GuestTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { guests: [] };
    this.refetch = () => {};
  }

  async UNSAFE_componentWillMount() {
    const { data, refetch } = await this.props.getCustomers({
      variables: {
        eventId: window.eventId,
        limit: 1000,
        offset: 0,
        searchTerm: '',
        filter: JSON.stringify({ 'invite.id': this.props.mainInvite.id }),
        sort: 'internalId:ASC',
      },
      onCompleted: ({ getCustomers }) => {
        this.setState({ guests: getCustomers.participants });
      },
    });
    this.setState({ guests: data.getCustomers.participants });
    this.refetch = refetch;
  }

  async deleteCustomer(row) {
    this.props.onDelete(row, () => {
      this.refetch();
    });
  }

  render() {
    const attendeesColumn = [
      { text: t('customers.table.forename'), dataField: 'forename' },
      { text: t('customers.table.name'), dataField: 'name' },
      { text: t('customers.table.email'), dataField: 'email' },
      { text: t('customers.table.ticketCategory'), dataField: 'ticketCategory.name' },
      { text: t('customers.table.seat'), dataField: 'eventTable.seat' },
      {
        dataField: 'id',
        text: 'Status',
        isDummyField: true,
        formatter: (cellContent, row) => {
          const isPayer = row.ticketCategory?.settings?.needsPayment || false;
          return (
            <>
              <StatusIcon glyph="envelope" className={row?.generalInfo?.emailSent ? 'text-success' : ''} />
              {isPayer ? (
                <StatusIcon
                  glyph="euro"
                  className={
                    row.customAttributes?.donation || row.customAttributes?.donationOper || row.customAttributes?.beitrag
                      ? 'text-success'
                      : 'text-danger'
                  }
                />
              ) : null}
            </>
          );
        },
      },
      {
        text: 'Aktion',
        isDummyField: true,
        formatter: (cellContent, row) => (
          <ButtonGroup>
            <Button
              bsStyle="primary"
              title={t('general.edit')}
              href={`/customers/edit/${window.eventId}/${row.id}`}
            >
              <Glyphicon glyph="pencil" />
            </Button>
            <Button
              bsStyle="info"
              title={t('customers.showTicket')}
              href={`/customers/outputTicket/${window.eventId}/${row.internalId}`}
              target="_blank"
            >
              <Glyphicon glyph="barcode" />
            </Button>
            <Button
              bsStyle="danger"
              title={t('general.delete')}
              onClick={() => this.deleteCustomer(row)}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </ButtonGroup>
        ),
      },
    ];

    return (
      <div style={{ marginBottom: 10, padding: '0px 30px' }}>
        <h4>Gäste</h4>
        <BootstrapTable
          keyField="id"
          remote
          data={this.state.guests}
          columns={attendeesColumn}
          striped
          bordered={false}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
        <Button bsStyle="info" href={this.props.mainInvite?.url} target="_blank">
          {t('invites.gotoInviteForm')}
        </Button>

      </div>
    );
  }
}

const StatusIcon = styled(Glyphicon)`
    font-size: 20px;
    margin-right: 5px;
`;

export default GuestTable;
