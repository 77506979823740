import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';

import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import FormField from '../../../components/FormField';

function TicketCategoryEditDialog({ show, onHide, modalData, onSubmit }) {
  const { t } = useTranslation('ticketCategories');
  const { t: tg } = useTranslation('general');
  const data = {
    name: '',
    highlightOnScan: false,
    printOnLabel: false,
    settings: { type: 'entry' },
    ...modalData,
  };

  const fields = useMemo(() => [
    { name: 'name', title: t('table.name'), type: 'string' },
    {
      name: 'settings.type',
      title: t('table.type'),
      type: 'select',
      options: [
        { label: t('ticketTypes.entry'), value: 'entry' },
        { label: t('ticketTypes.device'), value: 'device' },
        { label: t('ticketTypes.invite'), value: 'invite' },
        { label: t('ticketTypes.inviteRejected'), value: 'inviteRejected' },
        { label: t('ticketTypes.registered'), value: 'registered' },
        { label: t('ticketTypes.placeholder'), value: 'placeholder' },
        { label: t('ticketTypes.waitingList'), value: 'waitingList' },
      ],
      noDefaultOption: true,
    },
    { name: 'highlightOnScan', title: t('table.highlightOnScan'), type: 'boolean' },
    { name: 'printOnLabel', title: t('table.printOnLabel'), type: 'boolean' },
  ], []);

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {(modalData) ? t('createDialog.titleEdit') : t('createDialog.title')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {fields
                .map((field) => (
                  field.type !== 'subheadline'
                    ? (
                      <FormField
                        name={field.name}
                        title={field.title}
                        labelSize={2}
                        inputSize={10}
                        type={field.type}
                        options={field.options}
                        required={field.required}
                        noDefaultOption={field.noDefaultOption}
                      />
                    )
                    : <div className="subheadline">{field.title}</div>
                ))}
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {(modalData) ? tg('save') : tg('create')}
              </Button>
              <Button onClick={onHide}>{tg('close')}</Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
}

export default TicketCategoryEditDialog;
