import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { t } from '../../../locale';

export default function InviteEditAttendees({ invite }) {
  const columns = [
    { text: t('customers.table.id'), dataField: 'id' },
    { text: t('customers.table.forename'), dataField: 'forename' },
    { text: t('customers.table.name'), dataField: 'name' },
    { text: t('customers.table.email'), dataField: 'email' },
    { text: t('customers.table.seat'), dataField: 'customAttributes.seat' },
  ];

  return (
    <>
      <div className="subheadline" style={{ marginTop: 50 }}>{t('invites.editInviteDialog.attendees')}</div>
      <BootstrapTable
        keyField="id"
        columns={columns}
        data={invite.attendees}
        striped
        bordered={false}
      />
    </>
  );
}
