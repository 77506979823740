import React, { useMemo, useState } from 'react';
import { ButtonGroup, Col, Glyphicon, Panel, Row, Button } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { useFormState } from 'react-final-form';
import styled from 'styled-components';

import { t } from '../../../locale';
import { getCustomerEmailLogs, sendMailGql, sendTicketGql } from '../gql';
import ConfirmModal from '../../../components/ConfirmModal';
import toastHelper from '../../../lib/toastHelper';

export default function EmailLogPanel({ ticketCategories }) {
  const [viewModal, setViewModal] = useState({ show: false });
  const [sendMailModal, setSendMailModal] = useState({ show: false });
  const [showSendTicketModal, setShowSendTicketModal] = useState(null);
  const { values } = useFormState();
  const { data: emailLogsResult, refetch: refetchEmailLogs } = useQuery(getCustomerEmailLogs, {
    fetchPolicy: 'no-cache',
    skip: !window.customerId,
    variables: {
      eventId: window.eventId,
      id: window.customerId,
    },
  });

  const [sendMail, { loading: sendMailLoading }] = useMutation(sendMailGql);
  const [sendTicket, { loading: sendTicketLoading }] = useMutation(sendTicketGql);

  const currentTicketCategory = useMemo(() => {
    if (!ticketCategories || !values?.ticketCategory?.id) return null;
    return ticketCategories.find(({ id }) => id === values?.ticketCategory?.id);
  }, [values?.ticketCategory?.id, ticketCategories]);

  const wasTicketMailAlreadySent = useMemo(() => (
    !!emailLogsResult?.getCustomerEmailLogs.find(({ type }) => type === 'ticket')
  ), [emailLogsResult?.getCustomerEmailLogs]);

  const attendeesColumn = [
    {
      text: t('customers.edit.emailLog.type'),
      dataField: 'type',
      formatter: (cellContent) => t(`customers.edit.emailLog.typeEnum.${cellContent}`),
    },
    { text: t('customers.edit.emailLog.email'), dataField: 'email' },
    { text: t('customers.edit.emailLog.subject'), dataField: 'subject' },
    {
      text: t('customers.edit.emailLog.created'),
      dataField: 'created',
      formatter: (cellContent) => moment(cellContent).format('L LT'),
    },
    {
      text: 'Aktion',
      isDummyField: true,
      style: { width: '50px' },
      formatter: (cellContent, row) => (
        <ButtonGroup>
          {row.htmlMessage && (
            <Button
              bsStyle="primary"
              title={t('customers.edit.emailLog.viewEmail')}
              onClick={() => {
                const text = row.htmlMessage;
                setViewModal({ show: true, text });
              }}
            >
              <Glyphicon glyph="eye-open" />
            </Button>
          )}
        </ButtonGroup>
      ),
    },
  ];

  const onResendMail = async () => {
    await sendMail({
      variables: {
        eventId: window.eventId,
        customerId: window.customerId,
        input: {},
      },
    });
    setSendMailModal({ show: false });
    await refetchEmailLogs();
  };

  const onSendTicketConfirm = async () => {
    await sendTicket({
      variables: {
        eventId: window.eventId,
        customerId: window.customerId,
      },
      onError: () => toastHelper.error(t('customers.errors.sendTicketGeneralError')),
      onCompleted: () => toastHelper.success(t('customers.sendTicketDialog.success')),
    });
    setShowSendTicketModal(null);
    await refetchEmailLogs();
  };

  if (!currentTicketCategory || currentTicketCategory.settings?.type === 'placeholder') return null;

  return (
    <Row>
      <Col xs={12}>
        <Panel>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{t('customers.edit.emailLog.title')}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              <Col xs={12}>
                <BootstrapTable
                  keyField="id"
                  data={emailLogsResult?.getCustomerEmailLogs || []}
                  columns={attendeesColumn}
                  striped
                  bordered={false}
                  noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ActionButton onClick={() => setSendMailModal({ show: true })}>
                  {currentTicketCategory.settings?.type === 'invite'
                    ? t('customers.edit.emailLog.emailButton.invite')
                    : t('customers.edit.emailLog.emailButton.confirm')}
                </ActionButton>
                {currentTicketCategory.settings?.type !== 'invite' && (
                  <ActionButton onClick={() => setShowSendTicketModal(t('customers.sendTicketDialog.text', { customer: values }))}>
                    {wasTicketMailAlreadySent
                      ? t('customers.edit.emailLog.emailButton.ticketResend')
                      : t('customers.edit.emailLog.emailButton.ticketSend')}
                  </ActionButton>
                )}
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </Col>
      <ConfirmModal
        show={viewModal.show}
        text={viewModal.text}
        title={t('customers.edit.emailLog.sentEmail')}
        onClose={() => setViewModal({ show: false })}
        hideConfirmButton
      />
      <ConfirmModal
        show={sendMailModal.show}
        text={currentTicketCategory.settings?.type === 'invite'
          ? t('customers.edit.emailLog.emailConfirm.textInvite')
          : t('customers.edit.emailLog.emailConfirm.textConfirm')}
        title={t('customers.edit.emailLog.emailConfirm.title')}
        onClose={() => setSendMailModal({ show: false })}
        onConfirm={onResendMail}
        confirmMessage={t('customers.edit.emailLog.emailConfirm.send')}
        loading={sendMailLoading}
      />
      <ConfirmModal
        show={!!showSendTicketModal}
        title={t('customers.sendTicketDialog.title')}
        text={showSendTicketModal}
        loading={sendTicketLoading}
        confirmMessage={t('customers.sendTicketDialog.send')}
        onConfirm={onSendTicketConfirm}
        onClose={() => { setShowSendTicketModal(null); }}
      />
    </Row>
  );
}

const ActionButton = styled(Button)`
  margin-left: 5px;
`;
