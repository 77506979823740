import React, { useMemo, useState } from 'react';
import { ButtonGroup, Glyphicon, PageHeader, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { t } from '../../../locale';
import { getSubeventPresencesGql, getSubeventsGql } from '../gql';
import { deletePresenceGql } from '../../customers/gql';
import toastHelper from '../../../lib/toastHelper';
import ConfirmModal from '../../../components/ConfirmModal';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default function SubeventPresencePage() {
  const [confirmData, setConfirmData] = useState({});
  const { data, refetch, loading } = useQuery(getSubeventPresencesGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      subeventId: window.subeventId,
    },
  });

  const { data: subeventResult, loading: subeventLoading } = useQuery(getSubeventsGql, {
    variables: {
      eventId: window.eventId,
    },
  });

  const [deletePresence, { loading: deletePresenceLoading }] = useMutation(deletePresenceGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const subevent = useMemo(() => {
    if (!subeventResult?.getSubevents?.subevents) return null;
    return subeventResult.getSubevents.subevents.find(({ id }) => id === parseInt(window.subeventId));
  }, [subeventResult?.getSubevents?.subevents]);

  const presences = useMemo(() => {
    if (!data?.getSubeventPresences?.subeventPresences?.length) return [];
    return data?.getSubeventPresences?.subeventPresences;
  }, [data?.getSubeventPresences?.subeventPresences]);

  const handlePresenceDelete = (row) => {
    setConfirmData({
      show: true,
      title: t('customers.checkins.deletePresence.title'),
      text: t('customers.checkins.deletePresence.text', { customer: row }),
      loading: deletePresenceLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('customers.checkins.deletePresence.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deletePresence({ variables: { eventId: window.eventId, customerId: row.id, subeventId: window.subeventId, input: {} } });
        setConfirmData({});
      },
    });
  };

  const columns = [
    {
      text: 'Name',
      dataField: 'forename',
      formatter: (_, row) => `${row.forename} ${row.name}`,
    },
    {
      text: 'Status',
      dataField: 'id',
      formatter: () => (
        <div className="text-success">
          <i className="fa fa-check" /> Eingecheckt
        </div>
      ),
    },
    { text: 'Station/Benutzer', dataField: 'host' },
    {
      text: 'Eingecheckt am',
      dataField: 'date',
      formatter: (_, row) => moment(row.date).format('L LTS'),
    },
    {
      text: 'Aktion',
      dataField: 'id',
      isDummyField: true,
      style: { width: '140px' },
      formatter: (_, row) => (
        <ButtonGroup>
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            onClick={() => handlePresenceDelete(row)}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  if (loading || subeventLoading) return (<LoadingSpinner />);

  return (
    <>
      <PageHeader>{t('subeventPresences.headline', { subevent })}</PageHeader>

      <BootstrapTable
        remote
        keyField="id"
        columns={columns}
        data={presences}
        striped
        bordered={false}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}
