import { gql } from '@apollo/client';

export const getUsersGql = gql`
  query users($eventId: ID!) {
    getUsers (eventOrganizerId: $eventOrganizerId)
      @rest(type: "[User]", path: "organizers/{args.eventOrganizerId}/users") {
          userId
          forename
          name
          email
          company
          role
    }
  }
`;

export const getUserGql = gql`
  query user($eventOrganizerId: ID!, $userId: ID!) {
    getUser (eventOrganizerId: $eventOrganizerId, userId: $userId)
      @rest(type: "User", path: "organizers/{args.eventOrganizerId}/users") {
        userId
        forename
        name
        email
        company
        role
    }
  }
`;

export const addUserGql = gql`
  mutation addUser($eventOrganizerId: ID!, $input: PublishablePostInput!) {
    addUser (eventOrganizerId: $eventOrganizerId, input: $input) @rest(
      path: "organizers/{args.eventOrganizerId}/users",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateUserGql = gql`
  mutation updateUser($eventOrganizerId: ID!, $userId: ID!  $input: PublishablePostInput!) {
    updateUser (eventOrganizerId: $eventOrganizerId, userId: $userId, input: $input) @rest(
      path: "organizers/{args.eventOrganizerId}/users/{args.userId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteUserGql = gql`
  mutation deleteUser($eventOrganizerId: ID!, $userId: Int!) {
    deleteUser (eventOrganizerId: $eventOrganizerId, userId: $userId) @rest(
      path: "organizers/{args.eventOrganizerId}/users/{args.userId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
