import React from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import { t } from '../../../locale';

function GenerateInviteDialog({ show, onHide, onSubmit }) {
  const data = {
    maxCustomerCount: 1,
    count: 10,
  };

  const minValue = (min) => (value) =>
    (Number.isNaN(value) || value >= min ? undefined : t('invites.errors.minValueError', { value: min }));

  const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {t('invites.createInvitesDialog.title')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="subheadline">{t('invites.createInvitesDialog.general')}</div>

              <Field
                name="count"
                type="number"
                validate={composeValidators(minValue(1))}
              >
                {({ meta, input }) => (
                  <FormGroup controlId="formCount">
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('invites.createInvitesDialog.count')}
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} />
                      {meta.error && <span>{meta.error}</span>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field
                name="maxCustomerCount"
                type="number"
                validate={composeValidators(minValue(1))}
              >
                {({ meta, input }) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('invites.maxCustomerCount')}
                    </Col>
                    <Col sm={10}>
                      <FormControl {...input} />
                      {meta.error && <span>{meta.error}</span>}
                    </Col>
                  </FormGroup>
                )}
              </Field>

            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                { t('invites.createInvitesDialog.generate')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default GenerateInviteDialog;
