import React from 'react';
import { Label, PageHeader, Table } from 'react-bootstrap';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/de';
import { useQuery } from '@apollo/client';
import { t } from '../../locale';
import { getHostsStatus } from './gql';

momentDurationFormatSetup(moment);
moment.locale('de');

function HostStatus() {
  const { data } = useQuery(getHostsStatus, {
    variables: {
      eventId: window.eventId,
    },
    pollInterval: 2000,
  });

  const renderStatusLabel = (hostStatus) => {
    const timeSinceLastPing = moment().diff(moment(hostStatus.lastPingDate));
    if (timeSinceLastPing > 60000) return (<Label bsStyle="danger">Offline</Label>);
    return (<Label bsStyle="success">Aktiv</Label>);
  };

  return (
    <>
      <PageHeader>Status der Stationen</PageHeader>
      <p>Letztes Update: {moment().format('ll LTS')}</p>
      <p>Letztes Update Sequenz: {data?.hostStatus?.updateSeq}</p>
      <Table striped hover>
        <thead>
          <tr>
            <th>{t('hostStatus.host')}</th>
            <th>{t('hostStatus.status')}</th>
            <th>{t('hostStatus.lastPingDate')}</th>
            <th>{t('hostStatus.currentSubevent')}</th>
            <th>{t('hostStatus.currenUpdateSeq')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.hostStatus.hostStatus || []).map((hostStatus) => (
            <tr key={hostStatus.host}>
              <td>{hostStatus.host}</td>
              <td>{renderStatusLabel(hostStatus)}</td>
              <td>{moment(hostStatus.lastPingDate).format('ll LTS')}</td>
              <td>{hostStatus.subeventName}</td>
              <td>
                <span style={{ color: hostStatus.currentUpdateSeq < data.hostStatus.updateSeq ? 'red' : undefined }}>
                  {hostStatus.currentUpdateSeq}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default HostStatus;
