import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, FormControl, Alert, ListGroup, ProgressBar, ListGroupItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSpinner, faCheckCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { t } from '../locale';

export default function ProgressUploadModal(props) {
  const {
    show,
    title = '',
    text,
    onClose,
    closeMessage = t('general.close'),
    confirmMessage = t('general.save'),
    confirmBsStyle = 'primary',
    accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    file,
    onImportDone,
  } = props;
  const fileInputRef = useRef();
  const [importStatus, setImportStatus] = useState(null);
  const [lineErrors, setLineErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fortschritt berechnen
  const getProgress = () => {
    const stepsDone = Object.values(importStatus).filter(({ status }) => status === 'done').length;
    return (stepsDone / 3) * 100;
  };

  useEffect(() => {
    if (!file && fileInputRef.current?.value) fileInputRef.current.value = null;
  }, [file]);

  const onSubmit = async () => {
    setImportStatus({
      upload: { status: 'inProgress' }, // "pending" oder "done"
      read: { status: 'pending' },
      import: { status: 'pending' },
    });
    setLoading(true);
    const decoder = new TextDecoder();

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`/api/v1/events/${window.eventId}/imports/elopageCsv`, {
      method: 'POST',
      body: formData,
    });
    for await (const chunk of response.body) {
      for (const chunkChunk of decoder.decode(chunk).split('\n').filter((v) => v)) {
        const result = JSON.parse(chunkChunk);
        switch (result.event) {
          case 'loadStarted':
            setImportStatus((prevState) => {
              const state = { ...prevState };
              state.upload.status = 'done';
              state.read.status = 'inProgress';
              return state;
            });
            break;
          case 'loadProgress':
            setImportStatus((prevState) => {
              const state = { ...prevState };
              state.read.processedTickets = result.processedTickets;
              state.read.totalTickets = result.totalTickets;
              return state;
            });
            break;
          case 'importStarted':
            setImportStatus((prevState) => {
              const state = { ...prevState };
              state.upload.status = 'done';
              state.read.status = 'done';
              state.import.status = 'inProgress';
              return state;
            });
            break;
          case 'end':
            setImportStatus((prevState) => {
              const state = { ...prevState };
              state.result = result.result;
              state.import.status = 'done';
              state.read.processedTickets = state.read.totalTickets;
              return state;
            });
            setLineErrors(result.result.errors);
            setLoading(false);
            break;
          default:
            break;
        }
      }
    }
    onImportDone && onImportDone();
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'done': return <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />;
      case 'inProgress': return <FontAwesomeIcon icon={faSpinner} spin style={{ color: 'blue' }} />;
      default: return <FontAwesomeIcon icon={faPauseCircle} style={{ color: 'gray' }} />;
    }
  };

  const renderContent = () => {
    if (importStatus) {
      return (
        <>
          {importStatus?.result ? (<p>Der Importprozess wurde beendet.</p>) : (<p>Der Importprozess läuft. Bitte warten...</p>)}
          <ProgressBar now={getProgress()} animated />

          <ListGroup variant="flush" className="mt-3">
            <ListGroupItem>
              {getStatusIcon(importStatus.upload.status)} 1. Datei hochladen
            </ListGroupItem>
            <ListGroupItem>
              {getStatusIcon(importStatus.read.status)} 2. Daten einlesen{' '}
              {importStatus.read.totalTickets ? (`(${importStatus.read.processedTickets} / ${importStatus.read.totalTickets})`) : null}
            </ListGroupItem>
            <ListGroupItem>
              {getStatusIcon(importStatus.import.status)} 3. Daten importieren
            </ListGroupItem>
          </ListGroup>

          {importStatus.result && (
            <div className="mt-4 p-3 border rounded bg-light">
              <p>
                <strong>Import abgeschlossen:</strong> <br />
                {importStatus.result.ticketCount} Teilnehmer wurden gefunden,<br />
                davon wurden <strong>{importStatus.result.insertedTicketCount}</strong> Teilnehmer importiert,<br />
                <strong>{importStatus.result.updatedTicketCount}</strong> Teilnehmer aktualisiert,<br />
                <strong>{importStatus.result.skippedTicketCount}</strong> Teilnehmer übersprungen <br />
                und <strong className="text-danger">{importStatus.result.ticketsWithErrorCount}</strong> Teilnehmer wiesen einen Fehler auf.
              </p>
            </div>
          )}

          {lineErrors?.length ? (
            <Alert bsStyle="warning">
              <b>{t('import.errorHeadline')}</b>
              <br /><br />
              <ul>
                {lineErrors.map(({ lineNumber, errors }, index) => (
                  <li key={index}>
                    <b>{t('import.lineNumber', { lineNumber })}:</b>
                    {' '}
                    {errors.map((error) => <>{t(`import.customerErrors.${error.code}`, { error })}<br /></>)}
                  </li>
                ))}
              </ul>
            </Alert>
          ) : null}
        </>
      );
    }
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        <FormControl
          inputRef={fileInputRef}
          type="file"
          accept={accept}
          onChange={(e) => props.setFile(e.target.files[0])}
        />
      </>
    );
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderContent()}
      </Modal.Body>
      <Modal.Footer>
        {!importStatus?.result && (
          <Button bsStyle={confirmBsStyle} onClick={onSubmit} disabled={loading || !props.file}>
            {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : confirmMessage}
          </Button>
        )}
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          {closeMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
