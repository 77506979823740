import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { getPaymentStatisticsGql } from '../gql';
import DashboardPanel from './DashboardPanel';

const currencyFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

export default function PaymentStatistics() {
  const hasPaymentMgmtFeature = window.eventFeatures && window.eventFeatures.includes('paymentMgmt');
  const { data: paymentStatisticsResult } = useQuery(getPaymentStatisticsGql, {
    skip: !hasPaymentMgmtFeature,
    variables: { eventId: window.eventId },
  });

  if (!hasPaymentMgmtFeature) return null;
  return (
    <>
      <PageHeader>Zahlungen</PageHeader>
      <Row>
        <Col md={6}>
          <DashboardPanel
            name="Zahlung erwartet"
            value={paymentStatisticsResult?.getPaymentStatistics?.notPaidCount}
            icon="clock-o"
            color="yellow"
            link={`/customers/paymentOverview/${window.eventId}?filter=${encodeURIComponent('{"paid":"false"}')}`}
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Zahlung erfolgt"
            value={paymentStatisticsResult?.getPaymentStatistics?.paidCount}
            icon="check"
            color="green"
            link={`/customers/paymentOverview/${window.eventId}?filter=${encodeURIComponent('{"paid":"true"}')}`}
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Summe Beitrag Privat"
            value={currencyFormatter.format(paymentStatisticsResult?.getPaymentStatistics?.beitragSum)}
            icon="eur"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Summe Beitrag Sponsoring"
            value={currencyFormatter.format(paymentStatisticsResult?.getPaymentStatistics?.beitragSponsoringSum)}
            icon="eur"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Summe Spenden - PV"
            value={currencyFormatter.format(paymentStatisticsResult?.getPaymentStatistics?.donationSum)}
            icon="money"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Summe Spenden - Oper"
            value={currencyFormatter.format(paymentStatisticsResult?.getPaymentStatistics?.donationOperSum)}
            icon="money"
          />
        </Col>
      </Row>
    </>
  );
}
