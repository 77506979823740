import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import { getSingleQuota } from '../gql';
import FormField from '../../../components/FormField';

function QuotaEditDialog({ show, onHide, modalData, onSubmit }) {
  const isNew = !modalData;

  const { data: customerResult } = useQuery(getSingleQuota, {
    fetchPolicy: 'no-cache',
    skip: isNew,
    variables: {
      eventId: window.eventId,
      quotaId: modalData?.id,
    },
  });

  const quota = useMemo(() => (
    customerResult?.getSingleQuota || {}
  ), [customerResult?.getSingleQuota]);

  const handleSubmit = (data) => {
    if (!data?.invite?.inviteCategory?.id) return onSubmit(data);

    if (!customerResult?.getCustomer) {
      // eslint-disable-next-line no-param-reassign
      data.invites = [];
      data.invites.push({ inviteCategory: { id: data?.invite?.inviteCategory.id } });
      return onSubmit(data);
    }

    // eslint-disable-next-line no-param-reassign
    data.invites = customerResult.getCustomer.invites.map((invite) => {
      if (invite.type !== 'main') return { id: invite.id };
      return { id: invite.id, inviteCategory: { id: data?.invite?.inviteCategory.id } };
    });
    return onSubmit(data);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={quota}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {modalData ? t('quotas.editDialog.title.create') : t('quotas.editDialog.title.edit')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormField name="name" title={t('quotas.table.name')} required labelSize={2} inputSize={10} />
              <FormField name="maxCustomerCount" title={t('quotas.table.maxCustomerCount')} required labelSize={2} inputSize={10} />
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {(modalData) ? t('general.save') : t('general.create')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default QuotaEditDialog;
