import React, { useState } from 'react';
import { Button, ButtonToolbar, PageHeader, Table } from 'react-bootstrap';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { t } from '../../locale';
import SubeventEditDialog from './components/SubeventEditDialog';
import { getSubeventsGql, addSubeventGql, deleteSubeventGql, updateSubeventGql } from './gql';
import ConfirmModal from '../../components/ConfirmModal';

function SubeventsPage() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(null);
  const [deleteSubeventId, setDeleteSubeventId] = useState();

  const { data, refetch } = useQuery(getSubeventsGql, {
    variables: { eventId: window.eventId },
  });
  const [addSubevent] = useMutation(addSubeventGql);
  const [updateSubevent] = useMutation(updateSubeventGql);
  const [deleteSubevent] = useMutation(deleteSubeventGql);

  const onSubmit = async (incomingValues) => {
    const values = { ...incomingValues };
    const subeventId = values.id;
    delete values.barcode;

    if (subeventId) {
      delete values.id;
      await updateSubevent({
        variables: {
          eventId: window.eventId,
          subeventId,
          input: values,
        },
      });
      setShowModal(false);
      setModalData(null);
      refetch();
      return;
    }

    await addSubevent({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });
    setShowModal(false);
    setModalData(null);
    refetch();
  };

  const onDelete = async (subevent) => {
    setDeleteSubeventId(subevent.id);
    setShowDeletePrompt(t('subevents.deleteSubeventDialog.text', subevent));
  };

  const onDeleteConfirm = async () => {
    setShowDeletePrompt(false);
    await deleteSubevent({
      variables: { eventId: window.eventId, subeventId: deleteSubeventId },
    });
    refetch();
  };

  return (
    <>
      <PageHeader>Teilevents verwalten</PageHeader>
      <Button
        bsStyle="primary"
        onClick={() => {
          setModalData(null);
          setShowModal(true);
        }}
      >{t('subevents.createSubeventDialog.title')}
      </Button>
      <Button
        bsStyle="default"
        href={`/subevents/downloadPDF/${window.eventId}`}
        style={{ marginLeft: 5 }}
      >
        {t('subevents.pdfDownload')}
      </Button>
      <Table striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('subevents.name')}</th>
            <th>{t('subevents.place')}</th>
            <th>{t('subevents.start')}</th>
            <th>{t('subevents.end')}</th>
            <th>{t('subevents.typeLabel')}</th>
            <th>{t('general.action')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.getSubevents?.subevents || []).map((subevent) => (
            <tr key={subevent.id}>
              <td>{subevent.id}</td>
              <td>{subevent.name}</td>
              <td>{subevent.place}</td>
              <td>{moment(subevent.start).format('lll')}</td>
              <td>{moment(subevent.end).format('lll')}</td>
              <td>{t(`subevents.type.${subevent.type}`)}</td>
              <td>
                <ButtonToolbar>
                  <Button
                    onClick={() => {
                      setModalData(subevent);
                      setShowModal(true);
                    }}
                    bsStyle="primary"
                  >{t('general.edit')}
                  </Button>
                  <Button
                    bsStyle="danger"
                    onClick={() => onDelete(subevent)}
                  >
                    {t('general.delete')}
                  </Button>
                  <Button
                    href={`/subevents/customer/${window.eventId}/${subevent.id}`}
                  >{t('subevents.actions.attendeeOverview')}
                  </Button>
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SubeventEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        modalData={modalData}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        show={!!showDeletePrompt}
        title={t('subevents.deleteSubeventDialog.title')}
        text={showDeletePrompt}
        confirmMessage={t('general.delete')}
        onConfirm={onDeleteConfirm}
        onClose={() => setShowDeletePrompt(false)}
        confirmBsStyle="danger"
      />
    </>
  );
}

export default SubeventsPage;
