import React from 'react';
import { Glyphicon } from 'react-bootstrap';

export default function StatusIcon({ status }) {
  if (status === 'accepted') {
    return (<Glyphicon glyph="ok" style={{ fontSize: '20px', marginLeft: 5 }} className="text-success" />);
  }
  if (status === 'declined') {
    return (<Glyphicon glyph="remove" style={{ fontSize: '20px', marginLeft: 5 }} className="text-danger" />);
  }
  return (<Glyphicon glyph="question-sign" style={{ fontSize: '20px', marginLeft: 5 }} className="text-info" />);
}
