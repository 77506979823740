import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import DashboardPanel from './DashboardPanel';
import { getRoomsGql } from '../../rooms/gql';

export default function RoomStatistics() {
  const hasFeature = window.eventFeatures && window.eventFeatures.includes('roomTracking');
  const { data: roomResult } = useQuery(getRoomsGql, {
    skip: !hasFeature,
    variables: { eventId: window.eventId },
  });
  if (!hasFeature) return null;
  return (
    <>
      <PageHeader>Räume</PageHeader>
      <Row>
        {roomResult?.getRooms.rooms.map((room) => (
          <Col md={4} key={room.id}>
            <DashboardPanel
              name={room.name}
              value={room.maxAttendees ? `${room.currentPresenceCount}/${room.maxAttendees}` : room.currentPresenceCount}
              icon="sign-in"
              color="green"
            />
          </Col>
        ))}
      </Row>
    </>
  );
}
