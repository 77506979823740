import React, { useState, useMemo } from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, PageHeader, Panel, Row, Table } from 'react-bootstrap';
import Switch from 'react-bootstrap-switch';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import styled from 'styled-components';
import { useLocalStorage } from 'react-use';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import 'moment/locale/de';
import { useQuery } from '@apollo/client';
import { t } from '../../locale';
import { getCurrentActiveRfidAttendees, getSubeventsGql } from './gql';
import FieldGroup from '../../components/FieldGroup';

momentDurationFormatSetup(moment);
registerLocale('de', de);
moment.locale('de');

function CurrentActiveRfidAttendees() {
  const [showOnlyActiveTags, setShowOnlyActiveTags] = useState(false);
  const [minTimeSpent, setMinTimeSpent] = useLocalStorage(`${window.eventId}-minTimeSpent`, 1);
  const [from, setFrom] = useLocalStorage(`${window.eventId}-from`, null);
  const [to, setTo] = useLocalStorage(`${window.eventId}-to`, null);
  const [subevent, setSubevent] = useLocalStorage(`${window.eventId}-subevent`, null);
  let filter = { $and: [] };
  if (minTimeSpent) filter.$and.push({ timeSpent: { $gte: minTimeSpent * 60 } });
  if (from) filter.$and.push({ firstSeenDate: { $gte: from } });
  if (to) filter.$and.push({ firstSeenDate: { $lte: to } });
  if (showOnlyActiveTags) filter = { lastSeenDate: { $has: false } };
  if (subevent) filter.$and.push({ subeventId: subevent });

  const { data } = useQuery(getCurrentActiveRfidAttendees, {
    variables: {
      eventId: window.eventId,
      filter: JSON.stringify(filter),
    },
    pollInterval: 2000,
  });

  const { data: subevents } = useQuery(getSubeventsGql, {
    variables: { eventId: window.eventId },
  });

  const subeventsById = useMemo(() => subevents?.getSubevents.subevents.reduce((acc, subevent) => {
    acc[subevent.id] = subevent;
    return acc;
  }, {}), [subevents?.getSubevents.subevents]);

  const renderFilter = () => (
    <Panel>
      <Panel.Heading>
        Filter
      </Panel.Heading>
      <Panel.Body>
        <StyledForm>
          <Row>
            <Col md={2}>
              <FormGroup controlId="formControlsSelect">
                <ControlLabel>Teilevent</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  onChange={(e) => setSubevent(e.target.value)}
                  value={subevent}
                >
                  <option value="">Alle</option>
                  {subevents?.getSubevents.subevents.map((subevent) => <option value={subevent.id}>{subevent.name}</option>)}
                </FormControl>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <ControlLabel>Zeige nur aktive Tags</ControlLabel>
                <SwitchWrapper>
                  <Switch
                    onChange={(el, state) => setShowOnlyActiveTags(state)}
                    value={showOnlyActiveTags}
                    bsSize="small"
                  />
                </SwitchWrapper>
              </FormGroup>
            </Col>
            {!showOnlyActiveTags && (
              <>
                <Col md={2}>
                  <FormGroup>
                    <ControlLabel>Von</ControlLabel>
                    <DatePicker
                      locale="de"
                      showTimeSelect
                      selected={from && new Date(from)}
                      onChange={(date) => setFrom(date.toISOString())}
                      dateFormat="Pp"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <ControlLabel>Bis</ControlLabel>
                    <DatePicker
                      showTimeSelect
                      locale="de"
                      selected={to && new Date(to)}
                      onChange={(date) => setTo(date.toISOString())}
                      dateFormat="Pp"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FieldGroup
                    id="gtTimeSpent"
                    type="number"
                    label="Min. x Minuten verbracht"
                    value={minTimeSpent}
                    onChange={(e) => setMinTimeSpent(e.target.value)}
                  />
                </Col>
              </>
            )}
          </Row>
        </StyledForm>
      </Panel.Body>
    </Panel>
  );

  return (
    <>
      <PageHeader>Gelesene RFID Tags</PageHeader>

      {renderFilter()}
      <Table striped hover>
        <thead>
          <tr>
            <th>{t('currentActiveRfidAttendees.email')}</th>
            <th>{t('currentActiveRfidAttendees.forename')}</th>
            <th>{t('currentActiveRfidAttendees.name')}</th>
            <th>{t('currentActiveRfidAttendees.subevent')}</th>
            <th>{t('currentActiveRfidAttendees.antennaId')}</th>
            <th>{t('currentActiveRfidAttendees.firstSeenDate')}</th>
            <th>{t('currentActiveRfidAttendees.lastSeenDate')}</th>
            <th>Zeit verbracht</th>
          </tr>
        </thead>
        <tbody>
          {(data?.currentActiveRfidAttendees.currentActiveRfidAttendees || []).map((activeRfid) => (
            <tr key={activeRfid.id}>
              <td>{activeRfid.email}</td>
              <td>{activeRfid.forename}</td>
              <td>{activeRfid.name}</td>
              <td>{subeventsById?.[activeRfid.subeventId]?.name}</td>
              <td>{activeRfid.antennaId}</td>
              <td>{moment(activeRfid.firstSeenDate).format('lll')}</td>
              <td>{(activeRfid.lastSeenDate) ? moment(activeRfid.lastSeenDate).format('lll') : ''}</td>
              <td>{(activeRfid.timeSpent) ? moment.duration(activeRfid.timeSpent, 'seconds').format('hh:mm:ss', { trim: false }) : ''}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

const SwitchWrapper = styled.div`
  display: block;
`;

const StyledForm = styled(Form)`
  label {
    margin-right: 10px;
  }
  .react-datepicker-wrapper input{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
`;

export default CurrentActiveRfidAttendees;
