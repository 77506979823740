/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import React, { useMemo } from 'react';
import { Button, Col, Form, PageHeader, Panel, Row } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { getAvailableLabelsGql, getEventLabelGql, updateEventLabelGql } from '../gql';
import FormField from '../../../components/FormField';
import LoadingSpinner from '../../../components/LoadingSpinner';
import toastHelper from '../../../lib/toastHelper';

function ImageFields({ input, options }) {
  return (
    <Row>
      {options.map((label) => (
        <Col key={label.id} xs={4}>
          <label style={{ cursor: 'pointer' }} onClick={() => input.onChange(label.id)}>
            <Field
              name={input.name}
              component="input"
              type="radio"
              value={label.id}
              style={{ display: 'none' }}
            />
            <Panel
              className={
            input.value === label.id ? 'panel-primary' : 'panel-default'
          }
            >
              <Panel.Heading>{label.title}</Panel.Heading>
              <Panel.Body className="text-center">
                <img src={label.image} alt={label.title} className="img-fluid" style={{ width: '100%' }} />
                <Button bsStyle={input.value === label.id ? 'default' : 'primary'} disabled={input.value === label.id}>
                  {input.value === label.id ? 'Ausgewählt' : 'Auswählen'}
                </Button>
              </Panel.Body>
            </Panel>
          </label>
        </Col>
      ))}
    </Row>
  );
}

function SettingsLabelPage() {
  const { t } = useTranslation('settings');
  const { t: tg } = useTranslation('general');

  const { data, loading } = useQuery(getAvailableLabelsGql);
  const { data: eventLabelData, refetch } = useQuery(getEventLabelGql, {
    variables: {
      eventId: window.eventId,
    },
  });

  const [updateEventLabel, { loading: updateEventLabelLoading }] = useMutation(updateEventLabelGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(tg('successMessages.saved')); },
  });

  const onSubmit = async (values) => {
    await updateEventLabel({
      variables: {
        eventId: window.eventId,
        input: {
          ...values,
          __typename: undefined,
        },
      },
    });
  };

  const initialValues = useMemo(() => {
    if (!eventLabelData?.getAvailableLabels) {
      return {
        numCopies: 1,
        printAfterCreation: false,
      };
    }
    return {
      ...eventLabelData?.getAvailableLabels,
      numCopies: 1,
    };
  }, [eventLabelData?.getAvailableLabels]);

  const getFields = () => [
    { name: 'numCopies', title: t('label.numCopies'), required: true, type: 'number' },
    { name: 'printAfterCreation', title: t('label.printAfterCreation'), type: 'boolean' },
  ].filter((val) => val);

  if (loading) return (<LoadingSpinner />);

  return (
    <PageWrapper>
      <PageHeader>{t('label.headline')}</PageHeader>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => (
          <Form horizontal onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton bsStyle="primary" type="submit" disabled={updateEventLabelLoading}>
                {updateEventLabelLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : tg('save')}
              </ActionButton>
            </div>
            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">{t('label.labelToPrint')}</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="labelId" component={ImageFields} options={data?.getAvailableLabels?.availableLabels || []} />
                  </Panel.Body>
                </Panel>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">{t('label.settings')}</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {getFields(values)
                      .map((field) => (
                        field.type !== 'subheadline'
                          ? (
                            <FormField
                              name={field.name}
                              title={field.title}
                              labelSize={3}
                              inputSize={9}
                              type={field.type}
                              options={field.options}
                              required={field.required}
                            />
                          )
                          : <div className="subheadline">{field.title}</div>
                      ))}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Form>
        )}
      />
    </PageWrapper>
  );
}

export default SettingsLabelPage;

const ActionButton = styled(Button)`
  margin-left: 5px;
`;

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
