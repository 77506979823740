import cogoToast from 'cogo-toast';

const defaultOptions = {
  position: 'top-right',
  hideAfter: 5,
};

const success = (text, options) => cogoToast.success(text, { ...defaultOptions, ...options });
const info = (text, options) => cogoToast.info(text, { ...defaultOptions, ...options });
const error = (text, options) => cogoToast.error(text, { ...defaultOptions, ...options });
const loading = (text, options) => cogoToast.loading(text, { ...defaultOptions, ...options });
const warn = (text, options) => cogoToast.warn(text, { ...defaultOptions, ...options });

export default { success, info, error, loading, warn };
