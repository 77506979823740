/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getStatisticsGql = gql`
  query statistics($eventId: ID!) {
    statistics (eventId: $eventId) @rest(type: "Statistics", path: "events/{args.eventId}/statistics") {
      presences {
        total
        perDay
        perSubevent
        perTicketCategory
      }
      totalParticipantCount
      additionalBarcodeCount
    }
  }
`;

export const getPaymentStatisticsGql = gql`
  query paymentStatistics($eventId: ID!, $filter: Filter!) {
    getPaymentStatistics (eventId: $eventId, filter: $filter) @rest(type: "[getPaymentStatistics]", path: "events/{args.eventId}/paymentStatistics") {
      paidCount
      notPaidCount
      beitragSum
      beitragSponsoringSum
      donationSum
      donationOperSum
    }
  }
`;

export const getInviteStatisticsGql = gql`
  query inviteStatistics($eventId: ID!, $filter: Filter!) {
    getInviteStatistics (eventId: $eventId) @rest(type: "[getInviteStatistics]", path: "events/{args.eventId}/inviteStatistics") {
      totalCount
      pendingCount
      acceptedCount
      declinedCount
    }
  }
`;

export const getDeviceStatisticsGql = gql`
  query deviceStatistics($eventId: ID!, $filter: Filter!) {
    getDeviceStatistics (eventId: $eventId) @rest(type: "[getDeviceStatistics]", path: "events/{args.eventId}/deviceStatistics") {
      outputDeviceCount
      notReturnedDeviceCount
      returnedDeviceCount
      replacedDeviceCount
    }
  }
`;

export const getQuotas = gql`
  query quotas($eventId: ID!, $filter: Filter!) {
    quotas (eventId: $eventId) @rest(type: "[Quota]", path: "events/{args.eventId}/quotas") {
      quotas
    }
  }
`;

export const getOrderStatsGql = gql`
  query getOrderStats($eventId: ID!, $filter: Filter!) {
    getOrderStats (eventId: $eventId) @rest(type: "[Quota]", path: "events/{args.eventId}/ecommerce/statistics") {
      orderStats
    }
  }
`;
