import { gql } from '@apollo/client';

export const getSubeventsGql = gql`
  query subevents($eventId: ID!) {
    getSubevents (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/subevents") {
      subevents {
        id
        name
        place
        start
        end
        type
        settings
        allowAllTicketCategories
        allowedTicketCategories
      }
    }
  }
`;

export const getSubeventPresencesGql = gql`
  query subeventPresences($eventId: Int!, $subeventId: Int!) {
    getSubeventPresences (eventId: $eventId, subeventId: $subeventId)
      @rest(type: "[SubeventPresences]", path: "events/{args.eventId}/subevents/{args.subeventId}/presences") {
      subeventPresences
    }
  }
`;

export const addSubeventGql = gql`
  mutation addSubevent($eventId: ID!, $input: SubeventInput!) {
    addSubevents (eventId: $eventId, input: $input) @rest(type: "Subevent", path: "events/{args.eventId}/subevents", method: "POST") {
      id
    }
  }
`;

export const updateSubeventGql = gql`
  mutation updateSubevent($eventId: ID!, $subeventId: Int!, $input: SubeventInput!) {
    updateSubevents (eventId: $eventId, subeventId: $subeventId, input: $input)
      @rest(type: "Subevent", path: "events/{args.eventId}/subevents/{args.subeventId}", method: "POST") {
      NoResponse
    }
  }
`;

export const deleteSubeventGql = gql`
  mutation deleteSubevent($eventId: ID!, $subeventId: Int!) {
    deleteSubevents (eventId: $eventId, subeventId: $subeventId) @rest(
      path: "events/{args.eventId}/subevents/{args.subeventId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
