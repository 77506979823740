/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const getSettingsAndTicketCategoriesGql = gql`
  query getSettingsAndTicketCategories($eventId: ID!, $settingKeys: String!) {
    getSettings (eventId: $eventId, settingKeys: $settingKeys) @rest(type: "[Settings]", path: "events/{args.eventId}/settings?keys={args.settingKeys}") {
      deviceOutputedTemplateByTicketCategory
      deviceReminderTemplateByTicketCategory
      deviceReturnedTemplateByTicketCategory
    }
    getTicketCategories (eventId: $eventId)
      @rest(type: "[TicketCategory]", path: "events/{args.eventId}/ticketCategories") {
      ticketCategories
    }
  }
`;

export const setSettingsGql = gql`
  mutation setSettings($eventId: ID!, $input: PublishablePostInput!) {
    setSettings (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/settings",
      method: "POST"
    ) { NoResponse }
  }
`;
