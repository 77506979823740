import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { getInviteStatisticsGql } from '../gql';
import DashboardPanel from './DashboardPanel';

export default function InviteStatistics() {
  const hasFeature = window.eventFeatures && window.eventFeatures.includes('invites');
  const { data } = useQuery(getInviteStatisticsGql, {
    skip: !hasFeature,
    variables: { eventId: window.eventId },
  });

  if (!hasFeature) return null;
  return (
    <>
      <PageHeader>Einladungen</PageHeader>
      <Row>
        <Col md={3}>
          <DashboardPanel
            name="Gesendete Einladungen"
            value={data?.getInviteStatistics?.totalCount}
            icon="send-o"
          />
        </Col>
        <Col md={3}>
          <DashboardPanel
            name="Ausstehende Einladungen"
            value={data?.getInviteStatistics?.pendingCount}
            icon="clock-o"
            color="yellow"
            link={`/customers/invites/${window.eventId}?filter=${encodeURIComponent('{"invite.status":"created"}')}`}
          />
        </Col>
        <Col md={3}>
          <DashboardPanel
            name="Akzeptierte Einladungen"
            value={data?.getInviteStatistics?.acceptedCount}
            icon="check"
            color="green"
            link={`/customers/invites/${window.eventId}?filter=${encodeURIComponent('{"invite.status":"accepted"}')}`}
          />
        </Col>
        <Col md={3}>
          <DashboardPanel
            name="Abgelehnte Einladungen"
            value={data?.getInviteStatistics?.declinedCount}
            icon="times"
            color="red"
            link={`/customers/invites/${window.eventId}?filter=${encodeURIComponent('{"invite.status":"declined"}')}`}
          />
        </Col>
      </Row>
    </>
  );
}
