import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import DashboardPanel from './DashboardPanel';

export default function CategoryStatistics({ statistics }) {
  if (!statistics?.presences?.perTicketCategory?.length) return null;
  return (
    <>
      <PageHeader>Teilnehmerzahl pro Kategorie</PageHeader>
      <Row>
        {statistics.presences.perTicketCategory.map((ticketCategory) => (
          <Col md={4} key={ticketCategory.id}>
            <DashboardPanel
              name={ticketCategory.name}
              value={`${ticketCategory.count}/${ticketCategory.overallCount}`}
              icon="ticket"
              color="green"
            />
          </Col>
        ))}
      </Row>
    </>
  );
}
