import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, ButtonGroup, Glyphicon, Label, PageHeader } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { addProductGql, deleteProductGql, getProductsGql, updateProductGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import AdvancedBootstapTable from '../../../components/AdvancedBootstapTable';
import TicketCategoryEditDialog from '../components/TicketCategoryEditDialog';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmModal from '../../../components/ConfirmModal';

const euroFormatter = new Intl.NumberFormat('de-de', { style: 'currency', currency: 'EUR' });

function OrderTicketCategories() {
  const { t } = useTranslation('orders');
  const { t: tg } = useTranslation('general');
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [confirmData, setConfirmData] = useState({});
  const { data, loading, refetch } = useQuery(getProductsGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      orderId: window.orderId,
    },
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
  });

  const [addProduct, { loading: loadingAdd }] = useMutation(addProductGql, {
    onError: () => {},
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const [updateProduct, { loading: loadingUpdate }] = useMutation(updateProductGql, {
    onError: () => {},
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const [deleteProduct, { loading: deleteProductLoading }] = useMutation(deleteProductGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(tg('successMessages.deleted')); },
  });

  const handleError = (errors) => {
    if (errors?.networkError?.statusCode === 409) {
      // eslint-disable-next-line consistent-return
      return { name: t('ticketCategories.errors.nameConflict') };
    }
    toastHelper.error(tg([`errors.error${errors.networkError.statusCode}`, 'errors.generalError']));
    return {};
  };

  const onSubmit = async (orgData) => {
    const data = { ...orgData };
    if (data.inventory?.stock) data.inventory.stock = parseInt(data.inventory?.stock);
    if (data.inventory?.safetyStock) data.inventory.safetyStock = parseInt(data.inventory?.safetyStock);
    delete data.inventory.sold;
    if (!modalData) {
      const { errors } = await addProduct({
        variables: {
          eventId: window.eventId,
          input: {
            ...data,
            type: 'ticketCategory',
          },
        },
      });
      // eslint-disable-next-line consistent-return
      if (errors) return handleError(errors);

      refetch();
      setShowEditModal(false);
      setModalData(null);
      return {};
    }

    const { errors } = await updateProduct({
      variables: {
        eventId: window.eventId,
        productId: modalData.id,
        input: {
          ...data,
          id: undefined,
        },
      },
    });

    // eslint-disable-next-line consistent-return
    if (errors) return handleError(errors);

    refetch();
    setShowEditModal(false);
    setModalData(null);
    return {};
  };

  const handleDelete = (product, cb) => {
    setConfirmData({
      show: true,
      title: t('ticketCategories.delete.title'),
      text: t('ticketCategories.delete.text', { product }),
      confirmMessage: t('ticketCategories.delete.delete'),
      confirmBsStyle: 'danger',
      loading: deleteProductLoading,
      onClose: () => { setConfirmData({ show: false }); },
      onConfirm: async () => {
        await deleteProduct({ variables: { eventId: window.eventId, productId: product.id } });
        setConfirmData({ show: false });
        if (cb) cb();
      },
    });
  };

  const columns = [
    { text: t('ticketCategories.table.name'), dataField: 'name' },
    {
      text: t('table.status'),
      dataField: 'status',
      formatter: (status, row) => {
        if (status === 'inactive') return (<Label bsStyle="danger">{t('ticketCategories.status.inactive')}</Label>);
        if ((row.inventory.stock - row.inventory.safetyStock - row.inventory.sold) < 1) {
          return (<Label bsStyle="warning">{t('ticketCategories.status.soldOut')}</Label>);
        }
        return (<Label bsStyle="success">{t('ticketCategories.status.active')}</Label>);
      },
    },
    {
      text: t('ticketCategories.table.salePrice'),
      dataField: 'price',
      formatter: (cell, row) => (cell !== undefined && cell !== null ? euroFormatter.format(row.salesPrice || row.price) : ''),
    },
    { text: t('ticketCategories.table.stock'), dataField: 'inventory.stock' },
    { text: t('ticketCategories.table.safetyStock'), dataField: 'inventory.safetyStock' },
    { text: t('ticketCategories.table.saleCount'), dataField: 'inventory.sold' },
    {
      text: t('ticketCategories.table.available'),
      dataField: '',
      style: { width: '80px' },
      headerStyle: { width: '80px' },
      formatter: (cell, row) => Math.max(row.inventory.stock - row.inventory.safetyStock - row.inventory.sold, 0),
    },
    {
      text: 'Aktion',
      isDummyField: true,
      style: { width: '60px' },
      headerStyle: { width: '60px' },
      formatter: (cellContent, row) => (
        <ButtonGroup>
          <Button
            bsStyle="primary"
            title={tg('edit')}
            onClick={() => {
              setModalData(row);
              setShowEditModal(true);
            }}
          >
            <Glyphicon glyph="pencil" />
          </Button>
          <Button
            bsStyle="danger"
            title={row.inventory.sold > 0 ? t('ticketCategories.delete.restriction') : tg('delete')}
            onClick={() => handleDelete(row)}
            disabled={row.inventory.sold > 0}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <PageHeader>Ticketkategorien</PageHeader>
      <Button
        bsStyle="primary"
        onClick={() => {
          setModalData(null);
          setShowEditModal(true);
        }}
      >{t('ticketCategories.add')}
      </Button>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <AdvancedBootstapTable
          remote
          keyField="id"
          columns={columns}
          data={data?.getProducts?.products || []}
          striped
          bordered={false}
        />
      )}
      <TicketCategoryEditDialog
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        modalData={modalData}
        onSubmit={onSubmit}
        ticketCategories={data?.getProducts?.products || []}
        loading={loadingUpdate || loadingAdd}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}

export default OrderTicketCategories;
