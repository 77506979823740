import { onError } from 'apollo-link-error';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

// Set `RestLink` with your endpoint
const restLink = new RestLink({
  uri: '/api/v1/',
  customFetch: async (requestUrl, requestParams) => {
    const response = await fetch(requestUrl, requestParams);
    if (response.status === 404) {
      const err = new Error('Not Found');
      err.statusCode = 404;
      err.result = await response.json();
      throw err;
    }
    return response;
  },
  bodySerializers: {
    fileEncode: (data, headers) => {
      const formData = new FormData();
      formData.append('file', data, data.name);
      headers.set('Accept', '*/*');
      return { body: formData, headers };
    },
  },
});

const onErrorLink = onError(({ networkError }) => {
  if (networkError?.response?.status === 401) window.location.href = '/users/logout';
});

// Setup your client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([onErrorLink, restLink]),
});

export default client;
