import React from 'react';
import { ButtonGroup, Glyphicon, PageHeader, Button, ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import toastHelper from '../../../lib/toastHelper';
import { getInviteFormsGql } from '../gql';

export default function InviteFormsPage() {
  const { data: { getInviteForms: { inviteForms } = { inviteForms: [] } } = {} } = useQuery(getInviteFormsGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.getInviteError404'));
      return toastHelper.error(t('invites.errors.getInviteError'));
    },
  });

  const columns = [
    { text: t('inviteForms.name'), dataField: 'name' },
    {
      text: 'Status',
      dataField: 'Status',
      formatter: (_, row) => t(`general.status.${row.status}`),
    },
    {
      text: 'Aktion',
      dataField: 'id',
      isDummyField: true,
      align: 'right',
      style: { width: '140px' },
      formatter: (_, row) => (
        <ButtonGroup>
          <Button
            bsStyle="primary"
            title={t('general.edit')}
            onClick={() => { window.location.href = `${window.location.href}/${row.id}`; }}
          >
            <Glyphicon glyph="pencil" />
          </Button>
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            /* onClick={() => handleDeleteInvite(row)} */
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <PageHeader>{t('inviteForms.headline')}</PageHeader>

      <ButtonToolbar>
        <Button
          bsStyle="primary"
        >
          {t('inviteForms.createForm')}
        </Button>

      </ButtonToolbar>

      <BootstrapTable
        remote
        keyField="id"
        columns={columns}
        data={inviteForms}
        striped
        bordered={false}
      />
    </>
  );
}
