import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup, Form, Glyphicon, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { useLocalStorage } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../locale';
import {
  addDeviceGql,
  addPresenceGql,
  deleteCustomerDeviceGql,
  deletePresenceGql,
  getAttributesGql,
  getSingleCustomer,
  getTicketCategoriesGql,
  returnCustomerDeviceGql,
} from '../gql';
import FormField, { titleOptions } from '../../../components/FormField';
import ConfirmModal from '../../../components/ConfirmModal';
import toastHelper from '../../../lib/toastHelper';
import { getSubeventsGql } from '../../subevents/gql';

function CustomerEditDialog({ show, onHide, modalData, onSubmit, onSubentityChange }) {
  const [confirmData, setConfirmData] = useState({});
  const [localPrinter] = useLocalStorage(`${window.eventId}_localPrinter`);
  const [localPrinterSubevent] = useLocalStorage(`${window.eventId}_localPrinterSubevent`);
  const isNew = !modalData;

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });
  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });
  const { data: subeventResult } = useQuery(getSubeventsGql, {
    variables: { eventId: window.eventId },
  });

  const { data: customerResult, refetch } = useQuery(getSingleCustomer, {
    fetchPolicy: 'no-cache',
    skip: isNew,
    variables: {
      eventId: window.eventId,
      id: modalData?.id,
    },
  });

  const [returnCustomerDevice, { loading: returnCustomerDeviceLoading }] = useMutation(returnCustomerDeviceGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const [deleteCustomerDevice, { loading: deleteCustomerDeviceLoading }] = useMutation(deleteCustomerDeviceGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const [deletePresence, { loading: deletePresenceLoading }] = useMutation(deletePresenceGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const [addDevice, { loading: addDeviceLoading }] = useMutation(addDeviceGql, {
    onError: (err) => {
      if (['EPRINTERNOTAVAILABLE', 'EHOSTNOTFOUND'].includes(err.networkError.result?.code)) {
        toastHelper.error(t('customers.devices.addDevice.errors.EHOSTNOTFOUND'));
        refetch();
        return;
      }
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('customers.devices.addDevice.success')); },
  });

  const [addPresence, { loading: addPresenceLoading }] = useMutation(addPresenceGql, {
    onError: (err) => {
      if (err.networkError.result?.code === 'EHOSTNOTFOUND') {
        toastHelper.error(t('customers.devices.addDevice.errors.EHOSTNOTFOUND'));
        refetch();
        return;
      }
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('customers.checkinDialog.success')); },
  });

  const customer = useMemo(() => {
    let defaultCustomAttributes = {};
    if (attributeResult?.getAttributes?.attributes) {
      defaultCustomAttributes = attributeResult?.getAttributes?.attributes
        ?.filter(({ visibility }) => visibility !== 'system')
        .reduce((acc, val) => {
          acc[val.code] = val?.settings?.defaultValue;
          if (val?.settings?.defaultValue !== undefined) return acc;
          if (val.type === 'titleSelect') acc[val.code] = titleOptions[0].value;
          if (val.type === 'boolean') acc[val.code] = val?.settings?.defaultValue ? 'true' : 'false';
          return acc;
        }, {});
    }

    if (customerResult?.getCustomer) {
      const customer = customerResult?.getCustomer;
      customer.customAttributes ||= {};
      customer.customAttributes = { ...defaultCustomAttributes, ...customer.customAttributes };
      return customer;
    }

    return {
      ticketCategory: { id: categoriesResult?.getTicketCategories?.ticketCategories[0]?.id },
      customAttributes: defaultCustomAttributes,
    };
  }, [customerResult?.getCustomer, categoriesResult?.getTicketCategories, attributeResult?.getAttributes?.attributes]);

  const checkins = useMemo(() => {
    if (!customerResult?.getCustomer || !subeventResult?.getSubevents?.subevents) return [];
    return Object.entries(customerResult?.getCustomer.presences || {}).map(([subeventId, presence]) => {
      const subevent = subeventResult.getSubevents.subevents.find(({ id }) => id === parseInt(subeventId));
      return {
        ...presence,
        subevent: subevent?.name,
        subeventId,
      };
    });
  }, [customerResult?.getCustomer, subeventResult?.getSubevents?.subevents]);

  const onAddPresence = async (print = false) => {
    await addPresence({
      variables: {
        eventId: window.eventId,
        customerId: customer.id,
        input: {
          subeventId: localPrinterSubevent.id.toString(),
          host: 'WEB',
          date: new Date(),
          print,
          printOnHost: print ? localPrinter : undefined,
        },
      },
    });
    refetch();
    onSubentityChange && onSubentityChange();
  };

  const onAddDevice = () => {
    setConfirmData({
      show: true,
      title: t('customers.devices.addDevice.title'),
      text: t('customers.devices.addDevice.text', { customer }),
      loading: deleteCustomerDeviceLoading,
      // confirmBsStyle: 'danger',
      confirmMessage: t('customers.devices.addDevice.output'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await addDevice({
          variables: {
            eventId: window.eventId,
            customerId: customer.id,
            input: {
              print: true,
              printOnHost: localPrinter,
            },
          },
        });
        refetch();
        setConfirmData({});
        onSubentityChange && onSubentityChange();
      },
    });
  };

  const handleDeviceDelete = (row) => {
    setConfirmData({
      show: true,
      title: t('customers.devices.deleteDevice.title'),
      text: t('customers.devices.deleteDevice.text', { customer }),
      loading: deleteCustomerDeviceLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('customers.devices.deleteDevice.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomerDevice({ variables: { eventId: window.eventId, customerId: customer.id, deviceBarcode: row.barcode } });
        setConfirmData({});
        onSubentityChange && onSubentityChange();
      },
    });
  };

  const handlePresenceDelete = (row) => {
    setConfirmData({
      show: true,
      title: t('customers.checkins.deletePresence.title'),
      text: t('customers.checkins.deletePresence.text', { customer }),
      loading: deletePresenceLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('customers.checkins.deletePresence.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deletePresence({ variables: { eventId: window.eventId, customerId: customer.id, subeventId: row.subeventId, input: {} } });
        setConfirmData({});
        onSubentityChange && onSubentityChange();
      },
    });
  };

  const handleDeviceReturn = (row) => {
    setConfirmData({
      show: true,
      title: t('customers.devices.returnDevice.title'),
      text: t('customers.devices.returnDevice.text', { customer }),
      loading: returnCustomerDeviceLoading,
      confirmBsStyle: 'info',
      confirmMessage: t('customers.devices.returnDevice.return'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await returnCustomerDevice({ variables: { eventId: window.eventId, customerId: customer.id, deviceBarcode: row.barcode, input: {} } });
        setConfirmData({});
        onSubentityChange && onSubentityChange();
      },
    });
  };

  const deviceColumns = [
    {
      text: t('customers.devices.table.id'),
      dataField: 'id',
      formatter: (_, __, index) => index + 1,
      headerStyle: () => ({ width: '100px' }),
    },
    {
      text: t('customers.devices.table.pickedUp'),
      dataField: 'outputDate',
      formatter: (cellContent) => moment(cellContent).format('L LT'),
    },
    {
      text: t('customers.devices.table.returned'),
      dataField: 'returnDate',
      formatter: (cellContent) => (cellContent ? moment(cellContent).format('L LT') : null),
    },
    {
      text: 'Aktion',
      isDummyField: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cellContent, row) => (
        <ButtonGroup>
          {!row.returnDate && (
            <Button
              bsStyle="info"
              title={t('customers.devices.returnDevice.title')}
              onClick={() => handleDeviceReturn(row)}
            >
              <FontAwesomeIcon icon={faArrowRotateLeft} />
            </Button>
          )}
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            onClick={() => handleDeviceDelete(row)}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const renderButton = (values, handleSubmit, form) => {
    const defaultButtons = [
      (
        <Button bsStyle="primary" onClick={handleSubmit}>
          {(modalData) ? t('general.save') : t('general.create')}
        </Button>
      ),
      (
        <Button onClick={onHide}>{t('general.close')}</Button>
      ),
    ];

    if (modalData) {
      return [
        (
          <Button href={`/customers/audit/${window.eventId}/${modalData?.id}`} style={{ position: 'absolute', left: '12px' }}>
            {t('customers.changes')}
          </Button>
        ),
        ...defaultButtons,
      ];
    }

    if (!localPrinter) {
      return [
        (
          <Button bsStyle="primary" onClick={(e) => { form.change('printAfterCreation', true); handleSubmit(e); }}>
            {t('customers.createAndCheckin')}
          </Button>
        ),
        ...defaultButtons,
      ];
    }

    const buttons = [];

    const ticketCategory = categoriesResult?.getTicketCategories?.ticketCategories?.find(({ id }) => id === parseInt(values.ticketCategory.id));

    // device buttons
    if (ticketCategory?.settings?.type === 'device') {
      buttons.push(
        (
          <Button bsStyle="primary" onClick={(e) => { form.change('outputDeviceAfterCreation', true); handleSubmit(e); }}>
            {t('customers.createAndOutputDevice')}
          </Button>
        ),
      );
      return [...buttons, ...defaultButtons];
    }

    if (!localPrinterSubevent) return defaultButtons;
    if (window.eventFeatures.includes('printAfterCreation')) {
      buttons.push(
        (
          <Button bsStyle="primary" onClick={(e) => { form.change('printAfterCreation', true); handleSubmit(e); }}>
            {t('customers.createAndPrint')}
          </Button>
        ),
      );
    } else {
      buttons.push(
        (
          <Button bsStyle="primary" onClick={(e) => { form.change('checkinAfterCreation', true); handleSubmit(e); }}>
            {t('customers.createAndCheckin')}
          </Button>
        ),
      );
    }

    return [...buttons, ...defaultButtons];
  };

  const checkinColumns = [
    {
      text: t('customers.checkins.table.subevent'),
      dataField: 'subevent',
    },
    {
      text: t('customers.checkins.table.checkedInAt'),
      dataField: 'date',
      formatter: (cellContent) => moment(cellContent).format('L LT'),
    },
    {
      text: t('customers.checkins.table.host'),
      dataField: 'host',
    },
    {
      text: 'Aktion',
      isDummyField: true,
      headerStyle: () => ({ width: '100px' }),
      formatter: (cellContent, row) => (
        <ButtonGroup>
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            onClick={() => handlePresenceDelete(row)}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const allowToOutputDevice = !customer?.devices?.length || !customer?.devices?.find(({ returnDate }) => !returnDate);
  const allowToCheckin = modalData && !customer?.presences?.[localPrinterSubevent?.id];

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={customer}
        onSubmit={async (values) => {
          await onSubmit(values);
          refetch();
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form, values }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {modalData ? t('customers.editCustomerDialog.title.edit') : t('customers.editCustomerDialog.title.create')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="subheadline">{t('invites.editInviteDialog.general')}</div>

              <FormField name="forename" title={t('customers.table.forename')} required labelSize={2} inputSize={10} />
              <FormField name="name" title={t('customers.table.name')} required labelSize={2} inputSize={10} />
              <FormField name="company" title={t('customers.table.company')} labelSize={2} inputSize={10} />
              <FormField name="email" type="email" title={t('customers.table.email')} required labelSize={2} inputSize={10} />
              <FormField name="invoiceNumber" title={t('customers.table.invoiceNumber')} labelSize={2} inputSize={10} />
              <FormField
                name="ticketCategory.id"
                type="select"
                title={t('invites.inviteCategory')}
                required
                labelSize={2}
                inputSize={10}
                options={categoriesResult?.getTicketCategories?.ticketCategories.map((cat) => ({ value: cat.id, label: cat.name })) || []}
              />

              {attributeResult?.getAttributes?.attributes?.length ? (
                <>
                  <div className="subheadline">{t('invites.editInviteDialog.additionalFields')}</div>
                  {attributeResult?.getAttributes?.attributes.filter(({ visibility }) => visibility !== 'system').map((attribute) => (
                    <FormField
                      name={`customAttributes.${attribute.code}`}
                      title={attribute.name}
                      type={attribute.type}
                      options={attribute.options}
                      labelSize={2}
                      inputSize={10}
                    />
                  ))}
                </>
              ) : null}
              {modalData && categoriesResult?.getTicketCategories
                ?.ticketCategories?.find(({ id }) => id === parseInt(values.ticketCategory.id))?.settings?.type === 'device' ? (
                  <>
                    <div className="subheadline">
                      {t('customers.devices.title')}
                      <Glyphicon
                        glyph="refresh"
                        style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '5px' }}
                        onClick={() => refetch()}
                      />
                    </div>
                    <BootstrapTable
                      keyField="barcode"
                      data={customer.devices || []}
                      striped
                      columns={deviceColumns}
                    />
                    {allowToOutputDevice && localPrinter && (
                    <Button
                      bsStyle="primary"
                      disabled={addDeviceLoading}
                      onClick={onAddDevice}
                    >
                      {t('customers.devices.outputDevice')}
                    </Button>
                    )}
                  </>
                ) : null}
              {modalData
                && categoriesResult?.getTicketCategories
                  ?.ticketCategories?.find(({ id }) => id === parseInt(values.ticketCategory.id))?.settings?.type !== 'device' ? (
                    <>
                      <div className="subheadline" style={{ marginTop: 15 }}>
                        {t('customers.checkins.title')}
                        <Glyphicon
                          glyph="refresh"
                          style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '5px' }}
                          onClick={() => refetch()}
                        />
                      </div>
                      <BootstrapTable
                        keyField="barcode"
                        data={checkins}
                        striped
                        columns={checkinColumns}
                      />
                      {allowToCheckin && window.eventFeatures.includes('printAfterCreation') && localPrinterSubevent && localPrinter && (
                      <Button
                        bsStyle="primary"
                        disabled={addPresenceLoading}
                        onClick={() => onAddPresence(true)}
                      >
                        {t('customers.checkins.checkinAndPrint')}
                      </Button>
                      )}
                      {allowToCheckin && !window.eventFeatures.includes('printAfterCreation') && localPrinterSubevent && (
                      <Button
                        bsStyle="primary"
                        disabled={addPresenceLoading}
                        onClick={() => onAddPresence(false)}
                      >
                        {t('customers.checkins.checkin')}
                      </Button>
                      )}
                    </>
                ) : null}
            </Modal.Body>

            <Modal.Footer>
              {renderButton(values, handleSubmit, form)}
            </Modal.Footer>

          </Form>
        )}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </Modal>
  );
}

export default CustomerEditDialog;
