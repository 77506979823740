/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { Button, ButtonToolbar, PageHeader, Table } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../components/ConfirmModal';
import { createTicketCategoryGql, deleteTicketCategoryGql, getTicketCategoriesGql, updateTicketCategoryGql } from './gql';
import TicketCategoryEditDialog from './components/TicketCategoryEditDialog';
import toastHelper from '../../lib/toastHelper';

function TicketCategoriesPage() {
  const { t } = useTranslation('ticketCategories');
  const { t: tg } = useTranslation('general');
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(null);
  const [deleteTicketCategoryId, setDeleteTicketCategoryId] = useState();

  const { data, refetch } = useQuery(getTicketCategoriesGql, {
    variables: { eventId: window.eventId },
  });
  const [createTicketCategory] = useMutation(createTicketCategoryGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });
  const [updateTicketCategory] = useMutation(updateTicketCategoryGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });
  const [deleteTicketCategory] = useMutation(deleteTicketCategoryGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('delete.success')),
  });

  const handleError = (errors) => {
    if (errors?.networkError?.statusCode === 409) {
      // eslint-disable-next-line consistent-return
      return { name: t('errors.nameConflict') };
    }
    toastHelper.error(tg([`errors.error${errors.networkError.statusCode}`, 'errors.generalError']));
    return {};
  };

  const onSubmit = async (incomingValues) => {
    const values = { ...incomingValues };
    const ticketCategoryId = values.id;
    delete values.id;
    delete values.createInviteWithCategoryId;

    if (ticketCategoryId) {
      delete values.id;
      const { errors } = await updateTicketCategory({
        variables: {
          eventId: window.eventId,
          ticketCategoryId,
          input: values,
        },
      });
      if (errors) return handleError(errors);

      setShowModal(false);
      setModalData(null);
      refetch();
      return;
    }

    const { errors } = await createTicketCategory({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });

    // eslint-disable-next-line consistent-return
    if (errors) return handleError(errors);

    setShowModal(false);
    setModalData(null);
    refetch();
  };

  const onDelete = async (subevent) => {
    setDeleteTicketCategoryId(subevent.id);
    setShowDeletePrompt(t('delete.text', subevent));
  };

  const onDeleteConfirm = async () => {
    setShowDeletePrompt(false);
    await deleteTicketCategory({
      variables: { eventId: window.eventId, ticketCategoryId: deleteTicketCategoryId },
    });
    refetch();
  };

  return (
    <>
      <PageHeader>Ticketkategorien verwalten</PageHeader>
      <Button
        bsStyle="primary"
        onClick={() => {
          setModalData(null);
          setShowModal(true);
        }}
      >{t('createDialog.title')}
      </Button>
      <Table striped hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('table.name')}</th>
            <th>{t('table.type')}</th>
            <th>{t('table.highlightOnScan')}</th>
            <th>{t('table.printOnLabel')}</th>
            <th>{tg('action')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.getTicketCategories?.ticketCategories || []).map((ticketCategory) => (
            <tr key={ticketCategory.id}>
              <td>{ticketCategory.id}</td>
              <td>{ticketCategory.name}</td>
              <td>{t(`ticketTypes.${ticketCategory.settings?.type || 'entry'}`)}</td>
              <td>{tg(`form.boolean.${ticketCategory.highlightOnScan ? 'true' : 'false'}`)}</td>
              <td>{tg(`form.boolean.${ticketCategory.printOnLabel ? 'true' : 'false'}`)}</td>
              <td>
                <ButtonToolbar>
                  <Button
                    onClick={() => {
                      setModalData(ticketCategory);
                      setShowModal(true);
                    }}
                    bsStyle="primary"
                  >{tg('edit')}
                  </Button>
                  <Button
                    bsStyle="danger"
                    onClick={() => onDelete(ticketCategory)}
                  >
                    {tg('delete')}
                  </Button>
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <TicketCategoryEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        modalData={modalData}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        show={!!showDeletePrompt}
        title={t('delete.title')}
        text={showDeletePrompt}
        confirmMessage={tg('delete')}
        onConfirm={onDeleteConfirm}
        onClose={() => setShowDeletePrompt(false)}
        confirmBsStyle="danger"
      />
    </>
  );
}

export default TicketCategoriesPage;
