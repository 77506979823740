import { gql } from '@apollo/client';

export const getQuotas = gql`
  query quotas($eventId: ID!) {
    getQuotas (eventId: $eventId)
      @rest(type: "[Table]", path: "events/{args.eventId}/quotas") {
        quotas {
          id
          name
          maxCustomerCount
          currentCustomerCount
          isLocked
        }
    }
  }
`;

export const getSingleQuota = gql`
  query quotas($eventId: ID!, $quotaId: ID!) {
    getSingleQuota (eventId: $eventId, quotaId: $quotaId)
      @rest(type: "[Table]", path: "events/{args.eventId}/quotas/{args.quotaId}") {
        id
        name
        maxCustomerCount
        currentCustomerCount
        isLocked
    }
  }
`;

export const updateQuotaGql = gql`
  mutation updateQuota($eventId: ID!, $quotaId: ID!  $input: PublishablePostInput!) {
    updateQuota (eventId: $eventId, quotaId: $quotaId, input: $input) @rest(
      path: "events/{args.eventId}/quotas/{args.quotaId}",
      method: "POST"
    ) { NoResponse }
  }
`;
