import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Form as FinalForm } from 'react-final-form';
import { t } from '../../../locale';
import FormField from '../../../components/FormField';

function LinkModal({ show, onHide, onSubmit, loading, subeventsOptions, selectedSubeventId }) {
  // eslint-disable-next-line arrow-body-style
  const initialValues = useMemo(() => {
    if (selectedSubeventId) {
      const subevent = subeventsOptions.find(({ value }) => parseInt(selectedSubeventId) === parseInt(value));
      if (!subevent) return { subeventId: subeventsOptions[0]?.value?.toString() };
    }
    return {
      subeventId: selectedSubeventId || subeventsOptions[0]?.value?.toString(),
    };
  }, [selectedSubeventId, subeventsOptions]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {t('printerHosts.linkModal.title')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                {t('printerHosts.linkModal.text')}
              </p>
              <FormField
                name="subeventId"
                title={t('printerHosts.subevent')}
                type="select"
                required
                labelSize={2}
                inputSize={10}
                options={subeventsOptions}
              />

            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit} disabled={loading}>
                {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : t('general.save')}
              </Button>
              <Button variant="secondary" onClick={onHide} disabled={loading}>
                {t('general.close')}
              </Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default LinkModal;
