import React, { useState } from 'react';
import { Button, ButtonToolbar, PageHeader } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';

import { t } from '../../locale';
import AdvancedBootstapTable from '../../components/AdvancedBootstapTable';
import { getNotReturnedDevicesGql } from './gql';
import ReminderEmailModal from './components/ReminderEmailModal';

function DevicesPage() {
  const [showModal, setShowModal] = useState(false);
  const { data } = useQuery(getNotReturnedDevicesGql, {
    variables: { eventId: window.eventId },
  });

  const columns = [
    {
      text: t('customers.table.forename'),
      dataField: 'customer.forename',
    },
    {
      text: t('customers.table.name'),
      dataField: 'customer.name',
    },
    {
      text: t('customers.table.email'),
      dataField: 'customer.email',
    },
    {
      text: t('devices.table.returned'),
      dataField: 'device.outputDate',
      formatter: (cellContent) => moment(cellContent).format('L LT'),
    },
  ];

  return (
    <>
      <PageHeader>{t('devices.headline')}</PageHeader>

      <ButtonToolbar style={{ marginBottom: 10 }}>
        <Button onClick={() => setShowModal(true)}>
          Erinnerungsmails versenden
        </Button>
      </ButtonToolbar>
      <StyledContainer>
        <AdvancedBootstapTable
          remote
          keyField="id"
          columns={columns}
          data={data?.getNotReturnedDevices?.devices || []}
          striped
          bordered={false}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
      </StyledContainer>
      <ReminderEmailModal
        show={showModal}
        onClose={() => { setShowModal(false); }}
      />
    </>
  );
}

const StyledContainer = styled.div`
  .reset-expansion-style { background-color: #f9f9f9; }
`;

export default DevicesPage;
