/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getHostsStatus = gql`
  query hostStatus($eventId: ID!) {
    hostStatus (eventId: $eventId, filter: $filter)
      @rest(type: "[hostStatus]", path: "events/{args.eventId}/hostStatus") {
        updateSeq
        hostStatus {
          host
          lastPingDate
          subeventName
          currentUpdateSeq
          status
        }
    }
  }
`;
