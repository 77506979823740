import React, { useMemo } from 'react';
import { PageHeader } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '../../../locale';
import GeneralCustomerGrid from '../components/GeneralCustomerGrid';
import { getAttributesGql } from '../gql';

function SeatWishPage() {
  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const additionalColumns = useMemo(() => {
    if (!attributeResult?.getAttributes?.attributes) return [];
    const seatWishAttendee = attributeResult?.getAttributes?.attributes.find(({ code }) => code === 'seatWishAttendee');
    if (!seatWishAttendee) return [];

    return [
      {
        text: seatWishAttendee.name,
        dataField: 'customAttributes.seatWishAttendee',
      },
    ];
  }, [attributeResult?.getAttributes?.attributes]);
  return (
    <>
      <PageHeader>{t('seatWish.headline')}</PageHeader>
      <GeneralCustomerGrid
        storageKey="seatWish"
        defaultFilter={{
          'customAttributes.seatWish': { $has: false },
          'customAttributes.seatWishAttendee': { $has: true },
        }}
        additionalColumns={additionalColumns}
        noDataText={(
          <p style={{ fontSize: 20 }}>
            <FontAwesomeIcon style={{ cursor: 'pointer', color: 'green' }} icon={faCheck} size="3x" /><br />
            Super! Keine weiteren ausstehende Sitzplatzwünsche vorhanden
          </p>
        )}
      />
    </>
  );
}

export default SeatWishPage;
