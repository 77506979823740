import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { t } from '../locale';

export default function ConfirmModal(props) {
  const {
    show,
    title = '',
    text,
    onClose,
    onConfirm,
    loading = false,
    closeMessage = t('general.close'),
    confirmMessage = t('general.save'),
    confirmBsStyle = 'primary',
    hideConfirmButton = false,
  } = props;
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: text }} />
      <Modal.Footer>
        {!hideConfirmButton && (
          <Button bsStyle={confirmBsStyle} onClick={onConfirm} disabled={loading}>
            {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : confirmMessage}
          </Button>
        )}
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          {closeMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
