import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GaugeComponent from 'react-gauge-component';
import { getStatisticsGql } from '../gql';
import LoadingSpinner from '../../../components/LoadingSpinner';

function AdvancedDashboardPage() {
  const { data, loading } = useQuery(getStatisticsGql, {
    variables: { eventId: window.eventId },
    pollInterval: 6_000,
  });

  if (loading) return (<LoadingSpinner />);

  return (
    <Row style={{ height: '100%', alignItems: 'center', marginTop: 50 }}>
      {data.statistics.presences.perTicketCategory.map((presence) => (
        <Col xs={6}>
          <div
            style={{
              fontSize: '45px',
              textAlign: 'center',
              height: '150px',
            }}
          >
            {presence.name}
          </div>
          <GaugeComponent
            type="semicircle"
            marginInPercent={0.04}
            arc={{
              gradient: true,
              width: 0.15,
              padding: 0,
              colorArray: ['#FF2121', '#00FF15'],
            }}
            value={(presence.count / presence.overallCount) * 100}
            pointer={{
              color: '#345243',
              length: 1,
              width: 9,
              type: 'arrow',
            }}
            labels={{
              valueLabel: {
                formatTextValue: () => `${presence.count}/${presence.overallCount}`,
                style: {
                  fontSize: '35px',
                  fill: '#000',
                  textShadow: '',
                },
              },
              tickLabels: {
                type: 'inner',
                hideMinMax: true,
              },
            }}
          />
        </Col>
      ))}
    </Row>
  );
}

export default AdvancedDashboardPage;
