import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocalStorage } from 'react-use';
import { addAttendeeGql, addPresenceGql } from '../gql';
import toastHelper from '../lib/toastHelper';

export default function CreateAndCheckinButton() {
  const [, setRerenderId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const customerId = document.querySelector('input[name=idcustomer]').value;
  const [localPrinter] = useLocalStorage(`${window.eventId}_localPrinter`);
  const [localPrinterSubevent] = useLocalStorage(`${window.eventId}_localPrinterSubevent`);

  const [addAttendee] = useMutation(addAttendeeGql);
  const [addPresence] = useMutation(addPresenceGql);

  useEffect(() => {
    const cb = () => {
      setRerenderId(Math.random());
    };

    window.$('#customerDialog').on('show.bs.modal', cb);
    return () => {
      window.$('#customerDialog').off('show.bs.modal', cb);
    };
  });
  if (customerId || !localPrinterSubevent) return null;

  const onClick = async () => {
    const form = document.getElementById('CustomerEditForm');
    const isValid = form.reportValidity();
    if (!isValid) return;
    const formData = new FormData(form);

    try {
      setIsLoading(true);
      const result = await addAttendee({
        variables: {
          eventId: window.eventId,
          input: {
            participant: {
              forename: formData.get('data[Customer][forename]'),
              name: formData.get('data[Customer][name]'),
              email: formData.get('data[Customer][email]'),
              invoiceNumber: formData.get('data[Customer][invoice_number]'),
              customAttributes: formData.get('data[Customer][custom_attributes]'),
              ticketCategory: { id: formData.get('data[Customer][ticket_category_id]') },
              additionalBarcodes: [],
            },
          },
        },
      });

      await addPresence({
        variables: {
          eventId: window.eventId,
          barcode: result.data.addAttendee.barcode,
          input: {
            subeventId: localPrinterSubevent.id.toString(),
            host: 'WEB',
            date: new Date(),
          },
        },
      });

      if (localPrinter) {
        await fetch(`http://${localPrinter}:41230/print?subeventBarcode=${localPrinterSubevent.barcode}`, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: `${formData.get('data[Customer][forename]')} ${formData.get('data[Customer][name]')}`,
            barcode: result.data.addAttendee.barcode,
          }),
        })
          .catch((err) => {
            console.error(err);
            // eslint-disable-next-line no-alert
            alert(`Station ${localPrinter} konnte nicht erreicht werden`);
          });
      }
      window.location.reload();
    } catch (err) {
      console.error(err);
      toastHelper.error('Fehler beim Erstellen des Teilnehmers');
    }
    window.$('#customerDialog').modal('hide');
    setIsLoading(false);
  };

  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      bsStyle="primary"
    >
      Speichern und einchecken
    </Button>
  );
}
