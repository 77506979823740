import React from 'react';
import { PageHeader, Form, Col } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import FormField from '../../../components/FormField';
import { getSingleInviteFormGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';

export default function InviteFormEditPage() {
  const { data } = useQuery(getSingleInviteFormGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      formId: parseInt(window.formId),
    },
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.getInviteError404'));
      return toastHelper.error(t('invites.errors.getInviteError'));
    },
  });

  const onSubmit = () => {};
  return (
    <>
      <PageHeader>{t('inviteForms.headline')}</PageHeader>
      <FinalForm
        initialValues={data?.getInviteForm}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} horizontal>
            <Col sm={6}>
              <FormField name="name" title="Name" required />
            </Col>
            <Col sm={6}>
              <FormField name="title" title="Titel" required />
            </Col>
            <Col sm={6}>
              <FormField name="description" title="Beschreibung" required />
            </Col>
            <Col sm={6}>
              <FormField name="ticketCategoryOnParticipantCreation" title="Ticketkategorie beim Erstellen des Teilnehmers" required />
            </Col>
          </Form>
        )}
      />
    </>
  );
}
