import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Button, Col, Form, PageHeader, Panel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import axios from 'axios';

import toastHelper from '../../../lib/toastHelper';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getCredentials, getDetailsAndTextGql, updateCredentialsGql, updateDetailsAndTextsGql } from '../gql';
import FormField from '../../../components/FormField';
import TipTapEdior from '../components/TipTapEditor';

function OrderSettings() {
  const { t: tg } = useTranslation('general');
  const { data, loading } = useQuery(getDetailsAndTextGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
  });
  const { data: dataCredentials } = useQuery(getCredentials, {
    fetchPolicy: 'no-cache',
    skip: window.systemRole !== 'admin',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
  });

  const [updateDetails] = useMutation(updateDetailsAndTextsGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const [updateCredentials] = useMutation(updateCredentialsGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
  });

  const generalFields = [
    { name: 'title', title: 'Name des Shop', type: 'string' },
    { name: 'slug', title: 'URL Kürzel', type: 'string' },
    {
      name: 'mode',
      title: 'Modus',
      type: 'select',
      options: [{ label: 'Testmodus', value: 'testing' }, { label: 'Live', value: 'live' }],
      noDefaultOption: true,
    },

  ];

  const fields = [
    { name: 'paymentMethods.card', title: 'Kreditkarte', type: 'boolean' },
    { name: 'paymentMethods.apple_pay', title: 'Apple Pay', type: 'boolean' },
    { name: 'paymentMethods.google_pay', title: 'Google Pay', type: 'boolean' },
    { name: 'paymentMethods.paypal', title: 'PayPal', type: 'boolean' },
    { name: 'paymentMethods.sepa_debit', title: 'SEPA', type: 'boolean' },
    { name: 'paymentMethods.klarna', title: 'Klarna', type: 'boolean' },
  ];

  const onSubmit = async (orgValues) => {
    const values = {
      ...orgValues,
      eventId: undefined,
      logoUrl: undefined,
      newLogoFile: undefined,
      texts: undefined,
      credentials: undefined,
      paymentMethods: {
        card: orgValues.paymentMethods.card === true || orgValues.paymentMethods.card === 'true',
        paypal: orgValues.paymentMethods.paypal === true || orgValues.paymentMethods.paypal === 'true',
        google_pay: orgValues.paymentMethods.google_pay === true || orgValues.paymentMethods.google_pay === 'true',
        sepa_debit: orgValues.paymentMethods.sepa_debit === true || orgValues.paymentMethods.sepa_debit === 'true',
        klarna: orgValues.paymentMethods.klarna === true || orgValues.paymentMethods.klarna === 'true',
      },
    };

    await updateDetails({
      variables: {
        eventId: window.eventId,
        input: values,
        inputTexts: orgValues.texts,
      },
    });

    if (orgValues.newLogoFile) {
      const formData = new FormData();
      formData.append('file', orgValues.newLogoFile);
      await axios
        .postForm(`/api/v1/events/${window.eventId}/ecommerce/logo`, formData, {
          withCredentials: true,
        });
    }

    if (orgValues.credentials && window.systemRole === 'admin') {
      await updateCredentials({
        variables: {
          eventId: window.eventId,
          input: orgValues.credentials,
        },
      });
    }
  };

  // eslint-disable-next-line arrow-body-style
  const initialValues = useMemo(() => {
    return {
      ...data?.getDetails?.event,
      paymentMethods: {
        card: false,
        paypal: false,
        apple_pay: false,
        google_pay: false,
        sepa_debit: false,
        klarna: false,
        ...(data?.getDetails?.event.paymentMethods || {}),
      },
      credentials: dataCredentials?.getCredentials?.credentials,
      texts: data?.getTexts?.texts,
    };
  }, [data?.getDetails?.event, data?.getTexts?.texts, dataCredentials?.getCredentials?.credentials]);

  if (loading) return (<LoadingSpinner />);

  return (
    <PageWrapper>
      <PageHeader>Einstellungen</PageHeader>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton bsStyle="primary" type="submit">
                {tg('save')}
              </ActionButton>
            </div>
            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Allgemein</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {generalFields
                      .map((field) => (
                        field.type !== 'subheadline'
                          ? (
                            <FormField
                              name={field.name}
                              title={field.title}
                              labelSize={2}
                              inputSize={10}
                              type={field.type}
                              options={field.options}
                              required={field.required}
                              noDefaultOption={field.noDefaultOption}
                            />
                          )
                          : <div className="subheadline">{field.title}</div>
                      ))}
                  </Panel.Body>
                </Panel>
              </Col>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Zahlungsarten</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {fields
                      .map((field) => (
                        field.type !== 'subheadline'
                          ? (
                            <FormField
                              name={field.name}
                              title={field.title}
                              labelSize={2}
                              inputSize={10}
                              type={field.type}
                              options={field.options}
                              required={field.required}
                            />
                          )
                          : <div className="subheadline">{field.title}</div>
                      ))}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Impressum</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="texts.imprint.text">
                      {({ input }) => (
                        <TipTapEdior {...input} />
                      )}
                    </Field>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">AGB</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="texts.toc.text">
                      {({ input }) => (
                        <TipTapEdior {...input} />
                      )}
                    </Field>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Datenschutzerklärung</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="texts.privacyPolicy.text">
                      {({ input }) => (
                        <TipTapEdior {...input} />
                      )}
                    </Field>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Widerrufsrecht</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="texts.rightOfWithdrawal.text">
                      {({ input }) => (
                        <TipTapEdior {...input} />
                      )}
                    </Field>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

            {window.systemRole === 'admin' && (
              <Row>
                <Col xs={12}>
                  <Panel>
                    <Panel.Heading>
                      <Panel.Title componentClass="h3">Sevdesk</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      <FormField
                        name="credentials.sevDeskApiKey"
                        title="Sevdesk Api Key"
                        labelSize={2}
                        inputSize={10}
                      />
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Tracking</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <FormField
                      name="frontendSettings.facebookPixelId"
                      title="Meta Pixel Id"
                      labelSize={2}
                      inputSize={10}
                    />
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>

          </Form>
        )}
      />
    </PageWrapper>
  );
}

export default OrderSettings;

const ActionButton = styled(Button)`
  margin-left: 5px;
`;

const PageWrapper = styled.div`
  max-width: 1050px;
  margin: 0 auto;
`;
