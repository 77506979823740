import React, { useState } from 'react';
import { PageHeader, Button, Table, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { addRoomGql, deleteRoomGql, getRoomsGql, updateRoomGql } from './gql';
import RoomEditDialog from './components/RoomEditDialog';
import toastHelper from '../../lib/toastHelper';
import ConfirmModal from '../../components/ConfirmModal';

export default function RoomsPage() {
  const { t } = useTranslation('rooms');
  const { t: tg } = useTranslation('general');
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [confirmData, setConfirmData] = useState({});

  const { data, refetch } = useQuery(getRoomsGql, {
    variables: { eventId: window.eventId },
    pollInterval: 10_000,
  });

  const [addRoom, { loading: loadingAdd }] = useMutation(addRoomGql, {
    onError: () => {},
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const [updateRoom, { loading: loadingUpdate }] = useMutation(updateRoomGql, {
    onError: () => {},
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const [deleteRoom, { loading: deleteRoomLoading }] = useMutation(deleteRoomGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(tg('successMessages.deleted')); },
  });

  const handleError = (errors) => {
    if (errors?.networkError?.statusCode === 409) {
      // eslint-disable-next-line consistent-return
      return { name: t('ticketCategories.errors.nameConflict') };
    }
    toastHelper.error(tg([`errors.error${errors.networkError.statusCode}`, 'errors.generalError']));
    return {};
  };

  const onSubmit = async (data) => {
    if (!modalData) {
      const { errors } = await addRoom({
        variables: {
          eventId: window.eventId,
          input: data,
        },
      });
      // eslint-disable-next-line consistent-return
      if (errors) return handleError(errors);

      refetch();
      setShowEditModal(false);
      setModalData(null);
      return {};
    }

    const { errors } = await updateRoom({
      variables: {
        eventId: window.eventId,
        roomId: modalData.id,
        input: {
          ...data,
          status: undefined,
          currentPresenceCount: undefined,
          id: undefined,
        },
      },
    });

    // eslint-disable-next-line consistent-return
    if (errors) return handleError(errors);

    refetch();
    setShowEditModal(false);
    setModalData(null);
    return {};
  };

  const handleDelete = (room, cb) => {
    setConfirmData({
      show: true,
      title: t('delete.title'),
      text: t('delete.text', { room }),
      confirmMessage: t('delete.delete'),
      confirmBsStyle: 'danger',
      loading: deleteRoomLoading,
      onClose: () => { setConfirmData({ show: false }); },
      onConfirm: async () => {
        await deleteRoom({ variables: { eventId: window.eventId, roomId: room.id } });
        setConfirmData({ show: false });
        if (cb) cb();
      },
    });
  };

  return (
    <>
      <PageHeader>{t('headline')}</PageHeader>
      <Button
        bsStyle="primary"
        onClick={() => {
          setModalData(null);
          setShowEditModal(true);
        }}
      >{t('add')}
      </Button>
      <Table striped hover>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('currentPresenceCount')}</th>
            <th>{t('maxAttendees')}</th>
            <th>{tg('action')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.getRooms?.rooms || []).map((room) => (
            <tr key={room.id}>
              <td>{room.name}</td>
              <td>{room.currentPresenceCount}</td>
              <td>{room.maxAttendees}</td>
              <td>
                <ButtonToolbar>
                  {window.eventRole !== 'viewer' && (
                    <Button
                      bsStyle="primary"
                      title={tg('edit')}
                      onClick={() => {
                        setModalData(room);
                        setShowEditModal(true);
                      }}
                    >
                      <Glyphicon glyph="pencil" />
                    </Button>
                  )}
                  <Button
                    bsStyle="info"
                    title={t('invites.showInviteForm')}
                    href={`/events/roomAttendees/${window.eventId}/${room.id}`}
                  >
                    <Glyphicon glyph="list-alt" />
                  </Button>
                  {window.eventRole !== 'viewer' && (
                    <Button
                      bsStyle="danger"
                      title={tg('delete')}
                      onClick={() => handleDelete(room)}
                    >
                      <Glyphicon glyph="remove" />
                    </Button>
                  )}
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <RoomEditDialog
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        modalData={modalData}
        onSubmit={onSubmit}
        ticketCategories={data?.getProducts?.products || []}
        loading={loadingUpdate || loadingAdd}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}
