/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getHostsGql = gql`
  query getHosts{
    getHosts 
      @rest(type: "[host]", path: "hosts") {
        hosts {
          id
          name
          lastPingDate
          events
          subevents
        }
    }
  }
`;

export const updateHostGql = gql`
  mutation updateHost($hostId: ID!, $input: PublishablePostInput!) {
    updateHost (hostId: $hostId, input: $input) @rest(
      path: "hosts/{args.hostId}",
      method: "POST"
    ) { NoResponse }
  }
`;
