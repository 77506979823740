import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import DashboardPanel from './components/DashboardPanel';
import { getStatisticsGql } from './gql';
import PaymentStatistics from './components/PaymentStatistics';
import InviteStatistics from './components/InviteStatistics';
import QuotasStatistics from './components/QuotasStatistics';
import DeviceStatistics from './components/DeviceStatistics';
import GeneralStatistics from './components/GeneralStatistics';
import CategoryStatistics from './components/CategoryStatistics';
import LoadingSpinner from '../../components/LoadingSpinner';
import OrderStatistics from './components/OrderStatistics';
import RoomStatistics from './components/RoomStatistics';

function DashboardPage() {
  const hasRfidFeature = window.eventFeatures && window.eventFeatures.includes('rfid');
  const ecommerceFeature = window.eventFeatures && window.eventFeatures.includes('ecommerce');
  const { data, loading } = useQuery(getStatisticsGql, {
    variables: { eventId: window.eventId },
  });

  if (loading) return (<LoadingSpinner />);

  return (
    <>
      <GeneralStatistics statistics={data?.statistics} />
      {ecommerceFeature && (<OrderStatistics />)}
      <InviteStatistics />
      <PaymentStatistics />
      <QuotasStatistics />
      <DeviceStatistics />
      <CategoryStatistics statistics={data?.statistics} />
      <RoomStatistics />
      {hasRfidFeature && (
        <>
          <PageHeader>Sontiges</PageHeader>
          <Row>
            <Col md={6}>
              <DashboardPanel
                name="Ausgegebene Bänder"
                value={data?.statistics?.additionalBarcodeCount}
                icon="id-badge"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default DashboardPage;
