/* eslint-disable consistent-return */
import React, { useMemo, useState } from 'react';
import { Button, ButtonToolbar, PageHeader, Table } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../components/ConfirmModal';
import { createAttributeGql, deleteAttributeGql, getAttributesGql, updateAttributeGql } from '../gql';
// import TicketCategoryEditDialog from './components/TicketCategoryEditDialog';
import toastHelper from '../../../lib/toastHelper';
import AttributeEditDialog from '../components/AttributeEditDialog';

function SettingAttributePage() {
  const { t } = useTranslation('attributes');
  const { t: tg } = useTranslation('general');
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(null);
  const [deleteAttributeId, setDeleteAttributeId] = useState();

  const { data, refetch } = useQuery(getAttributesGql, {
    variables: { eventId: window.eventId },
  });
  const [createAttribute] = useMutation(createAttributeGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });
  const [updateAttribute] = useMutation(updateAttributeGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });
  const [deleteAttribute] = useMutation(deleteAttributeGql, {
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('delete.success')),
  });

  const handleError = (errors) => {
    if (errors?.networkError?.statusCode === 409) {
      // eslint-disable-next-line consistent-return
      return { name: t('errors.nameConflict') };
    }
    toastHelper.error(tg([`errors.error${errors.networkError.statusCode}`, 'errors.generalError']));
    return {};
  };

  const onSubmit = async (incomingValues) => {
    const values = { ...incomingValues };
    const attributeId = values.id;
    delete values.id;
    delete values.createInviteWithCategoryId;
    if (values.settings?.showOnScan !== undefined) {
      values.settings.showOnScan = values.settings?.showOnScan === 'true' || values.settings?.showOnScan === true;
    }
    if (values.settings?.highlightOnScan !== undefined) {
      values.settings.highlightOnScan = values.settings?.highlightOnScan === 'true' || values.settings?.highlightOnScan === true;
    }

    if (attributeId) {
      delete values.id;
      const { errors } = await updateAttribute({
        variables: {
          eventId: window.eventId,
          attributeId,
          input: values,
        },
      });
      if (errors) return handleError(errors);

      setShowModal(false);
      setModalData(null);
      refetch();
      return;
    }

    const { errors } = await createAttribute({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });

    // eslint-disable-next-line consistent-return
    if (errors) return handleError(errors);

    setShowModal(false);
    setModalData(null);
    refetch();
  };

  const onDelete = async (attribute) => {
    setDeleteAttributeId(attribute.id);
    setShowDeletePrompt(t('delete.text', attribute));
  };

  const onDeleteConfirm = async () => {
    setShowDeletePrompt(false);
    await deleteAttribute({
      variables: { eventId: window.eventId, attributeId: deleteAttributeId },
    });
    refetch();
  };

  const attributes = useMemo(() => {
    const attributes = data?.getAttributes?.attributes || [];
    return attributes.filter((attr) => attr.visibility !== 'system' && attr.visibility !== 'externalSystem');
  }, [data?.getAttributes?.attributes]);

  return (
    <>
      <PageHeader>Attribute verwalten</PageHeader>
      <Button
        bsStyle="primary"
        onClick={() => {
          const maxSequence = Math.max(...attributes.map(({ sequenceId }) => sequenceId)) || 0;
          setModalData({
            sequenceId: maxSequence + 1,
          });
          setShowModal(true);
        }}
      >{t('createDialog.title')}
      </Button>
      <Table striped hover>
        <thead>
          <tr>
            <th>{t('table.name')}</th>
            <th>{t('table.type')}</th>
            <th>{t('table.section')}</th>
            <th>{t('table.isVisibleInTable')}</th>
            <th>{t('highlightOnScan')}</th>
            <th>{t('highlightOnScanFilterType')}</th>
            <th>{tg('action')}</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attr) => (
            <tr key={attr.id}>
              <td>{attr.name}</td>
              <td>{t(`type.${attr.type}`)}</td>
              <td>{attr.section}</td>
              <td>{attr?.isVisibleInTable ? tg('form.boolean.true') : tg('form.boolean.false')}</td>
              <td>{attr?.settings?.showOnScan ? tg('form.boolean.true') : tg('form.boolean.false')}</td>
              <td>{attr?.settings?.highlightOnScan ? tg('form.boolean.true') : tg('form.boolean.false')}</td>
              <td>
                <ButtonToolbar>
                  <Button
                    onClick={() => {
                      setModalData(attr);
                      setShowModal(true);
                    }}
                    bsStyle="primary"
                  >{tg('edit')}
                  </Button>
                  <Button
                    bsStyle="danger"
                    onClick={() => onDelete(attr)}
                  >
                    {tg('delete')}
                  </Button>
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <AttributeEditDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        modalData={modalData}
        onSubmit={onSubmit}
      />
      <ConfirmModal
        show={!!showDeletePrompt}
        title={t('delete.title')}
        text={showDeletePrompt}
        confirmMessage={tg('delete')}
        onConfirm={onDeleteConfirm}
        onClose={() => setShowDeletePrompt(false)}
        confirmBsStyle="danger"
      />
    </>
  );
}

export default SettingAttributePage;
