import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import { getCustomersGql } from '../../customers/gql';

export default function DeleteConfirmModal(props) {
  const {
    show,
    onClose,
    onConfirm,
    customer,
    loading = false,
  } = props;

  const { data, loading: loadingCustomers } = useQuery(getCustomersGql, {
    skip: !customer,
    fetchPolicy: 'network-only',
    variables: {
      eventId: window.eventId,
      limit: 1000,
      offset: 0,
      searchTerm: '',
      filter: JSON.stringify({ 'linkedCustomer.id': customer?.id }),
      sort: 'internalId:ASC',
    },
  });

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('customers.deleteCustomer.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingCustomers
          ? (<div style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faCircleNotch} size="2x" spin /></div>)
          : (
            <div>
              {t(data?.getCustomers?.participants?.length
                ? 'customers.deleteCustomer.textMultiple'
                : 'customers.deleteCustomer.text', { customer })}
              {data?.getCustomers?.participants?.length ? (
                <>
                  <br />
                  Dieser Gast ist Gastgeber für folgende Teilnehmer:
                  <ul>
                    {data?.getCustomers?.participants?.map((customer) => (<li>{customer.forename} {customer.name}</li>))}
                  </ul>
                </>
              ) : null}
            </div>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" onClick={onConfirm} disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : t('invites.deleteInvite.delete')}
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          {t('general.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
