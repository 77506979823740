import React, { useState } from 'react';
import { Button, ButtonGroup, Glyphicon, PageHeader } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useQuery, useMutation } from '@apollo/client';
import ConfirmModal from '../../components/ConfirmModal';
import toastHelper from '../../lib/toastHelper';
import { t } from '../../locale';
import { getUsersGql, deleteUserGql, updateUserGql, addUserGql } from './gql';
import UserEditDialog from './components/UserEditDialog';

export default function UserPage() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const isAdmin = window.eventOrganizer.role === 'admin';

  const { data: { getUsers: users = [] } = { getUsers: [] }, refetch } = useQuery(getUsersGql, {
    fetchPolicy: 'no-cache',
    skip: !isAdmin,
    variables: {
      eventOrganizerId: window.eventOrganizerId,
    },
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('users.errors.getUsersError404'));
      return toastHelper.error(t('users.errors.getUsersError'));
    },
  });

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(deleteUserGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 400) return toastHelper.error(t('users.errors.deleteUserError400'));
      if (err.networkError.statusCode === 404) return toastHelper.error(t('users.errors.deleteUserError404'));
      return toastHelper.error(t('users.errors.deleteUserError'));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('users.deleteUser.success')); },
  });

  const [updateUser] = useMutation(updateUserGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('users.errors.editUserError404'));
      return toastHelper.error(t('users.errors.editUserError'));
    },
    onCompleted: () => toastHelper.success(t('users.editUserDialog.success.edit')),
  });

  const [createUser] = useMutation(addUserGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('users.errors.addUserError404'));
      if (err.networkError.statusCode === 400) return toastHelper.error(t('users.errors.addUserError400'));
      return toastHelper.error(t('users.errors.addUserError'));
    },
    onCompleted: () => toastHelper.success(t('users.editUserDialog.success.create')),
  });

  const onDeleteUserConfirm = async () => {
    await deleteUser({ variables: { eventOrganizerId: window.eventOrganizerId, userId: selectedUser.userId } });
    setSelectedUser(null);
    setShowDeleteModal(false);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleEditUser = (user = null) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const onEditSubmit = async ({ email, forename, name, company, role }) => {
    if (selectedUser) {
      await updateUser({
        variables: {
          eventOrganizerId: window.eventOrganizerId,
          userId: selectedUser.userId,
          input: {
            forename: forename || null,
            name: name || null,
            company: company || null,
            role: role || null,
          },
        },
      });
    } else {
      await createUser({
        variables: {
          eventOrganizerId: window.eventOrganizerId,
          input: {
            email,
            forename,
            name,
            company,
            role,
          },
        },
      });
    }

    setSelectedUser(null);
    setShowEditModal(false);
    return refetch();
  };

  const columns = [
    { text: t('users.email'), dataField: 'email' },
    { text: t('users.forename'), dataField: 'forename' },
    { text: t('users.name'), dataField: 'name' },
    { text: t('users.role'),
      dataField: 'role',
      formatter: (_, row) => (
        t(`users.${row.role}`)
      ),
    },
    {
      text: 'Aktion',
      dataField: 'id',
      isDummyField: true,
      formatter: (_, row) => (
        <ButtonGroup>
          <Button
            bsStyle="primary"
            title={t('general.edit')}
            onClick={() => handleEditUser(row)}
          >
            <Glyphicon glyph="pencil" />
          </Button>
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            onClick={() => handleDeleteUser(row)}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  if (!isAdmin) return (<h1>Access denied!</h1>);

  return (
    <>
      <PageHeader>{t('users.headline')}</PageHeader>

      <Button
        bsStyle="primary"
        onClick={() => handleEditUser()}
      >{t('users.editUserDialog.title.edit')}
      </Button>

      <BootstrapTable
        remote
        keyField="userId"
        columns={columns}
        data={users}
        striped
        bordered={false}
      />

      <UserEditDialog
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        modalData={selectedUser}
        onSubmit={onEditSubmit}
      />

      <ConfirmModal
        show={showDeleteModal}
        title={t('users.deleteUser.title')}
        text={t('users.deleteUser.text', { user: selectedUser })}
        loading={deleteUserLoading}
        confirmBsStyle="danger"
        confirmMessage={t('users.deleteUser.delete')}
        onConfirm={onDeleteUserConfirm}
        onClose={() => { setShowDeleteModal(false); setSelectedUser(null); }}
      />

    </>
  );
}
