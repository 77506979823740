import React from 'react';
import { PageHeader, Table } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getRoomGql, getRoomParticipantsGql } from '../gql';

export default function RoomAttendeesPage() {
  const { t } = useTranslation('rooms');
  const { data } = useQuery(getRoomParticipantsGql, {
    variables: { eventId: window.eventId, roomId: window.roomId },
    pollInterval: 10_000,
  });
  const { data: roomData } = useQuery(getRoomGql, {
    variables: { eventId: window.eventId, roomId: window.roomId },
  });

  return (
    <>
      <PageHeader>{t('attendeesHeadline', { name: roomData?.getRoom?.room?.name || '' })}</PageHeader>

      <Table striped hover>
        <thead>
          <tr>
            <th>{t('attendeesColumns.forename')}</th>
            <th>{t('attendeesColumns.name')}</th>
            <th>{t('attendeesColumns.email')}</th>
            <th>{t('attendeesColumns.enter')}</th>
            <th>{t('attendeesColumns.left')}</th>
            <th>{t('attendeesColumns.duration')}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.getRoomParticipants?.participants || []).map((part, index) => (
            <tr key={`${part.id}-${index}`}>
              <td>{part.forename}</td>
              <td>{part.name}</td>
              <td>{part.email}</td>
              <td>{moment(part.enter).format('LLL')}</td>
              <td>{part.left ? moment(part.left).format('LLL') : ''}</td>
              <td>{moment.duration(moment(part.left || undefined).diff(part.enter), 'milliseconds').format('h [hrs], m [min]')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
