import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Glyphicon, PageHeader,
} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { useDebounce, useSessionStorage } from 'react-use';
import styled from 'styled-components';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { t } from '../../../locale';
import {
  deleteCustomerGql, getAttributesGql, getCustomersGql, getDuplicatedCustomersGql, getTicketCategoriesGql, mergeCustomerGql,
} from '../gql';
import toastHelper from '../../../lib/toastHelper';
import QueryBuilder from '../components/QueryBuilder';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../../components/AdvancedBootstapTable';
import ConfirmModal from '../../../components/ConfirmModal';

function DuplicatesPage() {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmData, setConfirmData] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`customerPaymentSearch_${window.eventId}`, '');
  const [filter, setFilter] = useState({});
  const [tableFilter, setTableFilter] = useState([]);

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const [getCustomers] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const filterToSend = useMemo(() => {
    let filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);

    if (tableFilter && tableFilter.length) {
      filterToSend = { $and: [...tableFilter, ...filterToSend.$and] };
    }
    if (!filterToSend.$and.length) return '';
    return JSON.stringify(filterToSend);
  }, [filter, tableFilter]);

  const {
    data: { getDuplicatedCustomers: { participants, meta } = { participants: [], meta: {} } } = {},
    refetch,
  } = useQuery(getDuplicatedCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: debouncedSearchTerm,
      filter: filterToSend,
      sort: '',
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(deleteCustomerGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const [mergeCustomer, { loading: mergeCustomerLoading }] = useMutation(mergeCustomerGql, {
    onCompleted: () => { refetch(); toastHelper.success(t('customersDuplicates.mergeCustomer.success')); },
  });

  const handleDelete = (customer, cb) => {
    setConfirmData({
      show: true,
      title: t('customers.deleteCustomer.title'),
      text: t('customers.deleteCustomer.text', { customer }),
      loading: deleteCustomerLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('invites.deleteInvite.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomer({ variables: { eventId: window.eventId, customerId: customer.id } });
        setConfirmData({});
        if (cb) cb();
      },
    });
  };

  const handleMerge = async (customer) => {
    const { data } = await getCustomers({
      variables: {
        eventId: window.eventId,
        limit: 1000,
        offset: 0,
        filter: JSON.stringify({ forename: customer.forename, name: customer.name, email: customer.email }),
      },
    });

    const duplicates = `
    <ul>
      ${data?.getCustomers?.participants.map((customer) => `
        <li>${customer.forename} ${customer.name}
          ${customer.linkedCustomer ? `(Gast von ${customer.linkedCustomer.forename} ${customer.linkedCustomer.name})` : ''}
        </li>
      `).join('')}
    </ul>
    `;

    setConfirmData({
      show: true,
      title: t('customersDuplicates.mergeCustomer.title'),
      text: t('customersDuplicates.mergeCustomer.text', { duplicates, interpolation: { escapeValue: false } }),
      loading: mergeCustomerLoading,
      // confirmBsStyle: 'danger',
      confirmMessage: t('customersDuplicates.mergeCustomer.merge'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await mergeCustomer({ variables: { eventId: window.eventId, customerId: customer.id, input: {} } });
        setConfirmData({});
      },
    });
  };

  const categoryFilterOptions = useMemo(() => {
    if (!categoriesResult?.getTicketCategories?.ticketCategories) return [];
    return categoriesResult?.getTicketCategories?.ticketCategories.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [categoriesResult?.getTicketCategories?.ticketCategories]);

  const columns = [
    {
      text: t('customers.table.forename'),
      dataField: 'forename',
      filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.forename') }),
    },
    { text: t('customers.table.name'), dataField: 'name', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.name') }) },
    /* {
      text: t('customers.table.company'),
      dataField: 'company',
      filter: textFilter({ placeholder: t('customersPayment.table.filterPlaceholder.company') }),
    }, */
    { text: t('customers.table.email'), dataField: 'email', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.email') }) },
    {
      text: t('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      filter: selectFilter({
        options: [{ value: '', label: t('customers.table.filterPlaceholder.allCategories') }, ...categoryFilterOptions],
        placeholder: t('customers.table.filterPlaceholder.ticketCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    {
      text: t('customers.table.linkedCustomer'),
      dataField: 'linkedCustomer',
      formatter: (cellContent, row) => (
        (row.linkedCustomer)
          ? (
            <a href={`/customers/edit/${window.eventId}/${row.linkedCustomer.id}`}>
              {`${row.linkedCustomer?.forename} ${row.linkedCustomer?.name}`}
            </a>
          ) : ''
      ),
    },
    {
      dataField: 'id',
      text: 'Status',
      isDummyField: true,
      headerStyle: () => ({ width: '70px' }),
      formatter: (cellContent, row) => {
        const isPayer = row.ticketCategory?.settings?.needsPayment || false;
        return (
          <>
            <StatusIcon glyph="envelope" className={row?.generalInfo?.emailSent ? 'text-success' : ''} />
            {isPayer ? (
              <StatusIcon
                glyph="euro"
                className={
                  row.customAttributes?.donation || row.customAttributes?.donationOper || row.customAttributes?.beitrag
                    ? 'text-success'
                    : 'text-danger'
                }
              />
            ) : null}
          </>
        );
      },
    },
  ];

  if (window.eventRole !== 'viewer') {
    columns.push(
      {
        text: 'Aktion',
        isDummyField: true,
        headerStyle: () => ({ width: '170px' }),
        formatter: (cellContent, row) => (
          <ButtonGroup>
            <Button
              bsStyle="primary"
              title={t('customersDuplicates.merge')}
              onClick={() => handleMerge(row)}
            >
              <Glyphicon glyph="link" />
            </Button>
            <Button
              bsStyle="primary"
              title={t('general.edit')}
              href={`/customers/edit/${window.eventId}/${row.id}`}
            >
              <Glyphicon glyph="pencil" />
            </Button>
            <Button
              bsStyle="danger"
              title={t('general.delete')}
              onClick={() => handleDelete(row)}
            >
              <Glyphicon glyph="remove" />
            </Button>
          </ButtonGroup>
        ),
      },
    );
  }

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        if (name === 'paid') {
          newFilter.push({
            $or: [
              { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
              { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
            ],
          });
          continue;
        }
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  return (
    <>
      <PageHeader>{t('customersDuplicates.headline')}</PageHeader>

      <QueryBuilder
        attributes={attributeResult?.getAttributes?.attributes}
        ticketCategories={categoriesResult?.getTicketCategories?.ticketCategories}
        onFilterApply={(newFilter) => { setFilter(newFilter); if (JSON.stringify(newFilter) === JSON.stringify(filter)) { refetch(); } }}
        filterPersistKey={`customerPaymentFilter_${window.eventId}`}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <AdvancedBootstapTable
        remote
        keyField="internalId"
        columns={columns}
        data={participants}
        striped
        bordered={false}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta.total || 0,
          sizePerPageList: [10, 25, 50, 100],
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageRenderer,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        filterPersistKey={`paymentTableFilter_${window.eventId}`}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
    </>
  );
}

const StatusIcon = styled(Glyphicon)`
    font-size: 20px;
    margin-right: 5px;
`;

export default DuplicatesPage;
