import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useMutation } from '@apollo/client';
import { useLocalStorage } from 'react-use';
import CustomerEditDialog from './components/CustomerEditDialog';
import toastHelper from '../../lib/toastHelper';
import { addDeviceGql, createCustomerGql, updateCustomerGql, addPresenceGql } from './gql';
import { t } from '../../locale';

// eslint-disable-next-line prefer-arrow-callback
export default forwardRef(function CustomerPageElement({ onUpdateDone, onCreationDone }, ref) {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [localPrinter] = useLocalStorage(`${window.eventId}_localPrinter`);
  const [localPrinterSubevent] = useLocalStorage(`${window.eventId}_localPrinterSubevent`);

  useImperativeHandle(ref, () => ({
    openEditDialog: (data) => {
      setShowEditDialog(true);
      setModalData(data);
    },
  }), []);

  const [createCustomer] = useMutation(createCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  const [addDevice] = useMutation(addDeviceGql, {
    onError: (err) => {
      if (['EHOSTNOTFOUND', 'EPRINTERNOTAVAILABLE'].includes(err.networkError.result?.code)) {
        toastHelper.error(t('customers.devices.addDevice.errors.EHOSTNOTFOUND'));
        return;
      }
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { toastHelper.success(t('customers.devices.addDevice.success')); },
  });

  const [addPresence] = useMutation(addPresenceGql, {
    onError: (err) => {
      if (err.networkError.result?.code === 'EHOSTNOTFOUND') {
        toastHelper.error(t('customers.devices.addDevice.errors.EHOSTNOTFOUND'));
        return;
      }
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { toastHelper.success(t('customers.devices.addDevice.success')); },
  });

  const [updateCustomer] = useMutation(updateCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  window.openEditDialog = (data) => {
    setShowEditDialog(true);
    setModalData(data);
  };

  const onSubmit = async (data) => {
    const dataToSubmit = { ...data };
    const { outputDeviceAfterCreation, printAfterCreation, checkinAfterCreation } = dataToSubmit;
    delete dataToSubmit.outputDeviceAfterCreation;
    delete dataToSubmit.printAfterCreation;
    delete dataToSubmit.checkinAfterCreation;
    if (!dataToSubmit.id) {
      const { errors, data } = await createCustomer({
        variables: {
          eventId: window.eventId,
          input: {
            participant: {
              ...dataToSubmit,
              __typename: undefined,
              id: undefined,
              invites: undefined,
              ticket_category_id: undefined,
              devices: undefined,
              presences: undefined,
            },
          },
        },
      });
      if (errors) return;
      if (outputDeviceAfterCreation) {
        await addDevice({
          variables: {
            eventId: window.eventId,
            customerId: data.createCustomer.barcode,
            input: {
              print: true,
              printOnHost: localPrinter,
            },
          },
        });
      }
      if ((printAfterCreation || checkinAfterCreation) && localPrinterSubevent) {
        await addPresence({
          variables: {
            eventId: window.eventId,
            customerId: data.createCustomer.barcode,
            input: {
              subeventId: localPrinterSubevent.id.toString(),
              host: 'WEB',
              date: new Date(),
              print: printAfterCreation || false,
              printOnHost: printAfterCreation ? localPrinter : undefined,
            },
          },
        });
      }

      onCreationDone && onCreationDone(dataToSubmit);
      window.reloadTable && window.reloadTable();
      onUpdateDone && onUpdateDone();
      // window.setSearchTermOfTable && window.setSearchTermOfTable(`${dataToSubmit.forename} ${dataToSubmit.name}`);
      window.openEditDialog({ id: data.createCustomer.barcode });
      return;
    }
    await updateCustomer({
      variables: {
        eventId: window.eventId,
        customerId: dataToSubmit.id,
        input: {
          ...dataToSubmit,
          __typename: undefined,
          id: undefined,
          invites: undefined,
          ticket_category_id: undefined,
          devices: undefined,
          presences: undefined,
          participantsCrossEvent: undefined,
        },
      },
    });
    if (onUpdateDone) {
      onUpdateDone();
    } else {
      window.location.reload();
    }
    setShowEditDialog(false);
    setModalData(null);
  };

  return (
    <CustomerEditDialog
      show={showEditDialog}
      onHide={() => {
        setShowEditDialog(false);
        setModalData(null);
      }}
      modalData={modalData}
      onSubmit={onSubmit}
      onSubentityChange={() => {
        onUpdateDone && onUpdateDone();
      }}
    />
  );
});
