import React from 'react';
import { Col, Panel, Row } from 'react-bootstrap';
import styled from 'styled-components';

function DashboardPanel({ name, value, color, icon, link }) {
  return (
    <Panel bsStyle={color} onClick={() => { if (link) window.location.href = link; }}>
      <Panel.Heading>
        <Row>
          <Col xs={3}>
            <i className={`fa fa-${icon} fa-5x`} />
          </Col>
          <ColLink xs={9} className="text-right" link={link}>
            <div className="huge">{value}</div>
            <div className="name">{name}</div>
          </ColLink>
        </Row>
      </Panel.Heading>
    </Panel>
  );
}

const ColLink = styled(Col)`
  ${(props) => props.link && `
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    } 
  `}
`;

export default DashboardPanel;
