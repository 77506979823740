import React, { useEffect, useRef } from 'react';
import { Modal, Button, FormControl, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { t } from '../locale';

export default function UploadModal(props) {
  const {
    show,
    title = '',
    text,
    onClose,
    onConfirm,
    loading = false,
    closeMessage = t('general.close'),
    confirmMessage = t('general.save'),
    confirmBsStyle = 'primary',
    accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    lineErrors = [],
    file,
  } = props;
  const fileInputRef = useRef();

  useEffect(() => {
    if (!file && fileInputRef.current?.value) fileInputRef.current.value = null;
  }, [file]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lineErrors.length ? (
          <Alert bsStyle="warning">
            <b>{t('import.errorHeadline')}</b>
            <br /><br />
            <ul>
              {lineErrors.map(({ lineNumber, errors }, index) => (
                <li key={index}>
                  <b>{t('import.lineNumber', { lineNumber })}:</b>
                  {' '}
                  {errors.map((error) => <>{t(`import.customerErrors.${error.code}`, { error })}<br /></>)}
                </li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <span dangerouslySetInnerHTML={{ __html: text }} />
        <FormControl
          inputRef={fileInputRef}
          type="file"
          accept={accept}
          onChange={(e) => props.setFile(e.target.files[0])}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle={confirmBsStyle} onClick={onConfirm} disabled={loading || !props.file}>
          {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : confirmMessage}
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          {closeMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
