import React, { useMemo, useRef, useState } from 'react';
import { PageHeader, Form, Col, Row, Button, Panel, ButtonGroup, Glyphicon } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import { t } from '../../../locale';
import { createTableGql, getSingleTableGql, updateTableGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import FormPanel from '../../customers/components/FormPanel';
import FormField from '../../../components/FormField';
import AddTableAttendee from '../components/AddTableAttendee';

export default function TableEditPage() {
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef(null);
  const isNew = !window.tableId;
  const { data, loading, refetch } = useQuery(getSingleTableGql, {
    fetchPolicy: 'no-cache',
    skip: isNew,
    variables: {
      eventId: window.eventId,
      tableId: parseInt(window.tableId),
    },
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.getInviteError404'));
      return toastHelper.error(t('invites.errors.getInviteError'));
    },
  });

  const [createTable] = useMutation(createTableGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.created')),
  });
  const [updateTable] = useMutation(updateTableGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.saved')); },
  });

  const fields = [
    { name: 'name', label: t('tables.name'), required: true },
    {
      name: 'seatCount',
      label: t('tables.seatCount'),
      required: true,
      type: 'select',
      options: [
        { value: 8, label: t('tables.seatCountSelection.8') },
        { value: 9, label: t('tables.seatCountSelection.9') },
        { value: 10, label: t('tables.seatCountSelection.10') },
        { value: 11, label: t('tables.seatCountSelection.11') },
        { value: 12, label: t('tables.seatCountSelection.12') },
        { value: 13, label: t('tables.seatCountSelection.13') },
        { value: 14, label: t('tables.seatCountSelection.14') },
      ],
    },
    { name: 'tableNumber', label: t('tables.tableNumber') },
    { name: 'owner', label: t('tables.owner'), type: 'attendee' },
  ];

  const attendeesColumn = [
    { text: t('customers.table.forename'), dataField: 'forename' },
    { text: t('customers.table.name'), dataField: 'name' },
    { text: t('customers.table.email'), dataField: 'email' },
    {
      text: 'Aktion',
      isDummyField: true,
      headerStyle: () => ({ width: '70px' }),
      formatter: (cellContent, row) => (
        <ButtonGroup>
          <Button
            bsStyle="danger"
            title={t('general.delete')}
            onClick={() => {
              const attendees = [...formRef.current?.getState().values.attendees || []]
                .filter(({ internalId }) => internalId !== row.internalId);
              formRef.current?.change('attendees', attendees);

              const seating = [...formRef.current?.getState().values.seating || []]
                .map((seat) => {
                  if (seat === row.internalId) return null;
                  return seat;
                });
              formRef.current?.change('seating', seating);
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const getOptions = (values, i) => {
    if (!values.attendees) return [];
    return [
      { label: 'Nicht besetzt', value: '' },
      ...values.attendees
        .filter(({ internalId }) => !values.seating.includes(internalId.toString()) || values.seating.indexOf(internalId.toString()) === i)
        .map((value) => ({ label: `${value.forename} ${value.name}`, value: value.internalId })),
    ];
  };

  const onSubmit = async (data) => {
    const dataToSubmit = {
      tableNumber: null,
      ...data,
      attendees: data.attendees?.map((attendee) => ({ internalId: attendee.internalId, seat: attendee.seat })),
      __typename: undefined,
      seating: undefined,
      id: undefined,
    };
    if (data.owner?.id) dataToSubmit.owner = { id: parseInt(data.owner.id) };

    if (dataToSubmit.attendees) {
      for (const attendee of dataToSubmit.attendees) {
        const seatIndex = data.seating.findIndex((internalId) => attendee.internalId === parseInt(internalId));
        if (seatIndex !== -1) {
          attendee.seat = seatIndex;
          continue;
        }
        attendee.seat = null;
      }
    }

    if (!window.tableId) {
      const result = await createTable({
        variables: { eventId: window.eventId, input: dataToSubmit },
      });
      window.location.replace(`/events/tableEdit/${window.eventId}/${result.data.createTable.id}`);
      return;
    }

    await updateTable({
      variables: {
        eventId: window.eventId,
        tableId: parseInt(window.tableId),
        input: {
          ...dataToSubmit,
        },
      },
    });
  };

  const initialValues = useMemo(() => {
    if (!window.tableId) return { seatCount: 10 };
    if (!data?.getTable) return {};

    const table = { ...data?.getTable };

    table.seating = [];
    for (const index in table.attendees) {
      const attendee = table.attendees[index];
      if (attendee.seat === undefined) continue;
      table.seating[attendee.seat] = attendee?.internalId?.toString();
    }

    return table;
  }, [data?.getTable, data?.getTable?.attendees]);

  const renderSeats = (values) => {
    const elements = [];
    for (let i = 1; i <= values.seatCount; i++) {
      elements.push(
        <Col xs={6}>
          <FormField
            name={`seating.${i}`}
            title={`Sitzplatz ${i}`}
            type="select"
            options={getOptions(values, i)}
            noDefaultOption
            labelSize={2}
            inputSize={10}
          />
        </Col>,
      );
    }
    return elements;
  };

  const onAttendeeTableAdd = (newAttendees) => {
    const attendees = [...formRef.current?.getState().values.attendees || [], ...newAttendees];
    formRef.current?.change('attendees', attendees);
    setShowModal(false);
  };

  if (!isNew && loading) return (<h1>Loading...</h1>);

  return (
    <>
      <PageHeader>{t(window.tableId ? 'tables.editPage.titleEdit' : 'tables.editPage.title', { name: data?.getTable?.name })}</PageHeader>
      <FinalForm
        ref={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, form }) => {
          formRef.current = form;
          return (
            <Form onSubmit={handleSubmit} horizontal>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                <ActionButton onClick={() => window.location.replace(`/events/tables/${window.eventId}`)}>{t('general.back')}</ActionButton>
                <ActionButton bsStyle="primary" onClick={handleSubmit}>
                  {t('general.save')}
                </ActionButton>
              </div>
              <Row>
                <Col xs={12}>
                  <FormPanel title="Stammdaten" fields={fields} />
                </Col>
              </Row>
              {!isNew && (
                <>
                  <Row>
                    <Col xs={12}>
                      <Panel>
                        <Panel.Heading>
                          <Panel.Title componentClass="h3">{t('tables.editPage.attendees')}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                          <BootstrapTable
                            keyField="id"
                            data={values?.attendees || []}
                            columns={attendeesColumn}
                            striped
                            bordered={false}
                            noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
                          />
                          <Button onClick={() => setShowModal(true)}>Teilnehmer hinzufügen</Button>
                        </Panel.Body>
                      </Panel>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Panel>
                        <Panel.Heading>
                          <Panel.Title componentClass="h3">{t('tables.editPage.seatAssignment')}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                          <Row>
                            <Col xs={12} style={{ textAlign: 'center', marginBottom: 30 }}>
                              <img src={values.seatCount === 10 ? '/img/seating_10.jpg' : '/img/seating_12.jpg'} alt="" style={{ width: 250 }} />
                            </Col>
                          </Row>
                          <Row>
                            {renderSeats(values)}
                          </Row>
                        </Panel.Body>
                      </Panel>
                    </Col>
                  </Row>
                </>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                <Button bsStyle="primary" onClick={handleSubmit}>
                  {t('general.save')}
                </Button>
              </div>
              <AddTableAttendee
                show={showModal}
                onHide={() => setShowModal(false)}
                onAdd={onAttendeeTableAdd}
                selectedAttendees={values?.attendees}
              />
            </Form>
          );
        }}
      />
    </>
  );
}

const ActionButton = styled(Button)`
  margin-left: 5px;
`;
