/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getHostsStatus = gql`
  query hostStatus($eventId: ID!) {
    hostStatus (eventId: $eventId, filter: $filter)
      @rest(type: "[hostStatus]", path: "events/{args.eventId}/hostStatus") {
        updateSeq
        hostStatus {
          host
          lastPingDate
          subeventName
          currentUpdateSeq
        }
    }
  }
`;

export const getSubeventsGql = gql`
  query subevents($eventId: ID!) {
    getSubevents (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/subevents") {
      subevents {
        id
        name
        place
        start
        end
        type
        settings
        allowAllTicketCategories
        allowedTicketCategories
        barcode
      }
    }
  }
`;
