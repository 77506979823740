/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getCurrentActiveRfidAttendees = gql`
  query currentActiveRfidAttendees($eventId: ID!, $filter: String) {
    currentActiveRfidAttendees (eventId: $eventId, filter: $filter)
      @rest(type: "[CurrentActiveRfidAttendees]", path: "events/{args.eventId}/rfidAttendees?filter={args.filter}") {
        currentActiveRfidAttendees {
          id
          email
          forename
          name
          antennaId
          firstSeenDate
          lastSeenDate
          timeSpent
          subeventId
        }
    }
  }
`;

export const getSubeventsGql = gql`
  query subevents($eventId: ID!) {
    getSubevents (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/subevents") {
      subevents {
        id
        name
        place
        start
        end
        type
        settings
        allowAllTicketCategories
        allowedTicketCategories
      }
    }
  }
`;
