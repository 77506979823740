/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getHostsStatus = gql`
  query hostStatus($eventId: ID!) {
    hostStatus (eventId: $eventId, filter: $filter)
      @rest(type: "[hostStatus]", path: "events/{args.eventId}/hostStatus") {
        updateSeq
        hostStatus {
          host
          lastPingDate
          subeventName
          currentUpdateSeq
        }
    }
  }
`;

export const getSubeventsGql = gql`
  query subevents($eventId: ID!) {
    getSubevents (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/subevents") {
      subevents {
        id
        name
        place
        start
        end
        type
        settings
        allowAllTicketCategories
        allowedTicketCategories
        barcode
      }
    }
  }
`;

export const getEventFeaturesGql = gql`
  query features($eventId: ID!) {
    getEventFeatures (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/features") {
      features
    }
  }
`;

export const addEventFeatureGql = gql`
  mutation addEventFeature($eventId: ID!,  $input: PublishablePostInput!) {
    addEventFeature (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/features",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteEventFeatureGql = gql`
  mutation deleteEventFeature($eventId: ID!, $feature: String!, $input: PublishablePostInput!) {
    deleteEventFeature (eventId: $eventId, feature: $feature, input: $input) @rest(
      path: "events/{args.eventId}/features/{args.feature}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
