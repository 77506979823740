/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Color } from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold, faItalic, faUnderline, faStrikethrough, faListUl, faListOl, faQuoteLeft, /* faUndo, faRedo, */
  faAlignLeft, faAlignCenter, faAlignRight, faLink, faImage,
} from '@fortawesome/free-solid-svg-icons';

function MenuBar({ editor }) {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) return;

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run();
  }, [editor]);

  const addImage = useCallback(() => {
    const url = window.prompt('URL');

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="menuBar">
      <div>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faUnderline} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
        <input
          type="color"
          onInput={(event) => editor.chain().focus().setColor(event.target.value).run()}
          value={editor.getAttributes('textStyle').color}
          data-testid="setColor"
        />
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is_active' : ''}
          type="button"
        >
          H1
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is_active' : ''}
          type="button"
        >
          H2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is_active' : ''}
          type="button"
        >
          H3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is_active' : ''}
          type="button"
        >
          H4
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is_active' : ''}
          type="button"
        >
          H5
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is_active' : ''}
          type="button"
        >
          H6
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faListUl} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faListOl} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is_active' : ''}
          type="button"
        >
          <FontAwesomeIcon icon={faQuoteLeft} />
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className={editor.isActive('blockquote') ? 'is_active' : ''}
          type="button"
          title="Horizontale Linie"
        >
          ⎯
        </button>
        <button
          onClick={setLink}
          className={editor.isActive('link') ? 'is_active' : ''}
          type="button"
          title="Link"
        >
          <FontAwesomeIcon icon={faLink} />
        </button>
        <button
          onClick={addImage}
          className={editor.isActive('link') ? 'is_active' : ''}
          type="button"
          title="Bild"
        >
          <FontAwesomeIcon icon={faImage} />
        </button>
      </div>
      {/* <div>
        <button onClick={() => editor.chain().focus().undo().run()} type="button">
          <FontAwesomeIcon icon={faUndo} />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()} type="button">
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </div> */}
    </div>
  );
}

export default function TipTapEdior({ value, onChange }) {
  const editor = useEditor({
    content: value,
    editorProps: {
      attributes: {
        class: 'textarea',
      },
    },
    onUpdate() {
      onChange(editor?.getHTML() ?? '');
    },
    extensions: [
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Color,
      TextStyle,
      Link,
      Image,
    ],
  });

  return (
    <EditorDiv>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </EditorDiv>
  );
}

const EditorDiv = styled.div`
  border-radius: 5px;
  border: 1px solid grey;
  height: 350px;

  .menuBar {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menuBar button {
    font-size: 18px;
    margin: 7px;
    margin-right: 15px;
    outline: none;
    border: none;
    background: none;
    color: rgb(70, 70, 70);
    cursor: pointer;
  }
  .menuBar button:last-child {
    margin-right: 7px;
  }

  .textarea {
    outline: none;
    padding: 5px 15px;
    height: 250px;
    overflow: auto;
  }
`;
