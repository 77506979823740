import { gql } from '@apollo/client';

export const getInviteAttributesGql = gql`
  query inviteAttributes($organizerId: ID!) {
    getInviteAttributes (organizerId: $organizerId)
      @rest(type: "[InviteAttribute]", path: "organizers/{args.organizerId}/inviteAttributes") {
        inviteAttributes {
          code
          name
          type
          defaultValue
        }
    }
  }
`;

export const getInviteCategoriesGql = gql`
  query inviteCategories($eventId: ID!) {
    getInviteCategories (eventId: $eventId)
      @rest(type: "[InviteForm]", path: "events/{args.eventId}/inviteCategories") {
        inviteCategories {
          id
          name
        }
    }
  }
`;

export const getInviteMetaDataGql = gql`
  query inviteCategories($eventId: ID!, $settingKeys: String!) {
    getInviteCategories (eventId: $eventId)
      @rest(type: "[InviteForm]", path: "events/{args.eventId}/inviteCategories") {
        inviteCategories {
          id
          name
        }
    }
    getSettings (eventId: $eventId, settingKeys: $settingKeys)
      @rest(type: "[Settings]", path: "events/{args.eventId}/settings?keys={args.settingKeys}") {
      attendeeDefaultPageLimit
    }
  }
`;

export const getInvitesGql = gql`
  query invites($eventId: ID!, $limit: Int!, $offset: Int!, $filter: Params) {
    getInvites (eventId: $eventId, limit: $limit, offset: $offset, filter: $filter)
      @rest(type: "[Invite]", path: "events/{args.eventId}/invites?limit={args.limit}&offset={args.offset}&filter={args.filter}") {
        meta {
          total
        }
        invites {
          id
          title
          forename
          name
          email
          company
          token
          maxCustomerCount
          usedCount
          mailSent
          status
          customAttributes
          url
          inviteCategory {
            id
            name
          }
        }
    }
  }
`;

export const getSingleInviteGql = gql`
  query invite($eventId: ID!, $inviteId: ID!) {
    getInvite (eventId: $eventId, inviteId: $inviteId)
      @rest(type: "[Invite]", path: "events/{args.eventId}/invites/{args.inviteId}") {
        id
        title
        forename
        name
        email
        company
        token
        maxCustomerCount
        usedCount
        mailSent
        status
        url
        inviteCategory {
          id
        }
        customAttributes
        attendees {
          id
          forename
          name
          email
          company
          customAttributes
        }
    }
  }
`;

export const generateInvitesGql = gql`
  mutation generateInvites($eventId: ID!, $input: PublishablePostInput!) {
    generateInvites (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/generateInvites",
      method: "POST"
    ) { NoResponse }
  }
`;

export const createInviteGql = gql`
  mutation createInvite($eventId: ID!, $input: PublishablePostInput!) {
    createInvite (eventId: $eventId,  input: $input) @rest(
      path: "events/{args.eventId}/invites",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateInviteGql = gql`
  mutation updateInvite($eventId: ID!, $inviteId: ID!  $input: PublishablePostInput!) {
    updateInvite (eventId: $eventId, inviteId: $inviteId, input: $input) @rest(
      path: "events/{args.eventId}/invites/{args.inviteId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteInviteGql = gql`
  mutation deleteInvite($eventId: ID!, $inviteId: Int!) {
    deleteInvite (eventId: $eventId, inviteId: $inviteId) @rest(
      path: "events/{args.eventId}/invites/{args.inviteId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;

export const uploadXlsxMutation = gql`
  mutation uploadXlsx($eventId: ID!, $input: File!) {
    uploadXlsx (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/invites/imports/xlsx",
      type: "File"
      bodySerializer: "fileEncode"
      method: "POST"
    ) { 
      hasErrors
      errors
      overallCounts
     }
  }
`;

export const sendAllMailsGql = gql`
  mutation sendAllMails($eventId: ID!) {
    sendAllMails (eventId: $eventId) @rest(
      path: "events/{args.eventId}/invites/sendMail",
    ) { NoResponse }
  }
`;

export const sendSingleMailGql = gql`
  mutation sendSingleMail($eventId: ID!, $inviteId: ID!) {
    sendSingleMail (eventId: $eventId, inviteId: $inviteId) @rest(
      path: "events/{args.eventId}/invites/sendMail/{args.inviteId}",
    ) { NoResponse }
  }
`;

export const getInviteFormsGql = gql`
  query invites($eventId: ID!) {
    getInviteForms (eventId: $eventId)
      @rest(type: "[InviteForm]", path: "events/{args.eventId}/inviteForms") {
        inviteForms {
          id
          path
          name
          description
          status
          ticketCategoryOnParticipantCreation
        }
    }
  }
`;

export const getSingleInviteFormGql = gql`
  query invite($eventId: ID!, $formId: Number!) {
    getInviteForm (eventId: $eventId, formId: $formId)
      @rest(type: "InviteForm", path: "events/{args.eventId}/inviteForms/{args.formId}") {
        id
        path
        name
        title
        description
        status
        ticketCategoryOnParticipantCreation
    }
  }
`;
