import React, { useMemo } from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import moment from 'moment';
import { useSearchParam } from 'react-use';
import DashboardPanel from './DashboardPanel';
import { t } from '../../../locale';

const iconBySubeventType = {
  checkin: { color: 'green', icon: 'crosshairs' },
  normal: { color: 'info', icon: 'smile-o' },
  special: { color: 'yellow', icon: 'star' },
  ticketUpgrade: { color: 'info', icon: 'refresh' },
};

export default function GeneralStatistics({ statistics }) {
  const jsonParams = useSearchParam('json');
  const subeventByType = useMemo(() => {
    if (!statistics?.presences?.perSubevent.length) return [];
    return statistics?.presences?.perSubevent.reduce((acc, subevent) => {
      if (['roomTracking', 'deviceOutput', 'deviceReturn'].includes(subevent.type)) return acc;
      acc[subevent.type] ||= [];
      acc[subevent.type].push(subevent);
      return acc;
    }, {});
  }, [statistics?.presences?.perSubevent]);

  if (jsonParams) {
    return (
      <div style={{ marginTop: 30 }}>
        <code>{JSON.stringify(statistics, null)}</code>
      </div>
    );
  }
  return (
    <>
      <PageHeader>{t('dashboard.generalTitle')}</PageHeader>
      <Row>
        <Col md={6}>
          <DashboardPanel
            name={t('dashboard.totalParticipantCount')}
            value={statistics?.totalParticipantCount}
            icon="group"
            color="primary"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name={t('dashboard.presenceTotal')}
            value={statistics?.presences?.total}
            icon="child"
            color="green"
          />
        </Col>
      </Row>
      {statistics?.presences?.perDay?.length ? (
        <>
          <PageHeader>{t('dashboard.presenceTotalBySubevent')}</PageHeader>
          <Row>
            {statistics?.presences?.perDay.map((day) => (
              <Col md={4} key={day.date}>
                <DashboardPanel
                  name={moment(day.date).format('dddd DD.MM.YYYY')}
                  value={day.count}
                  icon="child"
                  color="green"
                />
              </Col>
            ))}
          </Row>
        </>
      ) : null}
      {Object.entries(subeventByType).map(([type, subevents]) => (
        <>
          <PageHeader>{t(`dashboard.subeventTypes.${type}`)}</PageHeader>
          <Row>
            {subevents.map((subevent) => (
              <Col md={4} key={subevent.id}>
                <DashboardPanel
                  name={`${t('dashboard.attendee')} - ${subevent.name}`}
                  value={subevent.count}
                  icon={iconBySubeventType[type]?.icon}
                  color={iconBySubeventType[type]?.color}
                />
              </Col>
            ))}
          </Row>
        </>
      ))}
    </>
  );
}
