/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const customerOverviewPageMetaGql = gql`
  query attributes($organizerId: ID!, $eventId: ID!, $settingKeys: String!) {
    getAttributes (organizerId: $organizerId)
      @rest(type: "[Attribute]", path: "organizers/{args.organizerId}/attributes") {
        attributes {
          code
          name
          type
          section
          isVisibleInTable
          sequenceId
          options
          visibility
          settings
        }
    }
    getTicketCategories (organizerId: $organizerId)
      @rest(type: "[TicketCategory]", path: "organizers/{args.organizerId}/ticketCategories") {
        ticketCategories {
          id
          name
          highlightOnScan
          printOnLabel
          settings
        }
    }
    getSubevents (eventId: $eventId) @rest(type: "[Subevent]", path: "events/{args.eventId}/subevents") {
      subevents {
        id
        name
        place
        start
        end
        type
        settings
        allowAllTicketCategories
        allowedTicketCategories
      }
    }
    getSettings (eventId: $eventId, settingKeys: $settingKeys) @rest(type: "[Settings]", path: "events/{args.eventId}/settings?keys={args.settingKeys}") {
      attendeeDefaultPageLimit
    }
  }
`;

export const getAttributesGql = gql`
  query attributes($organizerId: ID!) {
    getAttributes (organizerId: $organizerId)
      @rest(type: "[Attribute]", path: "organizers/{args.organizerId}/attributes") {
        attributes {
          code
          name
          type
          section
          isVisibleInTable
          sequenceId
          options
          visibility
          settings
        }
    }
  }
`;

export const getTicketCategoriesGql = gql`
  query ticketCategories($organizerId: ID!) {
    getTicketCategories (organizerId: $organizerId)
      @rest(type: "[TicketCategory]", path: "organizers/{args.organizerId}/ticketCategories") {
        ticketCategories {
          id
          name
          highlightOnScan
          printOnLabel
          settings
        }
    }
  }
`;

export const getCustomersGql = gql`
  query customers($eventId: ID!, $limit: Int = 100, $offset: Int = 0, $searchTerm: String = "", $filter: Params = {}, $sort: String = "", $fields: String = "") {
    getCustomers (eventId: $eventId, limit: $limit, offset: $offset, searchTerm: $searchTerm, filter: $filter, sort: $sort, fields: $fields)
      @rest(
        type: "[Customer]",
        path: "events/{args.eventId}/participants?limit={args.limit}&offset={args.offset}&query={args.searchTerm}&includeInternalId=true&filter={args.filter}&sort={args.sort}&fields={args.fields}"
      ) {
      participants {
        id
        internalId
        email
        company
        title
        forename
        name
        invoiceNumber
        ticketCategory
        customAttributes
        generalInfo
        linkedCustomer
        invites
        eventTable
        registerDate
      }
      meta {
        total
      }
    }
  }
`;

export const getDuplicatedCustomersGql = gql`
  query customers($eventId: ID!, $limit: Int = 100, $offset: Int = 0, $searchTerm: String = "", $filter: Params = {}, $fields: String = "") {
    getDuplicatedCustomers (eventId: $eventId, limit: $limit, offset: $offset, searchTerm: $searchTerm, filter: $filter, fields: $fields)
      @rest(
        type: "[Customer]",
        path: "events/{args.eventId}/participants/duplicates?limit={args.limit}&offset={args.offset}&query={args.searchTerm}&includeInternalId=true&filter={args.filter}&fields={args.fields}"
      ) {
      participants {
        id
        internalId
        email
        title
        forename
        name
        invoiceNumber
        ticketCategory
        customAttributes
        generalInfo
        linkedCustomer
        invites
        eventTable
      }
      meta {
        total
      }
    }
  }
`;

export const getCustomerGroupsGql = gql`
  query customerGroups($eventId: ID!, $filter: Params = {}) {
    customerGroups (eventId: $eventId, filter: $filter)
      @rest(
        type: "customerGroup",
        path: "events/{args.eventId}/participants/groups?filter={args.filter}"
      ) {
        participantGroups {
          groupId
          eventTable
          participants
        }
    }
  }
`;

export const getSingleCustomer = gql`
  query customer($eventId: ID!, $id: ID!) {
    getCustomer (eventId: $eventId, id: $id)
      @rest(
        type: "Customer",
        path: "events/{args.eventId}/participants/{args.id}"
      ) {
      id
      email
      title
      forename
      name
      company
      invoiceNumber
      customAttributes
      ticketCategory { id }
      presences
      invites
      eventTable
      devices
      participantsCrossEvent
    }
  }
`;

export const getCustomerAudit = gql`
  query customerAudit($eventId: ID!, $id: ID!) {
    getCustomerAudit (eventId: $eventId, id: $id)
      @rest(
        type: "Customer",
        path: "events/{args.eventId}/audit?participantId={args.id}"
      ) {
        auditLogs {
          customer
          type
          created
          changedBy
          old
          new
        }
    }
  }
`;

export const getCustomerEmailLogs = gql`
  query customerEmailLogs($eventId: ID!, $id: ID!) {
    getCustomerEmailLogs (eventId: $eventId, id: $id)
      @rest(
        type: "CustomerEmailLogs",
        path: "events/{args.eventId}/participants/{args.id}/emailLogs"
      ) {
      id
      type
      email
      subject
      textMessage
      htmlMessage
      created
    }
  }
`;

export const sendMailGql = gql`
  mutation sendMail($eventId: ID!, $customerId: ID!  $input: PublishablePostInput!) {
    sendMail (eventId: $eventId, customerId: $customerId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/sendMail/invite",
      method: "POST"
    ) { NoResponse }
  }
`;

export const sendTicketGql = gql`
  mutation sendTicketGql($eventId: ID!, $customerId: String!) {
    sendTicketGql (eventId: $eventId, customerId: $customerId, input: {})
    @rest(type: "sendTicket", path: "events/{args.eventId}/participants/{args.customerId}/sendEmail", method: "POST") {
      id
    }
  }
`;

export const createCustomerGql = gql`
  mutation createCustomer($eventId: ID!, $input: PublishablePostInput!) {
    createCustomer (eventId: $eventId,  input: $input) @rest(
      path: "events/{args.eventId}/participants",
      method: "POST"
    ) {
      barcode
      customerId
    }
  }
`;

export const updateCustomerGql = gql`
  mutation updateCustomer($eventId: ID!, $customerId: ID!  $input: PublishablePostInput!) {
    updateCustomer (eventId: $eventId, customerId: $customerId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteCustomerGql = gql`
  mutation deleteCustomer($eventId: ID!, $customerId: Int!) {
    deleteCustomer (eventId: $eventId, customerId: $customerId) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;

export const returnCustomerDeviceGql = gql`
  mutation returnCustomerDevice($eventId: ID!, $customerId: Int!, $deviceBarcode: String!, $input: PublishablePostInput!) {
    returnCustomerDevice (eventId: $eventId, customerId: $customerId, deviceBarcode: $deviceBarcode, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/devices/{args.deviceBarcode}/return",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteCustomerDeviceGql = gql`
  mutation deleteCustomerDevice($eventId: ID!, $customerId: Int!, $deviceBarcode: String!) {
    deleteCustomer (eventId: $eventId, customerId: $customerId, deviceBarcode: $deviceBarcode) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/devices/{args.deviceBarcode}",
      method: "DELETE"
    ) { NoResponse }
  }
`;

export const mergeCustomerGql = gql`
  mutation mergeCustomer($eventId: ID!, $customerId: Int!, $input: EmptyInput!) {
    mergeCustomer (eventId: $eventId, customerId: $customerId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/merge",
      method: "POST"
    ) { NoResponse }
  }
`;

export const uploadXlsxMutation = gql`
  mutation uploadXlsx($eventId: ID!, $input: File!) {
    uploadXlsx (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/imports/xlsx",
      type: "File"
      bodySerializer: "fileEncode"
      method: "POST"
    ) { 
      errors {
        lineNumber
        errors
      }
      insertedTicketCount
      updatedTicketCount
      ticketsWithErrorCount
      skippedTicketCount
      ticketCount
     }
  }
`;

export const uploadElopageCsvMutation = gql`
  mutation uploadXlsx($eventId: ID!, $input: File!) {
    uploadXlsx (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/imports/elopageCsv",
      type: "File"
      bodySerializer: "fileEncode"
      method: "POST"
    ) { 
      errors
      insertedTicketCount
      updatedTicketCount
      ticketsWithErrorCount
      skippedTicketCount
      ticketCount
     }
  }
`;

export const uploadDigistoreCsvMutation = gql`
  mutation uploadXlsx($eventId: ID!, $input: File!) {
    uploadXlsx (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/imports/digistoreCsv",
      type: "File"
      bodySerializer: "fileEncode"
      method: "POST"
    ) { 
      errors
      insertedTicketCount
      updatedTicketCount
      ticketsWithErrorCount
      skippedTicketCount
      ticketCount
     }
  }
`;

export const getTablesGql = gql`
  query tables($eventId: ID!, $filter: Filter!) {
    getTables (eventId: $eventId, filter: $filter) @rest(type: "[Table]", path: "events/{args.eventId}/tables?filter={args.filter}") {
      meta {
        total
      }
      tables {
        id
        name
        tableNumber
        seatCount
        status
        attendees
      }
    }
  }
`;

export const getTitles = gql`
  query titles($eventId: ID!) {
    getTitles (eventId: $eventId) @rest(type: "[Title]", path: "events/{args.eventId}/titles") {
      code
      name
      salutatoryAddress
      addressTitle
    }
  }
`;

export const addDeviceGql = gql`
  mutation addDevice($eventId: ID!, $customerId: ID!  $input: PublishablePostInput!) {
    addDevice (eventId: $eventId, customerId: $customerId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/devices",
      method: "POST"
    ) { NoResponse }
  }
`;

export const addPresenceGql = gql`
  mutation addPresence($eventId: ID!, $customerId: ID!  $input: PublishablePostInput!) {
    addDevice (eventId: $eventId, customerId: $customerId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/presences",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deletePresenceGql = gql`
  mutation deletePresence($eventId: ID!, $customerId: ID!, $subeventId: ID! $input: PublishablePostInput!) {
    addDevice (eventId: $eventId, customerId: $customerId, subeventId: $subeventId, input: $input) @rest(
      path: "events/{args.eventId}/participants/{args.customerId}/presences/{args.subeventId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
