import React from 'react';
import { Col, Panel } from 'react-bootstrap';
import FormField from '../../../components/FormField';

export default function FormPanel({ fields, title }) {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h3">{title || ''}</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {fields.map((field) => (
          <Col md={6} xs={12} key={field.name} {...(field?.grid || {})}>
            <FormField
              name={field.name}
              title={field.label}
              required={field.required}
              type={field.type}
              options={field.options}
              disabled={field.disabled}
              labelSize={2}
              inputSize={10}
            />
          </Col>
        ))}
      </Panel.Body>
    </Panel>
  );
}
