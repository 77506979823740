import { t } from '../locale';

export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

export const minValueValidator = (min) =>
  (value) => (Number.isNaN(value) || value >= min ? undefined : t('invites.errors.minValueError', { value: min }));

export const isRequired = (value) => (value ? undefined : t('general.errors.required'));

export const isValidEmail = () => (value) =>
  (!value || value.toLowerCase()
    // eslint-disable-next-line max-len
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ? undefined
    : t('invites.errors.emailError'));
