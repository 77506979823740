import React, { useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../locale';
import { getAttributesGql, getSingleCustomer } from '../gql';
import FormField from '../../../components/FormField';

function PaymentEditDialog({ show, onHide, modalData, onSubmit, loading }) {
  const isNew = !modalData;

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: customerResult } = useQuery(getSingleCustomer, {
    fetchPolicy: 'no-cache',
    skip: isNew || !modalData?.id,
    variables: {
      eventId: window.eventId,
      id: modalData?.id,
    },
  });

  const customer = useMemo(() => (
    customerResult?.getCustomer || {}
  ), [customerResult?.getCustomer]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
      // style={{ width: '80%' }}
    >
      <FinalForm
        initialValues={customer}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {t('customersPayment.editPayment', customer)}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {
              attributeResult?.getAttributes?.attributes
                .filter(({ section }) => section === 'Spende / Beitrag')
                .map((attribute) => (
                  <FormField
                    name={`customAttributes.${attribute.code}`}
                    title={attribute.name}
                    labelSize={2}
                    inputSize={10}
                    type={attribute.type}
                  />
                ))
              }
            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {loading
                  ? <FontAwesomeIcon icon={faCircleNotch} spin />
                  : t('general.save')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>

          </Form>
        )}
      />
    </Modal>
  );
}

export default PaymentEditDialog;
