import React from 'react';
import { ClipLoader } from 'react-spinners';

export default function LoadingSpinner() {
  return (
    <div style={{ textAlign: 'center', marginTop: 30 }}>
      <ClipLoader />
    </div>
  );
}
