/* eslint-disable max-len */
import { gql } from '@apollo/client';

export const getOrdersGql = gql`
  query orders($eventId: ID!, $filter: Filter!, $limit: Int = 100, $offset: Int = 0, $searchTerm: String = "") {
    getOrders (eventId: $eventId, filter: $filter, limit: $limit, offset: $offset, searchTerm: $searchTerm) @rest(type: "[Order]", path: "events/{args.eventId}/ecommerce/orders?limit={args.limit}&offset={args.offset}&searchTerm={args.searchTerm}&filter={args.filter}") {
      meta {
        total
      }
      orders {
        id
        invoiceNumber
        orderNumber
        total
        submitDate
        status
        payment
        seatCount
        status
        paymentStatus
        invoiceAddress
      }
    }
  }
`;

export const getOrderGql = gql`
  query order($eventId: ID!, $orderId: Int) {
    getOrder (eventId: $eventId, orderId: $orderId) @rest(type: "Order", path: "events/{args.eventId}/ecommerce/orders/{args.orderId}") {
      order
    }
  }
`;

export const getProductsGql = gql`
  query products($eventId: ID!) {
    getProducts (eventId: $eventId) @rest(type: "Order", path: "events/{args.eventId}/ecommerce/products") {
      products
    }
  }
`;

export const addProductGql = gql`
  mutation addProduct($eventId: ID!, $input: PublishablePostInput!) {
    addProduct (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/products",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateProductGql = gql`
  mutation updateProduct($eventId: ID!, $productId: Int!, $input: PublishablePostInput!) {
    updateProduct (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/products/{args.productId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteProductGql = gql`
  mutation deleteProduct($eventId: ID!, $productId: Int!, $input: PublishablePostInput!) {
    deleteProduct (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/products/{args.productId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;

export const getDetailsGql = gql`
  query getDetails($eventId: ID!) {
    getDetails (eventId: $eventId) @rest(type: "Order", path: "events/{args.eventId}/ecommerce/details") {
      event
    }
  }
`;

export const getDetailsAndTextGql = gql`
  query getDetails($eventId: ID!) {
    getDetails (eventId: $eventId) @rest(type: "Order", path: "events/{args.eventId}/ecommerce/details") {
      event
    }
    getTexts (eventId: $eventId) @rest(type: "Order", path: "events/{args.eventId}/ecommerce/texts") {
      texts
    }
  }
`;

export const getCredentials = gql`
  query getCredentials($eventId: ID!) {
    getCredentials (eventId: $eventId) @rest(type: "Credential", path: "events/{args.eventId}/ecommerce/credentials") {
      credentials
    }
  }
`;

export const updateCredentialsGql = gql`
  mutation updateCredentials($eventId: ID!, $input: PublishablePostInput!) {
    updateCredentials (eventId: $eventId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/credentials",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateDetailsGql = gql`
  mutation updateDetails($eventId: ID!, $input: PublishablePostInput!) {
    updateProduct (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/details",
      method: "POST"
    ) { NoResponse }
  }
`;

export const updateDetailsAndTextsGql = gql`
  mutation updateDetailsAndTexts($eventId: ID!, $input: PublishablePostInput!, $inputTexts: PublishablePostInput!) {
    updateProduct (eventId: $eventId, productId: $productId, input: $input) @rest(
      path: "events/{args.eventId}/ecommerce/details",
      method: "POST"
    ) { NoResponse }
    setTexts (eventId: $eventId, input: $inputTexts) @rest(
      path: "events/{args.eventId}/ecommerce/texts",
      method: "POST"
    ) { NoResponse }
  }
`;
