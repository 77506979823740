import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import deLocale from './de';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV === 'development',
    lng: 'de',
    resources: {
      de: { translation: deLocale },
    },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export const { t } = i18n;

export default i18n;
