import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { getDeviceStatisticsGql } from '../gql';
import DashboardPanel from './DashboardPanel';

export default function DeviceStatistics() {
  const hasFeature = window.eventFeatures && window.eventFeatures.includes('devices');
  const { data } = useQuery(getDeviceStatisticsGql, {
    skip: !hasFeature,
    variables: { eventId: window.eventId },
  });

  if (!hasFeature) return null;
  return (
    <>
      <PageHeader>Geräte</PageHeader>
      <Row>
        <Col md={6}>
          <DashboardPanel
            name="Ausgegebene Geräte"
            value={data?.getDeviceStatistics?.outputDeviceCount}
            icon="headphones"
            color="green"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Nicht zurückgegebene Geräte"
            value={data?.getDeviceStatistics?.notReturnedDeviceCount}
            icon="headphones"
            color="yellow"
            link={`/events/devices/${window.eventId}`}
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Zurückgegebene Geräte"
            value={data?.getDeviceStatistics?.returnedDeviceCount}
            icon="headphones"
            color="green"
          />
        </Col>
        <Col md={6}>
          <DashboardPanel
            name="Ersetzte Geräte"
            value={data?.getDeviceStatistics?.replacedDeviceCount}
            icon="headphones"
            /* color="red" */
          />
        </Col>
      </Row>
    </>
  );
}
