import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, ControlLabel, /* DropdownButton, MenuItem, */ Form, FormControl, FormGroup, Glyphicon, PageHeader,
} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useDebounce, useSearchParam, useSessionStorage } from 'react-use';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ConfirmModal from '../../components/ConfirmModal';
import toastHelper from '../../lib/toastHelper';
import InviteEditDialog from './components/InviteEditDialog';
import GenerateInvitesDialog from './components/GenerateInvitesDialog';
import UploadModal from '../../components/UploadModal';
import { t } from '../../locale';
import {
  generateInvitesGql,
  uploadXlsxMutation,
  /* sendAllMailsGql,
  sendSingleMailGql, */
  getInviteMetaDataGql,
} from './gql';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import StatusIcon from './components/StatusIcon';
import GuestTable from './components/GuestTable';
import { createCustomerGql, deleteCustomerGql, getCustomersGql, updateCustomerGql } from '../customers/gql';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../components/AdvancedBootstapTable';
import DeleteConfirmModal from './components/DeleteConfirmModal';

export default function InvitesPage() {
  const [selectedAttendee, setSelectedInvite] = useState(null);
  const [xlsx, setXlsx] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showXlsxUploadModal, setShowXlsxUploadModal] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState({});
  const [confirmData/* , setConfirmData */] = useState({});
  const [sizePerPage, setSizePerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`inviteFilter_${window.eventId}`, '');
  const [filter, setFilter] = useState([]);
  const [page, setPage] = useState(1);
  const filterQueryString = useSearchParam('filter');

  const { data: metaResult, loading: metaResultLoading } = useQuery(getInviteMetaDataGql, {
    variables: { eventId: window.eventId, settingKeys: ['attendeeDefaultPageLimit'].join(',') },
  });

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    if (!metaResult?.getSettings?.attendeeDefaultPageLimit) return;
    if (sizePerPage !== 25) return;
    setSizePerPage(parseInt(metaResult?.getSettings?.attendeeDefaultPageLimit));
  }, [metaResult?.getSettings?.attendeeDefaultPageLimit]);

  useEffect(() => {
    if (filterQueryString) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const filterToSend = useMemo(() => {
    const filterToSend = { $and: [{ 'invite.id': { $has: true }, 'invite.type': 'main' }, ...filter] };
    if (debouncedSearchTerm) {
      filterToSend.$and.push({
        $or: [
          { fullName: { $ct: debouncedSearchTerm?.trim() } },
          { email: { $ct: debouncedSearchTerm?.trim() } },
          { company: { $ct: debouncedSearchTerm?.trim() } },
        ],
      });
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [debouncedSearchTerm, filter]);

  const { data: { getCustomers: { participants, meta } = { participants: [], meta: {} } } = {}, refetch, loading } = useQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page * sizePerPage) - sizePerPage,
      filter: filterToSend,
      searchTerm: '',
      sort: '',
      fields: 'invites',
    },
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.getInviteError404'));
      return toastHelper.error(t('invites.errors.getInviteError'));
    },
  });

  const mappedParticipants = useMemo(() => (
    participants.map((participant) => {
      if (!participant.invites.length) return participant;
      // eslint-disable-next-line no-param-reassign
      participant.mainInvite = participant.invites.find(({ type }) => type === 'main');
      return participant;
    })
  ), [participants]);

  const [getCustomers] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const [generateInvites] = useMutation(generateInvitesGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.addInviteError404'));
      return toastHelper.error(t('invites.errors.addInviteError'));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('invites.createInvitesDialog.success')); },
  });

  const [createAttendee] = useMutation(createCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.created')),
  });
  const [updateAttendee] = useMutation(updateCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  const [uploadXlsx] = useMutation(uploadXlsxMutation, {
    onCompleted: ({ uploadXlsx }) => {
      toastHelper.success(t('invites.import.success', { ...uploadXlsx.overallCounts }));
      uploadXlsx.errors?.forEach((error) => toastHelper.error(error));
    },
    onError: (err) => {
      if (err.networkError.statusCode === 400 && err.networkError.result.missingFields.length) {
        const missingFields = err.networkError.result.missingFields.map((field) => `"${field}"`).join(', ');
        return toastHelper.error(t('invites.errors.importXlsxError400', { missingFields }));
      }
      return toastHelper.error(t('invites.error.importXlsxError'));
    },
  });

  /* const [sendSingleMail, { loading: sendMailLoading }] = useMutation(sendSingleMailGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.sendInviteError404'));
      return toastHelper.error(t('invites.errors.sendInviteError'));
    },
    onCompleted: () => toastHelper.success(t('invites.mail.sendSingleMail.success')),
  });

  const [sendAllMails, { loading: sendMailsLoading }] = useMutation(sendAllMailsGql, {
    onError: (err) => {
      if (err.networkError.statusCode === 404) return toastHelper.error(t('invites.errors.sendInvitesError404'));
      return toastHelper.error(t('invites.errors.sendInvitesError'));
    },
    onCompleted: () => toastHelper.success(t('invites.mail.sendAllMails.success')),
  }); */

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(deleteCustomerGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });

  const handleCustomerDelete = (customer, cb) => {
    setDeleteConfirmData({
      show: true,
      loading: deleteCustomerLoading,
      customer,
      onClose: () => { setDeleteConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomer({ variables: { eventId: window.eventId, customerId: customer.id } });
        setDeleteConfirmData({});
        if (cb) cb();
      },
    });
  };

  const onGenerateSubmit = async ({ maxCustomerCount, count }) => {
    await generateInvites({
      variables: {
        eventId: window.eventId,
        input: {
          maxCustomerCount,
          count,
        },
      },
    });
    setShowGenerateModal(false);
    refetch();
  };

  const onEditSubmit = async (data) => {
    const { title, email, forename, name, company, customAttributes, invites } = data;
    let result = null;
    if (selectedAttendee) {
      result = await updateAttendee({
        variables: {
          eventId: window.eventId,
          customerId: selectedAttendee.id,
          input: {
            title: title || null,
            email: email || null,
            forename: forename || null,
            name: name || null,
            company: company || null,
            customAttributes: customAttributes || undefined,
            invites,
          },
        },
      });
    } else {
      result = await createAttendee({
        variables: {
          eventId: window.eventId,
          input: {
            participant: {
              title: title || null,
              email: email || null,
              forename: forename || null,
              name: name || null,
              company: company || null,
              customAttributes: customAttributes || undefined,
              invites,
            },
          },
        },
      });
    }

    if (!result.errors) {
      setSelectedInvite(null);
      setShowEditModal(false);
    }
    return refetch();
  };

  const handleEditInvite = (invite = null) => {
    setSelectedInvite(invite);
    setShowEditModal(true);
  };

  /* const onSendMailConfirm = async (invite) => {
    await sendSingleMail({ variables: { eventId: window.eventId, inviteId: invite.id } });
    setConfirmData({});
  };

  const onSendMailsConfirm = async () => {
    await sendAllMails({ variables: { eventId: window.eventId } });
    setConfirmData({});
  }; */

  /* const handleSendMail = async (invite) => {
    setConfirmData({
      invite,
      show: true,
      title: t('invites.mail.sendSingleMail.confirm.title'),
      text: t('invites.mail.sendSingleMail.confirm.text', { email: invite.email }),
      loading: sendMailLoading,
      confirmBsStyle: 'info',
      confirmMessage: t('invites.mail.sendSingleMail.confirm.send'),
      onConfirm: () => onSendMailConfirm(invite),
      onClose: () => { setConfirmData({}); },
    });
  };

  const handleSendMails = async () => {
    setConfirmData({
      show: true,
      title: t('invites.mail.sendAllMails.confirm.title'),
      text: t('invites.mail.sendAllMails.confirm.text'),
      loading: sendMailsLoading,
      confirmBsStyle: 'info',
      confirmMessage: t('invites.mail.sendAllMails.confirm.send'),
      onConfirm: () => onSendMailsConfirm(),
      onClose: () => { setConfirmData({}); },
    });
  }; */

  /* const handleUploadXlsc = () => {
    setShowXlsxUploadModal(true);
  }; */
  const onUploadXlsxConfirm = async () => {
    await uploadXlsx({ variables: { eventId: window.eventId, input: xlsx } });
    setShowXlsxUploadModal(false);
    setXlsx(null);
    refetch();
  };

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'mainInvite.inviteCategory.name') {
          newFilter.push({ 'invite.inviteCategory.id': valObj.filterVal });
          continue;
        }
        if (name === 'invite.status') {
          newFilter.push({ 'invite.status': valObj.filterVal });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setFilter(newFilter);
    }
  };

  const categoryFilterOptions = useMemo(() => {
    if (!metaResult?.getInviteCategories?.inviteCategories) return [];
    return metaResult?.getInviteCategories?.inviteCategories.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [metaResult?.getInviteCategories?.inviteCategories]);

  const columns = [
    /* {
      text: t('invites.token'),
      dataField: 'invite.token',
      style: () => ({ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
      filter: textFilter({ placeholder: t('invites.table.filterPlaceholder.token') }),
    }, */
    { text: t('invites.forename'), dataField: 'forename', filter: textFilter({ placeholder: t('invites.table.filterPlaceholder.forename') }) },
    { text: t('invites.name'), dataField: 'name', filter: textFilter({ placeholder: t('invites.table.filterPlaceholder.name') }) },
    {
      text: t('invites.company'),
      dataField: 'company',
      filter: textFilter({ placeholder: t('invites.table.filterPlaceholder.company') }),
    },
    {
      text: t('invites.email'),
      dataField: 'email',
      filter: textFilter({ placeholder: t('invites.table.filterPlaceholder.email') }),
    },
    {
      text: t('invites.inviteCategory'),
      dataField: 'mainInvite.inviteCategory.name',
      headerStyle: () => ({ width: '140px' }),
      filter: selectFilter({
        options: [{ value: '', label: t('invites.table.filterPlaceholder.allInviteCategory') }, ...categoryFilterOptions],
        placeholder: t('invites.table.filterPlaceholder.inviteCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    {
      text: 'Status',
      dataField: 'invite.status',
      isDummyField: true,
      formatter: (_, row) => (
        <StatusIcon status={row.mainInvite?.status} row={row} />
      ),
      headerStyle: () => ({ width: '140px' }),
      filter: selectFilter({
        options: {
          '': 'Alle Status',
          created: 'Ausstehend',
          accepted: 'Zusage',
          declined: 'Absage',
        },
        placeholder: t('invites.table.filterPlaceholder.inviteCategory'),
        withoutEmptyOption: true,
        default: '',
      }),
    },
    {
      text: 'Aktion',
      dataField: 'id',
      isDummyField: true,
      formatter: (_, row) => (
        <ButtonGroup>
          {window.eventRole !== 'viewer' && (
            <Button
              bsStyle="primary"
              title={t('general.edit')}
              onClick={() => handleEditInvite(row)}
            >
              <Glyphicon glyph="pencil" />
            </Button>
          )}
          {row.mainInvite?.url && (
            <Button
              bsStyle="info"
              title={t('invites.showInviteForm')}
              href={row.mainInvite?.url}
              target="_blank"
            >
              <Glyphicon glyph="list-alt" />
            </Button>
          )}
          {/* <Button
            bsStyle="info"
            title={t('invites.mail.sendSingleMail.button')}
            disabled={!row.email}
            onClick={() => handleSendMail(row)}
          >
            <Glyphicon glyph="send" />
          </Button> */}
          {window.eventRole !== 'viewer' && (
            <Button
              bsStyle="danger"
              title={t('general.delete')}
              onClick={() => handleCustomerDelete(row)}
            >
              <Glyphicon glyph="remove" />
            </Button>
          )}
        </ButtonGroup>
      ),
    },
  ];

  const expandRow = {
    renderer: (customer) => <GuestTable {...customer} getCustomers={getCustomers} onDelete={handleCustomerDelete} />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => (
      <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={expanded ? faCaretDown : faCaretRight} />
    ),
    expandHeaderColumnRenderer: () => null,
  };

  return (
    <>
      <PageHeader>{t('invites.headline')}</PageHeader>

      <ButtonToolbar style={{ marginBottom: 10 }}>
        {window.eventRole !== 'viewer' && (
          <Button bsStyle="primary" onClick={() => handleEditInvite()}>
            {t('invites.buttons.createSingeInvite')}
          </Button>
        )}
        <Button href={`/api/v1/events/${window.eventId}/participants/export?filter=${filterToSend}&query=${debouncedSearchTerm}`} target="_blank">
          {t('invites.buttons.export')}
        </Button>
        {/* <DropdownButton
          id="createInviteDropdown"
          bsStyle="primary"
          title={t('invites.createInvites')}
        >
          <MenuItem onClick={() => handleEditInvite()}>
            {t('invites.createSingeInvite')}
          </MenuItem>

          <MenuItem onClick={() => setShowGenerateModal(true)}>
            {t('invites.generateInvites')}
          </MenuItem>

          <MenuItem onClick={() => handleUploadXlsc()}>
            {t('invites.importInviteExcel')}
          </MenuItem>
        </DropdownButton> */}

        {/* <Button
          disabled={!invites.length}
          onClick={() => handleSendMails()}
        >
          {t('invites.mail.sendAllMails.button')}
        </Button> */}

      </ButtonToolbar>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <AdvancedBootstapTable
        remote
        keyField="id"
        columns={columns}
        loading={loading || metaResultLoading}
        data={mappedParticipants}
        striped
        bordered={false}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta.total || 0,
          showTotal: true,
          sizePerPageList: [10, 25, 50, 100],
          paginationTotalRenderer,
          sizePerPageRenderer,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        expandRow={expandRow}
        filterPersistKey={`inviteTableFilter_${window.eventId}`}
      />
      <InviteEditDialog
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
          setSelectedInvite(null);
        }}
        modalData={selectedAttendee}
        onSubmit={onEditSubmit}
      />

      <GenerateInvitesDialog
        show={showGenerateModal}
        onHide={() => setShowGenerateModal(false)}
        onSubmit={onGenerateSubmit}
      />

      <UploadModal
        show={showXlsxUploadModal}
        title={t('invites.import.title')}
        text={t('invites.import.text')}
        loading={null}
        confirmMessage={t('general.import')}
        onConfirm={onUploadXlsxConfirm}
        onClose={() => { setShowXlsxUploadModal(false); setXlsx(null); }}
        setFile={setXlsx}
        file={xlsx}
      />

      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
      />
      <DeleteConfirmModal
        show={deleteConfirmData.show}
        loading={deleteConfirmData.loading}
        onConfirm={deleteConfirmData.onConfirm}
        onClose={deleteConfirmData.onClose}
        customer={deleteConfirmData.customer}
      />
    </>
  );
}
