/* eslint-disable max-len */
const translation = {
  general: {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    close: 'Schließen',
    back: 'Zurück',
    save: 'Speichern',
    send: 'Versenden',
    create: 'Erstellen',
    action: 'Aktion',
    loading: '',
    import: 'Importieren',
    noData: 'Keine Einträge gefunden',
    errors: {
      required: 'Bitte füllen Sie dieses Feld aus',
      generalError: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut',
      error404: 'Nicht gefunden',
    },
    successMessages: {
      deleted: 'Erfolgreich gelöscht!',
      saved: 'Erfolgreich gespeichert!',
      created: 'Erfolgreich erstellt!',
    },
    status: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
    },
    form: {
      all: 'Alle',
      boolean: {
        true: 'Ja',
        false: 'Nein',
      },
    },
    titles: {
      mr: 'Herr',
      mrs: 'Frau',
      mrDr: 'Herr Dr.',
      mrsDr: 'Frau Dr.',
      mrProf: 'Herr Prof.',
      mrsProf: 'Frau Prof.',
    },
  },
  customers: {
    headline: 'Teilnehmerübersicht',
    showTicket: 'Ticket anzeigen',
    checkin: 'Einchecken',
    checkinDialog: {
      title: 'Teilnehmer einchecken',
      text: 'Wollen Sie wirklich den Teilnehmer {{ customer.forename }} {{ customer.name }} einchecken?',
      textAlreadyExists: 'Teilnehmer {{ customer.forename }} {{ customer.name }} wurde bereits am {{ date }} eingecheckt.',
      success: 'Der Teilnehmer wurde erfolgreich eingecheckt',
      checkin: 'Einchecken',
    },
    checkinStatus: {
      notCheckedIn: 'Teilnehmer ist noch nicht eingecheckt',
      checkedIn: 'Teilnehmer wurde am {{ date }} eingecheckt',
    },
    sendEmail: 'E-Mail mit Ticket versenden',
    showEntries: {
      show: 'Zeige',
      entries: 'Einträge',
    },
    filter: {
      applyFilter: 'Filter anwenden',
      reset: 'Filter zurücksetzen',
      headline: 'Teilnehmerfilter',
    },
    search: 'Suche:',
    paginationText: 'Zeige {{ from }}-{{ to }} von {{total}} Einträgen',
    sendTicketDialog: {
      title: 'Ticket-Email',
      text: 'Wollen Sie wirklich eine Mail mit dem personalisierten Ticket an {{ customer.forename }} {{ customer.name }} senden?',
      success: 'Das Ticket wurde erfolgreich per E-Mail versendet',
      send: 'Senden',
    },
    editCustomerDialog: {
      title: {
        create: 'Teilnehmer erstellen',
        edit: 'Teilnehmer bearbeiten',
      },
    },
    errors: {
      sendTicketGeneralError: 'Fehler beim versenden der Ticket E-Mail',
    },
    attendeeSelector: {
      placeholder: 'Bitte einen Teilnehmer auswählen',
      noAttendee: 'Kein Teilnehmer gefunden',
    },
    table: {
      id: 'ID',
      title: 'Anrede',
      forename: 'Vorname',
      name: 'Nachname',
      company: 'Firma',
      email: 'E-Mail',
      table: 'Tisch',
      seat: 'Sitzplatz',
      invoiceNumber: 'Rechnungsnummer',
      created: 'Erstellt am',
      ticketCategory: 'Kategorie',
      ticketCategoryId: 'Kategorie',
      linkedCustomer: 'Gast von',
      registerDate: 'Registriert am',
      filterPlaceholder: {
        email: 'Suche nach E-Mail',
        forename: 'Suche nach Vorname',
        name: 'Suche nach Nachname',
        company: 'Suche nach Firma',
        customerField: 'Suche nach {{ attr.name }}',
        ticketCategory: 'Filter nach Kategorie',
        allCategories: 'Alle Kategorien',
      },
    },
    changes: 'Änderungen',
    buttons: {
      addCustomer: 'Teilnehmer hinzufügen',
      addSingleCustomer: 'Einzelnen Teilnehmer hinzufügen',
      importExcel: 'Excel-List importieren',
      importDigistore: 'Tickets von Digitstore24 importieren',
      importElopage: 'Tickets von Elopage importieren',
      export: 'Teilnehmer exportieren',
      audit: 'Änderungsprotokoll',
    },
    edit: {
      addCustomer: 'Teilnehmer hinzufügen',
      emailLog: {
        title: 'Versendete E-Mails',
        email: 'E-Mail Adresse',
        type: 'Type',
        subject: 'Betreff',
        created: 'Gesendet am',
        viewEmail: 'E-Mail ansehen',
        sentEmail: 'Versendete E-Mail',
        emailButton: {
          invite: 'Einladungsmail erneut versenden',
          confirm: 'Bestätigungsmail erneut versenden',
          ticketSend: 'Ticket versenden',
          ticketResend: 'Ticket erneut versenden',
        },
        emailConfirm: {
          title: 'E-Mail erneut versenden',
          textInvite: 'Wollen Sie wirklich die Einladungsmail erneut versenden?',
          textConfirm: 'Wollen Sie wirklich die Bestätigungsmail erneut versenden?',
          send: 'Versenden',
        },
        typeEnum: {
          customerCreated: 'Anmeldebestätigung',
          invite: 'Einladung',
          ticket: 'Einlasskarte',
          saveTheDate: 'Save The Date',
        },
      },
      crossEvent: {
        title: 'Teilnahme in anderen Events',
        eventName: 'Event Name',
        checkin: 'Anwesenheit',
        viewInEvent: 'Teilnehmer in anderem Event ansehen',
      },
    },
    deleteCustomer: {
      title: 'Teilnehmer löschen',
      text: 'Wollen Sie wirklich den Teilnehmer {{ customer.forename }} {{ customer.name }} löschen?',
      textMultiple: 'Wollen Sie wirklich den Teilnehmer(in) {{ customer.forename }} {{ customer.name }} und seine/ihre Gäste löschen?',
      success: 'Der Teilnehmer wurde erfolgreich gelöscht.',
      delete: 'Löschen',
    },
    import: {
      title: 'Import von einer XLS(X)-Datei für das Event {{ event.name }}',
      text: 'Für einen Import eines Excel-Files muss in der Zeile 1 die Überschriften für "Vorname", "Nachname", "E-Mail", "Rechnungsnummer", "Vollständig?" und "Ticketkategorie" über den passenden Spalten stehen',
      success: '{{ ticketCount }} Teilnehmer wurden gefunden, davon wurden {{ insertedTicketCount }} Teilnehmer importiert, {{ updatedTicketCount }} Teilnehmer aktualisiert, {{ skippedTicketCount }} Teilnehmer übersprungen und {{ ticketsWithErrorCount }} Teilnehmer wiesen einen Fehler auf',
    },
    importDigistore: {
      title: 'Import der CSV (UTF-8) Datei von Digistore für das Event {{ event.name }}',
      text: `<p>Bitte exportieren Sie die CSV-Datei / UTF-8 aus dem Digistore24 Backend. </p>
      <p>Der Export befindet sich unter Berichte --> E-Tickets --> Alle anzeigen. <br/>
      Dort filtern sie nach allen benötigten Tickets und klicken auf den CSV-Export Button und wählen "CSV-Datei / UTF-8" aus.</p>
      `,
    },
    importElopage: {
      title: 'Import von Elopage Tickets für das Event {{ event.name }}',
      text: `<p>Bitte exportieren Sie die CSV-Datei aus dem Elopage Backend</p>
      <p>Der Export befindet sich unter Kunden --> e-Ticket-Bestellungen --> Tab "Details" --> CSV --> Neuen Export generieren.<br/>
      Dort wählen Sie alle Felder aus und klicken auf den Button "CSV-Datei generieren".</p>
      `,
    },
    createAndOutputDevice: 'Erstellen und Gerät ausgeben',
    createAndPrint: 'Erstellen und Label drucken',
    createAndCheckin: 'Erstellen und Anwesenheit hinzufügen',
    devices: {
      title: 'Geräte',
      table: {
        id: '#',
        pickedUp: 'Ausgegeben am',
        returned: 'Zurückgegeben',
      },
      deleteDevice: {
        title: 'Gerät löschen',
        text: 'Wollen Sie wirklich ein Gerät des Teilnehmers {{ customer.forename }} {{ customer.name }} löschen?',
        delete: 'Löschen',
      },
      returnDevice: {
        title: 'Gerät zurückgeben',
        text: 'Wollen Sie wirklich das Gerät des Teilnehmers {{ customer.forename }} {{ customer.name }} zurückgeben?',
        return: 'Zurückgeben',
      },
      addDevice: {
        title: 'Gerät ausgeben',
        text: 'Wollen Sie wirklich dem Teilnehmer {{ customer.forename }} {{ customer.name }} ein Gerät ausgeben?',
        output: 'Ausgeben',
        success: 'Gerät wurde erfolgreich hinterlegt',
        errors: {
          EHOSTNOTFOUND: 'Lokaler Drucker ist nicht verfügbar',
        },
      },
      outputDevice: 'Gerät ausgeben',
      outputDate: 'Ausgegeben am',
      host: 'Ausgegeben von',
      returnDate: 'Zurückgegeben am',
      returnHost: 'Zurückgegeben von',
      barcode: 'Gerätebarcode',
    },
    checkins: {
      title: 'Anwesenheit',
      checkin: 'Anwesenheit hinzufügen',
      checkinAndPrint: 'Anwesenheit hinzufügen und Label drucken',
      table: {
        subevent: 'Teilevent',
        checkedInAt: 'Eingecheckt am',
        host: 'Station/Benutzer',
      },
      deletePresence: {
        title: 'Anwesenheit löschen',
        text: 'Wollen Sie wirklich die Anwesenheit des Teilnehmers {{ customer.forename }} {{ customer.name }} löschen?',
        delete: 'Löschen',
      },
    },
  },
  customersPayment: {
    headline: 'Zahlungsübersicht',
    table: {
      filterPlaceholder: {
        all: 'Alle',
        fullName: 'Suche nach Namen',
        company: 'Suche nach Firma',
      },
      fullName: 'Name',
      paid: 'Bezahlt',
      notPaid: 'Nicht bezahlt',
    },
    editPayment: 'Zahlung bearbeiten für {{ forename }} {{ name }}',
  },
  customersDuplicates: {
    headline: 'Duplikate',
    merge: 'Teilnehmer zusammenführen',
    mergeCustomer: {
      title: 'Teilnehmer zusammenführen',
      text: `
        Wollen Sie wirklich die folgeden Teilnehmer zusammenführen: <br/>
        {{- duplicates }}
      `,
      textMultiple: 'Wollen Sie wirklich den Teilnehmer(in) {{ customer.forename }} {{ customer.name }} und seine/ihre Gäste löschen?',
      success: 'Der Teilnehmer wurde erfolgreich zusammengeführt.',
      merge: 'Zusammenführen',
    },
  },
  customersWaitingList: {
    headline: 'Warteliste',
    moveFromWaitingList: 'Von Warteliste nachrücken lassen',
    moveFromWaitingListModal: {
      title: 'Von Warteliste nachrücken lassen',
      text: 'Wollen Sie wirklich den Teilnehmer {{ customer.forename }} {{ customer.name }} und seine/ihre Gäste von der Warteliste nachrücken lassen?',
      move: 'Nachrücken lassen',
    },
  },
  orders: {
    headline: 'Bestellungen',
    downloadInvoice: 'Rechnung herunterladen',
    buttons: {
      export: 'Bestellungen exportieren',
      exportInvoice: 'Rechnungen exportieren',
    },
    table: {
      orderNumber: 'Bestellnr.',
      invoiceNumber: 'Rechnungsnr.',
      orderDate: 'Bestelldatum',
      invoiceName: 'Bestellt von',
      status: 'Status',
      paymentStatus: 'Zahlungsstatus',
      total: 'Gesamtbetrag',
    },
    status: {
      open: 'Offen',
      complete: 'Abgeschlossen',
    },
    paymentStatus: {
      open: 'Offen',
      processing: 'In Bearbeitung',
      succeeded: 'Erfolgreich',
    },
    ticketCategories: {
      add: 'Ticketkategorie hinzufügen',
      price: 'Verkaufspreis',
      strikePrice: 'Streichpreis',
      sequenceId: 'Reihenfolge',
      stockSubHeadline: 'Bestand',
      modal: {
        addTitle: 'Ticketkategorie hinzufügen',
        editTitle: 'Ticketkategorie bearbeiten',
      },
      table: {
        name: 'Name',
        status: 'Status',
        salePrice: 'Verkaufspreis',
        saleCount: 'Anzahl der Verkäufe',
        stock: 'Kontingent',
        available: 'Verfügbar',
        safetyStock: 'Sicherheitsbestand',
      },
      status: {
        active: 'Im Verkauf',
        soldOut: 'Ausverkauft',
        inactive: 'Inaktiv',
      },
      errors: {
        nameConflict: 'Eine Ticketkategorie mit demselben Namen existiert bereits.',
      },
      delete: {
        title: 'Ticketkategorie löschen',
        text: 'Möchten Sie die Ticketkategorie „{{ product.name }}“ wirklich löschen?',
        success: 'Die Ticketkategorie wurde erfolgreich gelöscht.',
        restriction: 'Ticketkategorien mit Verkäufen können nicht gelöscht werden',
        delete: 'Löschen',
      },
    },
    design: {
      primaryColor: 'Primärfarbe',
      backgroundColor: 'Hintergrundfarbe',
    },
  },
  seatWish: {
    headline: 'Ausstehende Sitzplatzwünsche',
  },
  groups: {
    headline: 'Teilnehmergruppierung',
    buttons: {
      export: 'Gruppen exportieren',
    },
  },
  subevents: {
    name: 'Name',
    place: 'Ort',
    start: 'Start',
    end: 'Ende',
    typeLabel: 'Type',
    type: {
      normal: 'Teilnehmer-Registrierung',
      special: 'Spezial',
      deviceOutput: 'Geräteausgabe',
      checkin: 'Fastlane Einlass',
      ticketUpgrade: 'Ticketkategorie Änderungen',
    },
    general: 'Allgemein',
    ticketCategories: 'Ticketkategorien',
    allowAllTicketCategories: 'Alle Ticketkategorien erlauben',
    allowedTicketCategories: 'Erlaubte Ticketkategorien',
    allowMultiCheckins: 'Mehrfaches Scannen erlauben',
    activateFlash: 'Barcoderegistrierung aktivieren',
    disablePrinter: 'Drucker deaktivieren',
    settings: 'Einstellungen',
    pdfDownload: 'Teilevent PDF herunterladen',
    actions: {
      attendeeOverview: 'Teilnehmerübersicht',
    },
    createSubeventDialog: {
      title: 'Teilevent hinzufügen',
    },
    editSubeventDialog: {
      title: 'Teilevent bearbeiten',
    },
    deleteSubeventDialog: {
      title: 'Teilevent löschen',
      text: 'Wollen Sie das Teilevent "{{ name }}" wirklich löschen?',
    },
  },
  subeventPresences: {
    headline: 'Teilnehmerübersicht - {{subevent.name}}',
  },
  currentActiveRfidAttendees: {
    email: 'E-Mail',
    forename: 'Vorname',
    name: 'Nachname',
    antennaId: 'Antenne',
    subevent: 'Teilevent',
    firstSeenDate: 'Anwesend von',
    lastSeenDate: 'Anwesend bis',
  },
  hostStatus: {
    host: 'Station',
    status: 'Aktueller Status',
    lastPingDate: 'Letzter Kontakt',
    currentSubevent: 'Ausgewähltes Teilevent',
    currenUpdateSeq: 'Update Sequenz',
  },
  inviteForms: {
    headline: 'Einladungsformulare',
    name: 'Name',
    createForm: 'Neues Formular erstellen',
  },
  invites: {
    headline: 'Einladungen',
    generateInvites: 'Einladungen generieren',
    maxCustomerCount: 'Anzahl Teilnehmer',
    createInvites: 'Einladungen erstellen',
    title: 'Anrede',
    forename: 'Vorname',
    name: 'Nachname',
    company: 'Firma',
    email: 'E-Mail',
    token: 'Code',
    inviteCategory: 'Kategorie',
    showInviteForm: 'Einladungsformular anzeigen',
    gotoInviteForm: 'Einladungsformular aufrufen',
    buttons: {
      export: 'Einladungen exportieren',
      createSingeInvite: 'Einladung erstellen',
      importInviteExcel: 'Excel-Liste importieren',
    },

    table: {
      token: 'Token',
      maxCustomerCount: 'Maximal zu erstellende Teilnehmer',
      usedCount: 'Anzahl bereits genutzt',
      filterPlaceholder: {
        email: 'Suche nach E-Mail',
        company: 'Suche nach Firma',
        forename: 'Suche nach Vorname',
        name: 'Suche nach Nachname',
        token: 'Suche nach Code',
        inviteCategory: 'Filter nach Kategorie',
        allInviteCategory: 'Alle Kategorien',
      },
    },
    createInvitesDialog: {
      title: 'Einladungen generieren',
      generate: 'Generieren',
      general: 'Allgemein',
      maxCustomerCount: 'Anzahl Teilnehmer',
      count: 'Anzahl',
      success: 'Die Einladungen wurden erfolgreich erstellt.',

    },
    editInviteDialog: {
      title: {
        edit: 'Einladung erstellen',
        create: 'Einladung bearbeiten',
      },
      general: 'Allgemein',
      additionalFields: 'Weitere Felder',
      attendees: 'Registrierte Teilnehmer',
      success: {
        edit: 'Die Einladung wurde erfolgreich bearbeitet.',
        create: 'Die Einladung wurde erfolgreich erstellt.',
      },
    },
    deleteInvite: {
      title: 'Einladung Löschen',
      text: 'Wollen Sie wirklich die Einladung mit dem Token {{ invite.token }} löschen?',
      success: 'Die Einladung wurde erfolgreich gelöscht.',
      delete: 'Löschen',
    },
    import: {
      title: 'Import von einer Excel-Liste mit Einladungen',
      text: 'Für einen Import einer Excel-Liste muss in der Zeile 1 die Überschriften für "Vorname", "Nachname", "E-Mail", "Anzahl der Teilnehmer" und "Organisation" über den passenden Spalten stehen',
      success: 'Es wurden {{imported}} Einladung(en) importiert. {{failed}} Einladung(en) konnten nicht importiert werden.',
    },
    mail: {
      sendAllMails: {
        button: 'Einladungen versenden',
        success: 'Einladungen werden versendet. Es kann mehrere Minuten daueren bis alle Mails versendet worden sind.',
        confirm: {
          title: 'Einladungen versenden',
          text: 'Wollen Sie wirklich alle Einladungen versenden? Bereits gesendete Einladungen werden übersprungen.',
          send: 'Senden',
        },
      },
      sendSingleMail: {
        button: 'Einladung versenden',
        success: 'Einladung wird versendet. Es kann mehrere Minuten daueren bis die Mail versendet worden ist.',
        confirm: {
          title: 'Einladungen versenden',
          text: 'Wollen Sie die Einladung an die E-Mail-Adresse {{email}} wirklich versenden?',
          send: 'Senden',
        },

      },
    },
    errors: {
      getInviteError: 'Fehler beim Laden der Einladungen.',
      getInviteError404: 'Fehler beim Laden der Einladungen: Event nicht gefunden.',
      addInviteError: 'Fehler beim Erstellen der Einladungen.',
      addInviteError404: 'Fehler beim Erstellen der Einladungen: Event nicht gefunden.',
      createInviteError: 'Fehler beim Erstellen der Einladung.',
      createInviteError404: 'Fehler beim Erstellen der Einladungen: Einladung nicht gefunden.',
      editInviteError: 'Fehler beim Bearbeiten der Einladungen.',
      editInviteError404: 'Fehler beim Bearbeiten der Einladungen: Einladung nicht gefunden.',
      deleteInviteError: 'Fehler beim Löschen der Einladung',
      deleteInviteError404: 'Fehler beim Löschen der Einladung: Einladung nicht gefunden.',
      deleteInviteError400: 'Fehler beim Löschen der Einladung: Einladung bereits benutzt.',

      importXlsxError: 'Excel-Liste konnte nicht importiert werden.',
      importXlsxError400: 'Excel-Liste konnte nicht importiert werden. Es fehlen die Spalten: {{missingFields}}',
      sendInviteError: 'Fehler beim Senden der Einladung.',
      sendInviteError404: 'Fehler beim Senden der Einladung: Einladung nicht gefunden.',
      sendInvitesError: 'Fehler beim Senden der Einladung.',
      sendInvitesError404: 'Fehler beim Senden der Einladung: Event nicht gefunden.',

      minValueError: 'Wert sollte größer als {{value}} sein.',
      emailError: 'Bitte geben sie eine gültige E-Mail Adresse ein.',
    },
  },

  users: {
    headline: 'Benutzer',
    addUser: 'Benutzer erstellen',
    forename: 'Vorname',
    name: 'Nachname',
    company: 'Firma',
    email: 'E-Mail',
    role: 'Rolle',
    user: 'User',
    admin: 'Admin',
    viewer: 'Betrachter',

    editUserDialog: {
      title: {
        edit: 'Benutzer erstellen',
        create: 'Benutzer bearbeiten',
      },
      general: 'Allgemein',
      success: {
        edit: 'Der Benutzer wurde erfolgreich bearbeitet.',
        create: 'Der Benutzer wurde erfolgreich erstellt.',
      },
    },
    deleteUser: {
      title: 'Benutzer Entfernen',
      text: 'Wollen Sie wirklich den Benutzer mit der E-Mail {{ user.email }} aus der Organisation entfernen?',
      success: 'Der Benutzer wurde erfolgreich entfernt.',
      delete: 'Entfernen',
    },

    errors: {
      getUsersError: 'Fehler beim laden der Benutzer.',
      getUsersError404: 'Fehler beim laden der Benutzer: Organisation nicht gefunden.',
      getUserError: 'Fehler beim laden des Benutzers.',
      getUserError404: 'Fehler beim laden des Benutzers: Organisation nicht gefunden.',
      addUserError: 'Fehler beim erstellen des Benutzers.',
      addUserError404: 'Fehler beim erstellen des Benutzers: Organisation nicht gefunden.',
      addUserError400: 'Fehler beim erstellen des Benutzers: Benutzer existiert bereits in dieser Organisation.',
      editUserError: 'Fehler beim bearbeiten des Benutzers.',
      editUserError404: 'Fehler beim bearbeiten des Benutzers: Benutzer nicht gefunden.',
      deleteUserError: 'Fehler beim löschen des Benutzers',
      deleteUserError404: 'Fehler beim löschen es Benutzers: Benutzer nicht gefunden.',

      emailError: 'Bitte geben sie eine gültige E-Mail Adresse ein.',
    },
  },
  import: {
    errorHeadline: 'Beim Import der Datei ist ein Fehler aufgetreten. Bitte überprüfen Sie die folgenden Fehlermeldungen und versuchen Sie es erneut.',
    lineNumber: 'Zeile {{ lineNumber }}',
    errors: {
      general: 'Die Datei konnte nicht importiert werden.',
      missingColumns: 'Die Datei konnte nicht importiert werden. Es fehlen die Spalten: {{missingFields}}',
    },
    customerErrors: {
      4040: 'Spalte {{ error.field }} fehlt.',
      404: 'Der Wert für Spalte {{ error.field }} fehlt.',
      400: 'Die Ticketkategorie {{ error.value }} ist unbekannt.',
    },
  },
  tables: {
    headline: 'Tischverwaltung',
    name: 'Name',
    tableNumber: 'Tischnummer',
    seatCount: 'Anzahl Sitzplätze',
    owner: 'Organisator',
    seatCountSelection: {
      8: '8 Plätze',
      9: '9 Plätze',
      10: '10 Plätze',
      11: '11 Plätze',
      12: '12 Plätze',
      13: '13 Plätze',
      14: '14 Plätze',
    },
    table: {
      filterPlaceholder: {
        name: 'Suche nach Namen',
      },
    },
    deleteTable: {
      title: 'Tisch Löschen',
      text: 'Wollen Sie wirklich den Tisch mit dem Namen {{ table.name }} löschen?',
      success: 'Der Tisch wurde erfolgreich gelöscht.',
      delete: 'Löschen',
    },
    editPage: {
      title: 'Tisch hinzufügen',
      titleEdit: 'Tisch {{ name }}',
      attendees: 'Gäste',
      seatAssignment: 'Sitzplätze',
    },
    errors: {
      deleteTableError: 'Fehler beim Löschen des Tisches',
      deleteTableError404: 'Fehler beim Löschen des Tisches: Tisch nicht gefunden.',
      deleteTableError400: 'Fehler beim Löschen des Tisches: Tisch bereits benutzt.',
    },
  },
  quotas: {
    headline: 'Kontingente',
    table: {
      name: 'Name',
      currentCustomerCount: 'Aktuell belegt',
      maxCustomerCount: 'Gesetztes Kontingent',
      isLocked: 'Warteliste aktiv',
    },
    editDialog: {
      title: {
        edit: 'Kontingent erstellen',
        create: 'Kontingent bearbeiten',
      },
      success: {
        edit: 'Das Kontingent wurde erfolgreich bearbeitet.',
        create: 'Das Kontingent wurde erfolgreich erstellt.',
      },
    },
  },
  audit: {
    type: {
      participantCreated: 'Hinzugefügt',
      participantUpdated: 'Geändert',
      deviceAdded: 'Gerät ausgegeben',
      deviceDeleted: 'Gerät gelöscht',
      deviceReturned: 'Gerät zurückgegeben',
      deviceExchanged: 'Gerät ausgetauscht',
      emailSent: 'E-Mail gesendet',
      checkinAdded: 'Anwesenheit hinzugefügt',
    },
  },
  devices: {
    headline: 'Nicht zurückgegebene Geräte',
    table: {
      returned: 'Ausgegeben am',
    },
  },
  dashboard: {
    generalTitle: 'Generell',
    totalParticipantCount: 'Teilnehmer insgesamt',
    presenceTotal: 'Anwesende Teilnehmer',
    presenceTotalBySubevent: 'Teilnehmerzahl pro Tag',
    subeventTypes: {
      checkin: 'Fastlane Einlass',
      normal: 'Teilnehmer-Registrierung',
      special: 'Spezial',
      ticketUpgrade: 'Ticketkategorie Änderungen',
      deviceOutput: 'Geräteausgabe',
      deviceReturn: 'Geräterückgabe',
      photoBooth: 'Fotobox',
      survey: 'Umfrage',
    },
    attendee: 'Teilnehmer',
    orders: 'Bestellungen',
  },
  eventMgmt: {
    title: 'Events verwalten',
    addEvents: 'Event hinzufügen',
    eventName: 'Name',
    eventStart: 'Start',
    eventEnd: 'Ende',
    eventAddress: 'Adresse',
    eventCity: 'Stadt',
    editDialog: {
      titleCreate: 'Event erstellen',
      titleEdit: 'Event bearbeiten',
    },
  },
  printerHosts: {
    title: 'Entry Geräte',
    name: 'Name',
    currentEvent: 'Aktuelles Event',
    currentSubevent: 'Aktuelles Teilevent',
    lastContact: 'Letzter Kontakt',
    subevent: 'Teilevent',
    type: 'Type',
    types: {
      localServer: 'Lokaler Server',
      printerClient: 'Drucker Raspberry',
      mobileApp: 'Zebra/Mobile App',
    },
    currentVersion: 'Version',
    deleteModal: {
      title: 'Drucker-Client vom Event trennen',
      text: 'Möchten Sie den Drucker-Client wirklich trennen?',
      disconnect: 'Trennen',
    },
    linkModal: {
      title: 'Drucker-Client mit Event verbinden',
      text: 'Mit welchem Teilevent möchten Sie den Drucker-Client verbinden?',
      disconnect: 'Trennen',
    },
  },
  rooms: {
    headline: 'Räume',
    name: 'Raum',
    currentPresenceCount: 'Aktuell anwesende Teilnehmer',
    maxAttendees: 'Maximale Teilnehmeranzahl',
    attendeesHeadline: '{{name}} - Teilnehmer',
    attendeesColumns: {
      forename: 'Vorname',
      name: 'Nachname',
      email: 'E-Mail',
      enter: 'Raum betreten',
      left: 'Raum verlassen',
      duration: 'Zeit im Raum',
    },
    add: 'Raum hinzufügen',
    modal: {
      addTitle: 'Raum hinzufügen',
      editTitle: 'Raum bearbeiten',
    },
    delete: {
      title: 'Raum löschen',
      text: 'Möchten Sie den Raum "{{ room.name }}“ wirklich löschen?',
      delete: 'Löschen',
    },
  },
  settings: {
    features: {
      headline: 'Features / Module',
      enableFeature: 'Feature / Module freischalten',
      feature: 'Feature / Module',
      description: 'Beschreibung',
      dialog: {
        title: 'Feature / Module freischalten',
      },
      deleteDialog: {
        title: 'Feature löschen',
        text: 'Wollen Sie wirklich das Feature {{ feature }} löschen?',
        delete: 'Löschen',
      },
    },
    template: {
      emailTemplates: 'E-Mail Templates',
      deviceOutput: 'Geräteausgabe',
      deviceReminder: 'Geräteerinnerung',
      deviceReturn: 'Geräterückgabe',
    },
    email: {
      headline: 'E-Mail Einstellungen',
      server: 'E-Mail Server für alle Events',
      type: 'Absender',
      from: 'Von',
      replyTo: 'Antwortadresse',
      host: 'Server',
      username: 'Nutzernamen',
      password: 'Passwort',
      port: 'Port',
      requireTLS: 'TLS einschalten',
      typeEnum: {
        default: 'Standard Entry Absender',
        customMailAddress: 'Über Sendgrid mit eigener Domain',
        customSMTP: 'Eigener SMTP Server',
      },
    },
    label: {
      headline: 'Labels',
      labelToPrint: 'Labelgröße',
      settings: 'Einstellungen',
      numCopies: 'Anzahl der Label',
      printAfterCreation: 'Drucken beim Erstellen eines Teilnehmers',
    },
  },
  ticketCategories: {
    table: {
      name: 'Name',
      highlightOnScan: 'Beim Scannen hervorheben',
      printOnLabel: 'Auf Badge drucken',
      type: 'Type',
    },
    ticketTypes: {
      entry: 'Einlass',
      device: 'Gerät',
      invite: 'Einladung',
      inviteRejected: 'Einladung abgelehnt',
      registered: 'Einladung angenommen',
      placeholder: 'Platzhalter',
      waitingList: 'Warteliste',
    },
    createDialog: {
      title: 'Ticketkategorie hinzufügen',
      titleEdit: 'Ticketkategorie bearbeiten',
    },
    errors: {
      nameConflict: 'Eine Ticketkategorie mit demselben Namen existiert bereits.',
    },
    delete: {
      title: 'Ticketkategorie löschen',
      text: 'Möchten Sie die Ticketkategorie „{{ name }}“ wirklich löschen?',
      success: 'Die Ticketkategorie wurde erfolgreich gelöscht.',
    },
  },
  attributes: {
    table: {
      code: 'Code',
      name: 'Name',
      type: 'Typ',
      section: 'Bereich',
      visibility: 'Sichtbarkeit',
      isVisibleInTable: 'In Liste anzeigen',
      sequenceId: 'Reihenfolge',
    },
    visibility: {
      internal: 'Intern',
      external: 'Extern',
    },
    type: {
      text: 'Text',
      color: 'Farbe',
      boolean: 'Ja/Nein-Wert',
      date: 'Datum',
      number: 'Zahl',
      select: 'Auswahl',
      textarea: 'Mehrzeiliges Textfeld',
      money: 'Währung',
      coreField: 'Kernfeld',
      attendee: 'Teilnehmer',
      title: 'Titel',
    },
    behaviourOnScan: 'Verhalten beim Scannen',
    showOnScan: 'Beim Scannen anzeigen',
    highlightOnScan: 'Beim Scannen hervorheben',
    highlightOnScanFilterType: 'Filter für Hervorhebung',
    highlightOnScanFilterValue: 'Filterwert für Hervorhebung',
    highlightOnScanColor: 'Farbe der Hervorhebung',
    filterType: {
      hasValue: 'Anzeigen, wenn Attribut nicht leer ist',
      contains: 'Nur anzeigen, wenn folgender Wert gesetzt ist',
    },
    createDialog: {
      title: 'Attribut hinzufügen',
      titleEdit: 'Attribut bearbeiten',
    },
    delete: {
      title: 'Attribut löschen',
      text: 'Möchten Sie das Attribut „{{ name }}“ wirklich löschen?',
      success: 'Das Attribut wurde erfolgreich gelöscht.',
      delete: 'Löschen',
    },
  },
};

export default translation;
