import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { getQuotas } from '../gql';
import DashboardPanel from './DashboardPanel';

export default function QuotasStatistics() {
  const hasFeature = window.eventFeatures && window.eventFeatures.includes('customerWaitingList');
  const { data } = useQuery(getQuotas, {
    skip: !hasFeature,
    variables: { eventId: window.eventId },
  });

  if (!hasFeature || !data?.quotas?.quotas?.length) return null;
  return (
    <>
      <PageHeader>Kontingent</PageHeader>
      <Row>
        {data?.quotas?.quotas?.map((quota) => (
          <Col md={4} key={quota.id}>
            <DashboardPanel
              name={quota.name}
              value={`${quota.currentCustomerCount}/${quota.maxCustomerCount}`}
              icon="users"
              color={(quota.currentCustomerCount >= quota.maxCustomerCount)
                ? 'red' : ((quota.currentCustomerCount / quota.maxCustomerCount) > 0.8) ? 'yellow' : 'green'}
              // eslint-disable-next-line max-len
              link={`/customers/index/${window.eventId}?filter=${encodeURIComponent(JSON.stringify({ ticketCategoryId: { $in: quota.ticketCategories } }))}`}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}
