import React, { useEffect, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { camelCase } from 'change-case';

import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import FormField from '../../../components/FormField';

function AttributeEditDialog({ show, onHide, modalData, onSubmit }) {
  const { t } = useTranslation('attributes');
  const { t: tg } = useTranslation('general');
  const data = {
    name: '',
    isVisibleInTable: false,
    visibility: 'internal',
    type: 'text',
    ...modalData,
    settings: {
      showOnScan: false,
      highlightOnScan: false,
      highlightOnScanFilterType: 'exists',
      highlightOnScanFilterValue: '',
      ...(modalData?.settings || {}),
    },
  };

  const fields = useMemo(() => [
    { name: 'name', title: t('table.name'), type: 'string' },
    { name: 'code', title: t('table.code'), type: 'string', disabled: true },
    {
      name: 'type',
      title: t('table.type'),
      type: 'select',
      options: [
        { label: t('type.text'), value: 'text' },
        { label: t('type.textarea'), value: 'textarea' },
        { label: t('type.color'), value: 'color' },
        { label: t('type.boolean'), value: 'boolean' },
        { label: t('type.number'), value: 'number' },
        { label: t('type.date'), value: 'date' },
        { label: t('type.select'), value: 'select' },
        { label: t('type.money'), value: 'money' },
        { label: t('type.coreField'), value: 'coreField' },
        { label: t('type.attendee'), value: 'attendee' },
        { label: t('type.titleSelect'), value: 'titleSelect' },
      ],
      noDefaultOption: true,
    },
    { name: 'sequenceId', title: t('table.sequenceId'), type: 'number' },
    { name: 'section', title: t('table.section'), type: 'string' },
    { name: 'isVisibleInTable', title: t('table.isVisibleInTable'), type: 'boolean' },
    {
      name: 'visibility',
      title: t('table.visibility'),
      type: 'select',
      noDefaultOption: true,
      options: [
        { label: t('visibility.internal'), value: 'internal' },
        { label: t('visibility.external'), value: 'external' },
      ],
    },
    { title: t('behaviourOnScan'), type: 'subheadline' },
    { name: 'settings.showOnScan', title: t('showOnScan'), type: 'boolean' },
    { title: t('highlightOnScan'), type: 'subheadline' },
    { name: 'settings.highlightOnScan', title: t('highlightOnScan'), type: 'boolean' },
    {
      name: 'settings.highlightOnScanFilterType',
      title: t('highlightOnScanFilterType'),
      type: 'select',
      noDefaultOption: true,
      options: [
        { label: t('filterType.hasValue'), value: 'exists' },
        { label: t('filterType.contains'), value: 'contains' },
      ],
      condition: {
        name: 'settings.highlightOnScan',
        value: true,
      },
    },
    {
      name: 'settings.highlightOnScanColor',
      title: t('highlightOnScanColor'),
      type: 'color',
      condition: {
        name: 'settings.highlightOnScan',
        value: true,
      },
    },
    {
      name: 'settings.highlightOnScanFilterValue',
      title: t('highlightOnScanFilterValue'),
      type: 'string',
      condition: {
        name: 'settings.highlightOnScanFilterType',
        value: 'contains',
      },
    },
  ], []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      bsSize="large"
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, form }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (modalData?.id) return;
            form.change('code', camelCase(values.name));
          }, [values.name]);
          return (
            <Form horizontal onSubmit={handleSubmit}>

              <Modal.Header closeButton>
                <Modal.Title>
                  {(modalData?.id) ? t('createDialog.titleEdit') : t('createDialog.title')}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {fields
                  .map((field) => (
                    field.type !== 'subheadline'
                      ? (
                        <FormField
                          name={field.name}
                          title={field.title}
                          labelSize={3}
                          inputSize={9}
                          type={field.type}
                          options={field.options}
                          required={field.required}
                          noDefaultOption={field.noDefaultOption}
                          condition={field.condition}
                          disabled={field.disabled}
                        />
                      )
                      : <div className="subheadline">{field.title}</div>
                  ))}
              </Modal.Body>

              <Modal.Footer>
                <Button bsStyle="primary" onClick={handleSubmit}>
                  {(modalData) ? tg('save') : tg('create')}
                </Button>
                <Button onClick={onHide}>{tg('close')}</Button>
              </Modal.Footer>
            </Form>
          );
        }}
      />
    </Modal>
  );
}

export default AttributeEditDialog;
