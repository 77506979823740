import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  ControlLabel, Form, FormControl, FormGroup, Glyphicon, PageHeader, Table,
} from 'react-bootstrap';
import { useSessionStorage, useDebounce } from 'react-use';

import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import { getAttributesGql, getCustomerGroupsGql, getTicketCategoriesGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import QueryBuilder from '../components/QueryBuilder';

function GroupsPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`customerGroupSearch_${window.eventId}`, '');
  const [filter, setFilter] = useState({});

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { organizerId: window.eventOrganizerId },
  });

  const filterToSend = useMemo(() => {
    const filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);
    if (debouncedSearchTerm) {
      filterToSend.$and.push({
        $or: [
          { fullName: { $ct: debouncedSearchTerm } },
          { email: { $ct: debouncedSearchTerm } },
        ],
      });
    }

    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [filter, debouncedSearchTerm]);

  const { data: { customerGroups: { participantGroups } } = { customerGroups: {} }, refetch } = useQuery(getCustomerGroupsGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      filter: filterToSend,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    setSearchTerm(debouncedSearchTerm);
  }, []);

  return (
    <>
      <PageHeader>{t('groups.headline')}</PageHeader>
      <ButtonToolbar style={{ marginBottom: 10 }}>
        <Button
          href={`/api/v1/events/${window.eventId}/participants/groups/exports?filter=${filterToSend}&query=${debouncedSearchTerm}`}
          target="_blank"
        >
          {t('groups.buttons.export')}
        </Button>
      </ButtonToolbar>

      <QueryBuilder
        attributes={attributeResult?.getAttributes?.attributes}
        ticketCategories={categoriesResult?.getTicketCategories?.ticketCategories}
        onFilterApply={(newFilter) => { setFilter(newFilter); if (JSON.stringify(newFilter) === JSON.stringify(filter)) { refetch(); } }}
        filterPersistKey={`customerPaymentFilter_${window.eventId}`}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <Table responsive bordered striped hover>
        <thead>
          <tr>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>E-Mail</th>
            <th>Ticketkategorie</th>
            <th>Gast von</th>
            <th>Sitzplatzwunsch von Teilnehmer</th>
            <th>Sitzplatzwunsch</th>
            <th>Sitzplatz</th>
            <th>{t('general.action')}</th>
          </tr>
        </thead>
        <tbody>
          {participantGroups?.map((group) => ([
            (
              <tr key={group.groupId}>
                <td colSpan="9">
                  <h4 style={{ marginLeft: 10 }}>
                    {
                    group?.eventTable
                      ? group.eventTable.name
                      : `Gastgruppe von ${group.participants[0].forename} ${group.participants[0].name}`
                    }
                  </h4>
                </td>
              </tr>
            ),
            ...group.participants.map((part) => (
              <tr key={part.id}>
                <td>{part.forename}</td>
                <td>{part.name}</td>
                <td>{part.email}</td>
                <td>{part.ticketCategory.name}</td>
                <td>{part.linkedCustomer ? `${part.linkedCustomer.forename} ${part.linkedCustomer.name}` : ''}</td>
                <td>{part.customAttributes.seatWishAttendee}</td>
                <td>{part.customAttributes.seatWish}</td>
                <td>{part.eventTable?.seat}</td>
                <td>
                  <ButtonToolbar>
                    <Button
                      href={`/customers/edit/${window.eventId}/${part.id}`}
                      bsStyle="primary"
                    >
                      <Glyphicon glyph="pencil" />
                    </Button>
                  </ButtonToolbar>
                </td>
              </tr>
            )),
          ]))}
        </tbody>
      </Table>
    </>
  );
}

export default GroupsPage;
