/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const addAttendeeGql = gql`
  mutation addAttendee($eventId: ID!, $input: AttendeeInput!) {
    addAttendee (eventId: $eventId, input: $input) @rest(type: "Subevent", path: "events/{args.eventId}/participants", method: "POST") {
      barcode
    }
  }
`;

export const addPresenceGql = gql`
  mutation addPresence($eventId: ID!, $barcode: String!, $input: AttendeeInput!) {
    addAttendee (eventId: $eventId, barcode: $barcode, input: $input) @rest(
      type: "Subevent", path: "events/{args.eventId}/participants/{args.barcode}/presences", method: "POST"
    ) {
      barcode
    }
  }
`;
