/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getEvents = gql`
  query getEvents{
    getEvents 
      @rest(type: "[event]", path: "events?onlyFutureEvents=false") {
        events {
          id
          name
          place
          street
          city
          start
          end
          isArchived
          eventOrganizer
        }
    }
  }
`;
