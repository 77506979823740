import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Button, Col, Form, HelpBlock, PageHeader, Panel, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field, Form as FinalForm } from 'react-final-form';
import axios from 'axios';

import toastHelper from '../../../lib/toastHelper';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getDetailsGql, updateDetailsGql } from '../gql';
import FormField from '../../../components/FormField';
import FieldGroup from '../../../components/FieldGroup';
import TipTapEdior from '../components/TipTapEditor';

function OrderDesignPage() {
  const { t } = useTranslation('orders');
  const { t: tg } = useTranslation('general');
  const { data, loading } = useQuery(getDetailsGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
  });

  const [updateDetails] = useMutation(updateDetailsGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(tg([`errors.error${err.networkError.statusCode}`, 'errors.generalError']));
    },
    onCompleted: () => toastHelper.success(tg('successMessages.saved')),
  });

  const fields = [
    { name: 'colors.primary', title: t('design.primaryColor'), type: 'color', required: true },
    { name: 'colors.background', title: t('design.backgroundColor'), type: 'color', required: true },
  ];

  const onSubmit = async (orgValues) => {
    const values = {
      ...orgValues,
      eventId: undefined,
      logoUrl: undefined,
      newLogoFile: undefined,
    };
    await updateDetails({
      variables: {
        eventId: window.eventId,
        input: values,
      },
    });
    if (orgValues.newLogoFile) {
      const formData = new FormData();
      formData.append('file', orgValues.newLogoFile);
      await axios
        .postForm(`/api/v1/events/${window.eventId}/ecommerce/logo`, formData, {
          withCredentials: true,
        });
    }
  };

  if (loading) return (<LoadingSpinner />);

  return (
    <PageWrapper>
      <PageHeader>Design</PageHeader>
      <FinalForm
        initialValues={data?.getDetails?.event}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => (
          <Form horizontal onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton bsStyle="primary" type="submit">
                {tg('save')}
              </ActionButton>
            </div>
            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Logo</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <img
                      src={values.newLogoFile ? URL.createObjectURL(values.newLogoFile) : values.logoUrl}
                      alt="Logo"
                      style={{ maxWidth: '100%' }}
                    />
                    <div style={{ marginLeft: 20 }}>
                      <Field
                        name="newLogoFile"
                        type="file"
                      >
                        {({ meta, input }) => (
                          <>
                            <FieldGroup
                              type="file"
                              label="Logo hochladen"
                              /* {...input} */
                              onChange={(file) => input.onChange(file.target.files[0])}
                              style={{ marginTop: 10 }}
                              accept=".png,.jpg,.jpeg,.svg,image/png,image/jpeg,image/svg+xml"
                            />
                            {meta.touched && (meta.error || meta.submitError) && <HelpBlock>{meta.error || meta.submitError}</HelpBlock>}
                          </>
                        )}
                      </Field>
                    </div>
                  </Panel.Body>
                </Panel>
              </Col>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Farbe</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {fields
                      .map((field) => (
                        field.type !== 'subheadline'
                          ? (
                            <FormField
                              name={field.name}
                              title={field.title}
                              labelSize={4}
                              inputSize={8}
                              type={field.type}
                              options={field.options}
                              required={field.required}
                            />
                          )
                          : <div className="subheadline">{field.title}</div>
                      ))}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">Beschreibung</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Field name="description">
                      {({ input }) => (
                        <TipTapEdior {...input} />
                      )}
                    </Field>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Form>
        )}
      />
    </PageWrapper>
  );
}

export default OrderDesignPage;

const ActionButton = styled(Button)`
  margin-left: 5px;
`;

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
