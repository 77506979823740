import React from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar, Line } from 'recharts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getOrderStatsGql } from '../gql';

const formatCurrency = (number) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);

export default function OrderStatistics() {
  const { t } = useTranslation('dashboard');
  const hasFeature = window.eventFeatures && window.eventFeatures.includes('ecommerce');
  const { data } = useQuery(getOrderStatsGql, {
    skip: !hasFeature,
    variables: { eventId: window.eventId },
  });
  return (
    <>
      <PageHeader>{t('orders')}</PageHeader>
      <Row>
        <Col md={6} style={{ height: 500, textAlign: 'center', marginBottom: 80 }}>
          <h3>Verkäufe</h3>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={400}
              data={data?.getOrderStats?.orderStats}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="date" scale="band" tickFormatter={(value) => moment(value).format('L')} />
              <YAxis yAxisId="left" tickFormatter={formatCurrency} />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                formatter={(value, name, props) => {
                  if (props.dataKey === 'rev') return formatCurrency(value);
                  return value;
                }}
              />
              <Legend />
              <Bar
                dataKey="rev"
                barSize={20}
                fill="#8884d8"
                yAxisId="left"
                tickFormatter={formatCurrency}
                name="Umsatz"
              />
              <Line type="monotone" dataKey="count" stroke="#5cb85c" yAxisId="right" name="Anzahl Bestellungen" />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
        <Col md={6} style={{ height: 500, textAlign: 'center', marginBottom: 80 }}>
          <h3>Verkaufte Tickets</h3>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={400}
              data={data?.getOrderStats?.orderStats}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="date" scale="band" tickFormatter={(value) => moment(value).format('L')} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="ticketSoldCount" name="Summe verkaufter Tickets" />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
}
