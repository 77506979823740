import React from 'react';
import { Button, Checkbox, Col, ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Switch from 'react-bootstrap-switch';
import { t } from '../../../locale';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';

const types = [
  { name: t('subevents.type.normal'), value: 'normal' },
  { name: t('subevents.type.special'), value: 'special' },
  { name: t('subevents.type.checkin'), value: 'checkin' },
];

function SubeventEditDialog({ show, onHide, modalData, onSubmit }) {
  const data = {
    allowAllTicketCategories: true,
    allowedTicketCategories: [],
    start: new Date(),
    end: new Date(),
    type: 'normal',
    ...modalData,
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <FinalForm
        initialValues={data}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => (
          <Form horizontal onSubmit={handleSubmit}>

            <Modal.Header closeButton>
              <Modal.Title>
                {(modalData) ? t('subevents.editSubeventDialog.title') : t('subevents.createSubeventDialog.title')}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="subheadline">{t('subevents.general')}</div>
              <Field name="name">
                {(props) => (
                  <FormGroup controlId="formHorizontalEmail">
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('subevents.name')}
                    </Col>
                    <Col sm={10}>
                      <FormControl {...props.input} />
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="place">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('subevents.place')}
                    </Col>
                    <Col sm={10}>
                      <FormControl {...props.input} />
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="start">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('subevents.start')}
                    </Col>
                    <Col sm={10}>
                      <DatePickerWrapper>
                        <DatePicker
                          selected={new Date(props.input.value || undefined)}
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={15}
                          onChange={(date) => props.input.onChange(date.toISOString())}
                          dateFormat="Pp"
                        />
                      </DatePickerWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="end">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('subevents.end')}
                    </Col>
                    <Col sm={10}>
                      <DatePickerWrapper>
                        <DatePicker
                          selected={new Date(props.input.value || undefined)}
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={15}
                          onChange={(date) => props.input.onChange(date.toISOString())}
                          dateFormat="Pp"
                        />
                      </DatePickerWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="type">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      {t('subevents.typeLabel')}
                    </Col>
                    <Col sm={10}>
                      <FormControl componentClass="select" {...props.input}>
                        {types.map((type) => <option key={type.value} value={type.value}>{type.name}</option>)}
                      </FormControl>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <div className="subheadline">{t('subevents.ticketCategories')}</div>
              <Field name="allowAllTicketCategories">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={4}>
                      {t('subevents.allowAllTicketCategories')}
                    </Col>
                    <Col sm={8}>
                      <SwitchWrapper>
                        <Switch
                          {...props.input}
                          onChange={(el, state) => props.input.onChange(state)}
                          bsSize="small"
                        />
                      </SwitchWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              {!values.allowAllTicketCategories
                ? (
                  <Field name="allowedTicketCategories">
                    {(props) => (
                      <FormGroup>
                        <Col componentClass={ControlLabel} sm={4}>
                          {t('subevents.allowedTicketCategories')}
                        </Col>
                        <Col sm={8}>
                          {window.ticketCategories.map((cat) => (
                            <Checkbox
                              key={cat.id}
                              checked={!!props.input.value?.find(({ id }) => id === cat.id) || false}
                              onChange={(value) => {
                                if (value.target.checked) {
                                  props.input.onChange([
                                    ...props.input.value,
                                    { id: cat.id, name: cat.name },
                                  ]);
                                  return;
                                }
                                props.input.onChange([
                                  ...props.input.value.filter(({ id }) => id !== cat.id),
                                ]);
                              }}
                            >{cat.name}
                            </Checkbox>
                          ))}
                        </Col>
                      </FormGroup>
                    )}
                  </Field>
                )
                : null }

              <div className="subheadline">{t('subevents.settings')}</div>
              <Field name="settings.allowMultiCheckins">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={4}>
                      {t('subevents.allowMultiCheckins')}
                    </Col>
                    <Col sm={8}>
                      <SwitchWrapper>
                        <Switch
                          {...props.input}
                          onChange={(el, state) => props.input.onChange(state)}
                          bsSize="small"
                        />
                      </SwitchWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="settings.activateFlash">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={4}>
                      {t('subevents.activateFlash')}
                    </Col>
                    <Col sm={8}>
                      <SwitchWrapper>
                        <Switch
                          {...props.input}
                          onChange={(el, state) => props.input.onChange(state)}
                          bsSize="small"
                        />
                      </SwitchWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

              <Field name="settings.disablePrinter">
                {(props) => (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={4}>
                      {t('subevents.disablePrinter')}
                    </Col>
                    <Col sm={8}>
                      <SwitchWrapper>
                        <Switch
                          {...props.input}
                          onChange={(el, state) => props.input.onChange(state)}
                          bsSize="small"
                        />
                      </SwitchWrapper>
                    </Col>
                  </FormGroup>
                )}
              </Field>

            </Modal.Body>

            <Modal.Footer>
              <Button bsStyle="primary" onClick={handleSubmit}>
                {(modalData) ? t('general.save') : t('general.create')}
              </Button>
              <Button onClick={onHide}>{t('general.close')}</Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
}

const DatePickerWrapper = styled.div`
  input {
    width: 100%;
    padding: 6px 12px;
    height: 34px;
    color: #555;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border-radius: 4px;
  }
`;

const SwitchWrapper = styled.div`
  margin-top: 10px;
`;

export default SubeventEditDialog;
