import React, { useEffect, useMemo, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';
import { useSearchParam, useSessionStorage } from 'react-use';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { t } from '../locale';

export default function AdvancedBootstapTable({ filterPersistKey = '', noDataText = '', ...tableParams }) {
  const [filterValues, setFilterValues] = useSessionStorage(filterPersistKey, {});
  const filterQueryString = useSearchParam('filter');
  const filterValueRef = useRef();
  const columns = useMemo(() => {
    const filterQuery = filterQueryString ? JSON.parse(filterQueryString) : {};
    const actualFilterValues = (filterQueryString) ? {} : filterValues;

    const mapped = tableParams.columns.map((column) => {
      if (!column.filter || (!filterValues?.[column.dataField] && !filterQuery[column.dataField])) return column;
      // eslint-disable-next-line no-param-reassign
      column.filter.props.getFilter = (filter) => {
        if (column.filter.props.options) {
          setTimeout(() => {
            filter(filterQuery[column.dataField] || actualFilterValues[column.dataField]);
          }, 300);
        }
        // eslint-disable-next-line no-param-reassign
        filter(filterQuery[column.dataField] || actualFilterValues[column.dataField]);
      };

      // eslint-disable-next-line no-param-reassign
      column.headerClasses = (column) => {
        if (filterValueRef.current?.[column.dataField]) return 'filterSelected';
        return '';
      };

      return column;
    });
    return mapped;
  }, [tableParams.columns]);

  useEffect(() => {
    filterValueRef.current = filterValues;
  }, [filterValues]);

  const onTableChange = (type, result) => {
    tableParams.onTableChange(type, result);
    const newFilterValues = {};
    if (type === 'filter' && filterPersistKey) {
      for (const [name, { filterVal }] of Object.entries(result.filters)) {
        newFilterValues[name] = filterVal;
      }
      setFilterValues(newFilterValues);
    }
  };

  return (
    <TableWrapper>
      <BootstrapTable
        {...tableParams}
        columns={columns}
        onTableChange={onTableChange}
        striped
        bordered={false}
        noDataIndication={() => <p style={{ textAlign: 'center' }}>{noDataText || t('general.noData')}</p>}
      />
    </TableWrapper>
  );
}

export const paginationTotalRenderer = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total" style={{ marginLeft: 30 }}>
    { from } - { to } von { size } Ergebnisse
  </span>
);

export const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <Form inline style={{ display: 'inline' }}>
    <FormGroup controlId="formControlsSelect">
      <span>Einträge pro Seite:</span>
      <FormControl
        componentClass="select"
        onChange={(e) => onSizePerPageChange(e.target.value)}
        style={{ marginLeft: 5 }}
      >
        {options.map((option) => (
          <option value={option.page} selected={currSizePerPage === `${option.page}`}>{option.text}</option>
        ))}
      </FormControl>
    </FormGroup>
  </Form>
);

const TableWrapper = styled.div`
  table tbody tr td {
    overflow-wrap: break-word;
  }

  .filterSelected {
    background-color: #eee;
  }
`;
