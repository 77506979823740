import { gql } from '@apollo/client';

export const getTablesGql = gql`
  query tables($eventId: ID!, $limit: Int, $offset: Int, $filter: Params = "") {
    getTables (eventId: $eventId, limit: $limit, offset: $offset, filter: $filter)
      @rest(type: "[Table]", path: "events/{args.eventId}/tables?limit={args.limit}&offset={args.offset}&filter={args.filter}") {
      meta {
        total
      }
      tables {
        id
        name
        tableNumber
        seatCount
        status
        attendees
      }
    }
  }
`;

export const getSingleTableGql = gql`
  query table($eventId: ID!, $tableId: ID!) {
    getTable (eventId: $eventId, tableId: $tableId) @rest(type: "[Table]", path: "events/{args.eventId}/tables/{args.tableId}") {
      id
      name
      tableNumber
      seatCount
      status
      attendees
      owner
      shouldAddPlacholderCustomer
    }
  }
`;

export const createTableGql = gql`
  mutation createTable($eventId: ID!, $input: PublishablePostInput!) {
    createTable (eventId: $eventId,  input: $input) @rest(
      path: "events/{args.eventId}/tables",
      method: "POST"
    ) { id }
  }
`;

export const updateTableGql = gql`
  mutation updateTable($eventId: ID!, $tableId: ID!  $input: PublishablePostInput!) {
    updateTable (eventId: $eventId, tableId: $tableId, input: $input) @rest(
      path: "events/{args.eventId}/tables/{args.tableId}",
      method: "POST"
    ) { NoResponse }
  }
`;

export const deleteTableGql = gql`
  mutation updateTable($eventId: ID!, $tableId: ID!) {
    updateTable (eventId: $eventId, tableId: $tableId) @rest(
      path: "events/{args.eventId}/tables/{args.tableId}",
      method: "DELETE"
    ) { NoResponse }
  }
`;
