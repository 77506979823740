import React, { useEffect } from 'react';
import { Button, ButtonGroup, Panel } from 'react-bootstrap';
import { useSearchParam, useSessionStorage } from 'react-use';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { t } from '../../../locale';
import { getTitles } from '../gql';

export default function QueryBuilder({ filterPersistKey = '', attributes, ticketCategories, onFilterApply, useQueryParam = true }) {
  const [sessionFilter, setSessionFilter] = useSessionStorage(filterPersistKey || `customerFilter_${window.eventId}`);
  const filterQueryString = useSearchParam('filter');
  const { data: dynamicTitles } = useQuery(getTitles, {
    variables: { eventId: window.eventId },
    fetchPolicy: 'cache-first',
  });

  const mapOperator = (operator) => {
    if (operator === 'greater') return '$gt';
    if (operator === 'greater_or_equal') return '$gte';
    if (operator === 'less') return '$lt';
    if (operator === 'less_or_equal') return '$lte';
    if (operator === 'not_equal') return '$neq';
    if (operator === 'in') return '$in';
    if (operator === 'not_in') return '$nin';
    if (operator === 'is_empty') return '$has';
    if (operator === 'is_not_empty') return '$has';
    if (operator === 'contains') return '$ct';
    if (operator === 'not_contains') return '$nct';
    return '$eq';
  };

  const mapRules = (ruleToMap) => {
    const mappedRules = [];
    for (const index in ruleToMap.rules) {
      const rule = { ...ruleToMap.rules[index] };
      if (rule.condition) {
        mappedRules.push(mapRules(rule));
        continue;
      }
      if (rule.operator === 'is_not_empty') rule.value = true;
      if (rule.operator === 'is_empty') rule.value = false;
      if (rule.data?.internalType === 'date') rule.value = new Date(rule.value).toISOString();
      if (rule.data?.internalType === 'boolean') rule.value = rule.value === 'true';
      mappedRules.push({ [rule.field]: { [mapOperator(rule.operator)]: rule.value } });
    }
    return { [`$${ruleToMap.condition.toLowerCase()}`]: mappedRules };
  };

  const onClick = () => {
    const orgRules = window.$('#builder').queryBuilder('getRules');
    setSessionFilter(orgRules);
    const mappedRules = mapRules(orgRules);
    if (onFilterApply) onFilterApply(mappedRules);
  };

  useEffect(() => {
    if (!attributes || !ticketCategories || !dynamicTitles?.getTitles) return;

    const categoriesById = ticketCategories.reduce((acc, cat) => {
      acc[cat.id] = cat.name;
      return acc;
    }, {});

    const customAttr = attributes
      .filter(({ visibility }) => !visibility.toLowerCase().includes('system'))
      .map((attr) => {
        let props = {};
        if (attr.type === 'text') {
          props = { input: 'text', type: 'string', operators: ['equal', 'not_equal', 'contains', 'is_empty', 'is_not_empty'] };
        }
        if (attr.type === 'number') {
          props = {
            input: 'number',
            type: 'integer',
            operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'is_empty', 'is_not_empty'],
          };
        }
        if (attr.type === 'boolean') {
          props = {
            input: 'radio',
            type: 'integer',
            values: [{ true: 'Ja' }, { false: 'Nein' }],
            operators: ['equal'],
            data: { internalType: 'boolean' },
          };
        }
        if (attr.type === 'date') {
          props = {
            type: 'string',
            operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal', 'between', 'is_empty', 'is_not_empty'],
            input: (rule, name) => (
              `<input class="form-control" type="date" name="${name}">`
            ),
          };
        }

        if (attr.type === 'titleSelect') {
          props = {
            input: 'select',
            type: 'string',
            operators: ['equal', 'not_equal', 'contains', 'is_empty', 'is_not_empty'],
            values: dynamicTitles?.getTitles.reduce((acc, title) => { acc[title.code] = title.name; return acc; }, {}),
          };
        }

        if (attr.code === 'title') {
          props = {
            id: 'title',
            input: 'select',
            type: 'string',
            operators: ['equal', 'not_equal', 'contains', 'is_empty', 'is_not_empty'],
            values: dynamicTitles?.getTitles.reduce((acc, title) => { acc[title.code] = title.name; return acc; }, {}),
          };
        }

        if (!props.operators) props.operators = ['equal', 'not_equal', 'is_empty', 'is_not_empty'];

        return {
          id: (attr.type !== 'coreField') ? `customAttributes.${attr.code}` : attr.code,
          label: attr.name,
          optgroup: attr.section,
          ...props,
        };
      });

    const availableFilter = [
      {
        id: 'ticketCategoryId',
        label: t('customers.table.ticketCategory'),
        input: 'select',
        multiple: true,
        type: 'integer',
        values: categoriesById,
        operators: ['in', 'not_in'],
        optgroup: 'baseData',
      },
      {
        id: 'created',
        label: t('customers.table.created'),
        data: { internalType: 'date' },
        optgroup: 'baseData',
        input: (rule, name) => (
          `<input class="form-control" type="date" name="${name}">`
        ),
        operators: ['less', 'greater_or_equal'],
      },
      {
        id: 'registerDate',
        label: t('customers.table.registerDate'),
        data: { internalType: 'date' },
        optgroup: 'baseData',
        input: (rule, name) => (
          `<input class="form-control" type="date" name="${name}">`
        ),
        operators: ['less', 'greater_or_equal'],
      },
      ...customAttr,
    ];

    window.$('#builder').queryBuilder({
      sortable: true,
      filters: availableFilter,
      optgroups: {
        baseData: 'Stammdaten',
      },
    });

    if (filterQueryString && useQueryParam) {
      const filterQuery = JSON.parse(filterQueryString);
      const filter = {
        condition: 'AND',
        rules: Object.entries(filterQuery)
          .map(([filterName, filter]) => {
            if (typeof filter !== 'object') {
              return {
                id: filterName, field: filterName, type: 'integer', input: 'select', operator: 'eq', value: filter,
              };
            }
            const [filterOperator, filterValue] = Object.entries(filter)[0];
            return {
              id: filterName, field: filterName, type: 'integer', input: 'select', operator: filterOperator.replace('$', ''), value: filterValue,
            };
          }),
      };
      setSessionFilter(filter);
      window.$('#builder').queryBuilder('setRules', filter);
      onClick();
      return;
    }

    if (sessionFilter) {
      window.$('#builder').queryBuilder('setRules', sessionFilter);
      onClick();
    }
  }, [attributes, ticketCategories, dynamicTitles?.getTitles]);

  const onReset = () => {
    window.$('#builder').queryBuilder('reset');
    if (onFilterApply) onFilterApply({});
    setSessionFilter(null);
  };

  return (
    <Panel defaultExpanded={!!sessionFilter || !!filterQueryString}>
      <Panel.Heading>
        <Panel.Title toggle>
          {t('customers.filter.headline')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <div id="builder" />
          <ButtonGroup style={{ marginTop: '10px' }}>
            <ActionButton onClick={onClick} bsStyle="primary" style={{ marginRight: '5px' }}>
              {t('customers.filter.applyFilter')}
            </ActionButton>
            <ActionButton onClick={onReset}>
              {t('customers.filter.reset')}
            </ActionButton>
          </ButtonGroup>
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
}

const ActionButton = styled(Button)`
  margin-left: 5px;
`;
